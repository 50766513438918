import React from 'react'
import type { CollapseProps } from 'antd'
import { Button, Collapse } from 'antd'
import SystemSwitch from './SystemSwitch'
import AddParameterSet from './AddParameterSet'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import {
  initialDataSetLESSH,
  initialDataSetLEXXZ,
  initialDataSetRRSSH,
  initialDataSetRRXXZ,
  initialSSHParameters,
  initialXXZParameters,
  mathJaxConfig,
  mscThesisLink,
  mscThesisView
} from '../../resources/loschmidtEcho'
import { SystemModel } from '../../enum'
import { FormParametersIF, ResponseDataIF } from '../../interface/loschmidtEcho'
import { expandPanel } from './functions'
import { DataSetIF } from '../../interface/common'
import { request } from '../../../store/request'

const SolutionMenu: React.FC<{
  collapseItems: CollapseProps['items']
  disableBtn: boolean
  parameters: FormParametersIF[]
  systemModel: SystemModel
  datasetsLE: DataSetIF[]
  datasetsRR: DataSetIF[]
  setDatasetsLE: React.Dispatch<React.SetStateAction<DataSetIF[]>>
  setDatasetsRR: React.Dispatch<React.SetStateAction<DataSetIF[]>>
  setDisableBtn: React.Dispatch<React.SetStateAction<boolean>>
  setParameters: React.Dispatch<React.SetStateAction<FormParametersIF[]>>
  setSystemModel: React.Dispatch<React.SetStateAction<SystemModel>>
  handleAddParameterSet: () => void
  updateCollapsedItems: (parameters: FormParametersIF[]) => void
}> = ({
  collapseItems,
  disableBtn,
  parameters,
  systemModel,
  datasetsLE,
  datasetsRR,
  setDatasetsLE,
  setDatasetsRR,
  setDisableBtn,
  setParameters,
  setSystemModel,
  handleAddParameterSet,
  updateCollapsedItems
}) => {
  const buttonStyle: React.CSSProperties = {
    display: 'block',
    marginTop: '1rem',
    marginLeft: 'auto'
  }
  const onChangeSystemModel = (model: SystemModel) => {
    setSystemModel(model)
    const initialParameters =
      model === SystemModel.SSH ? initialSSHParameters : initialXXZParameters
    setParameters(initialParameters)
    const initialDatasetsLE =
      model === SystemModel.SSH ? [initialDataSetLESSH] : [initialDataSetLEXXZ]
    const initialDatasetsRR =
      model === SystemModel.SSH ? [initialDataSetRRSSH] : [initialDataSetRRXXZ]
    setDatasetsLE(initialDatasetsLE)
    setDatasetsRR(initialDatasetsRR)
    updateCollapsedItems(initialParameters)
    expandPanel(setDisableBtn)
  }
  const onRunCalculation = async () => {
    setDisableBtn(true)
    try {
      const response = await request('api/loschmidt-echo/', { payload: parameters }, 'post')
      const responseData: ResponseDataIF[] = response.data.response
      setDatasetsLE(
        datasetsLE.map((value: DataSetIF, index) => ({
          ...value,
          data: responseData[index].le
        }))
      )
      setDatasetsRR(
        datasetsRR.map((value: DataSetIF, index) => ({
          ...value,
          data: responseData[index].rr
        }))
      )
      setDisableBtn(false)
    } catch (error) {
      setDisableBtn(false)
    }
  }
  return (
    <div>
      <MathJaxContext version={3} config={mathJaxConfig}>
        <MathJax className="loschmidt-echo-paragraph-container" style={{ marginBottom: '16px' }}>
          {`This simulation application provides numerical solutions for each given parameter set.
            The first numerical solutions on the right for calculating the Loschmidt echo and the 
            return rate are pre-populated with the first parameter set. Numerical solutions with 
            non-zero disorder parameters should be the averaged results over very large number of 
            disordered solutions, because real world systems have impurities spreaded out evenly 
            in the crystal lattice and the system is usually very large. For simple calculation   
            purposes, this application will not take the average values for numerical solutions 
            with non-zero disorder parameters because doing so involes very long calculation time 
            and computing resources. Furthermore, the system size is limited to maximum of $2^8$ = 
            256 (L = 8) for many-particle systems to save computing resources. For signle-particle 
            systems, the maximum system size is L = 130 for the SSH model and L = for the XXZ model.  
            For more accurate results, please visit the following link for 
            the research thesis: `}
          <a href={mscThesisLink} target="_blank" rel="noopener noreferrer">
            LINK
          </a>
          {`. The PDF file is also available for download and online view: `}
          <a href={mscThesisView} target="_blank" rel="noopener noreferrer">
            HERE
          </a>
          .
        </MathJax>
      </MathJaxContext>
      <SystemSwitch
        label={'System Selection'}
        systemModel={systemModel}
        onChangeSystemModel={onChangeSystemModel}
      />
      <AddParameterSet label="Parameters" disableBtn={disableBtn} onClick={handleAddParameterSet} />
      <Collapse accordion defaultActiveKey={'1'} items={collapseItems} />
      <Button
        type="primary"
        style={buttonStyle}
        onClick={onRunCalculation}
        disabled={disableBtn}
        loading={disableBtn}
      >
        Run
      </Button>
    </div>
  )
}

export default SolutionMenu
