import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { mathJaxConfig } from '../../resources/loschmidtEcho'

const Summary = () => {
  return (
    <MathJaxContext version={3} config={mathJaxConfig}>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`To study dynamical phase transitions for disordered systems, we need to evaluate the 
          mean value of the Loschmidt echo averaged from many calculations with different 
          disordered configurations, called the disorder-averaged Loschmidt echo. Each 
          configuration corresponds to a set of eigenvalues and eigenvectors obtained by exact 
          diagonalization for a disordered system. To realize a disordered system, we first define 
          the on-site disorder potential according to the Anderson localization Hamiltonian,
          $$w_j=\\sum^L_{j=1}\\mu_j\\hat{c}_{j}\\hat{c}_{j+1}=\\sum^L_{j=1}\\mu_j\\hat{n}_j,$$
          where $\\mu_j\\in[-W,W]$ is the box disorder potential, 
          $\\hat{n}_j=\\hat{c}^\\dagger_j\\hat{c}_{j+1}$ is the occupation number operator on site 
          $j$, and the sum is over the number of lattice sits $L=2N$. Each value of $\\mu_j$ for 
          a given $j$ is randomly generated and independently distinguished from any other values 
          $\\mu_{j+1}$ for $j+1$. The SSH Hamiltonian with disorder potential then takes the form
          $$\\hat{H}_{SSH_\\triangle}=J\\sum^L_{j=1}[(1+\\delta e^{i\\pi j})\\hat{c}^\\dagger_j\\hat{c}_{j+1} + h.c.]+\\sum^L_{j=1}\\mu_j\\hat{n}_j+V,$$
          where $V$ is the nearest-neighbor interaction for free fermions derived by the fermionic
          interacting XXZ model can be expressed as
          $$V = \\triangle\\sum^L_{j=1}\\left(\\hat{n}_j\\hat{n}_{j+1}-\\frac{1}{4}\\right).$$
          The disordered XX model with has the form
          $$\\hat{H}_{XX}=\\frac{J}{2}\\sum^L_{j=1}(\\hat{c}^\\dagger_j\\hat{c}_{j+1}+\\hat{c}^\\dagger_{j+1}\\hat{c}_j)+\\sum^L_{j=1}\\mu_j\\hat{n}_j.$$
          The full XXZ model with disorder potential then has the following form
          $$\\hat{H}_{XXZ}=\\frac{J}{2}\\sum^L_{j=1}(\\hat{c}^\\dagger_j\\hat{c}_{j+1}+\\hat{c}^\\dagger_{j+1}\\hat{c}_j)+\\sum^L_{j=1}\\mu_j\\hat{n}_j + V.$$
          We will use the eigenstate as the initial state for the SSH model after the quench during
          the forward evolution; whereas the N$\\acute{e}$el state will be the initial state for the XXZ model. 
          The SSH and Heisenberge models with disordered potentials will be used to evaluate the 
          Loschmidt echo and study the dynamical phase transition in this research.`}
      </MathJax>
    </MathJaxContext>
  )
}

export default Summary
