import React from 'react'
import '../assets/contact.sass'
import { Button, Form, Input, Select, Tag } from 'antd'
import { contactSelect } from '../utils/resources'

const { Option } = Select

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!'
  }
}

const Contact: React.FC<{ color1: string; color2: string }> = (props) => {
  const [expand, setExpand] = React.useState<boolean>(false)
  const [showBtn, setShowBtn] = React.useState<string>('block')
  const [showForm, setShowForm] = React.useState<string>('none')
  const [showDone, setShowDone] = React.useState<string>('none')
  const [showWait, setShowWait] = React.useState<string>('none')
  const [msgCount, setMsgCount] = React.useState<number>(2)
  const [minute, setMinute] = React.useState<number>(30)
  const [second, setSecond] = React.useState<number>(0)
  const elapse = 30 * 60 * 1000

  const content = React.useRef(null)

  React.useEffect(() => {
    const expiration = localStorage.getItem('expiration')
    if (expiration) {
      setShowBtn('none')
      setShowWait('block')
      setMsgCount(3)
      let loopTimer: any
      let countTimer: any
      const then = new Date(expiration).getTime()
      countTimer = setTimeout(() => {
        setShowBtn('block')
        setShowWait('none')
        setMsgCount(0)
        setSecond(0)
        setMinute(0)
        clearInterval(loopTimer)
        clearTimeout(countTimer)
        localStorage.removeItem('msgCount')
        localStorage.removeItem('expiration')
      }, then - new Date().getTime())
      loopTimer = setInterval(() => {
        const current = new Date().getTime()
        setSecond(Math.floor(((then - current) / 1000) % 60))
        setMinute(Math.floor((then - current) / 1000 / 60))
        if (then - current < 0) {
          clearInterval(loopTimer)
          clearTimeout(countTimer)
        }
      }, 1000)
    }
  }, [])

  const [form] = Form.useForm()

  const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
      {required ? <Tag color="error">required</Tag> : <Tag color="warning">optional</Tag>}
      {label}
    </>
  )

  const onSubjectChange = (value: string) => {
    form.setFieldsValue({ subject: value })
  }

  const onFinish = (values: any) => {
    setMsgCount(msgCount + 1)
    const thisContent = content!.current! as HTMLElement
    thisContent.style.height = '25vh'
    setTimeout(() => {
      setShowForm('none')
      setShowDone('block')
      setShowBtn('none')
      thisContent.style.height = '0vh'
    }, 1000)
  }

  const onReset = () => {
    form.resetFields()
  }

  const toggleExpand = () => {
    if (content !== null) {
      setExpand(!expand)
      const thisContent = content!.current! as HTMLElement
      if (!expand) {
        setShowForm('block')
        setTimeout(() => {
          thisContent.style.height = '520px'
        }, 50)
        setTimeout(() => {
          const item = document.getElementById('contact')
          item?.scrollIntoView({ behavior: 'smooth' })
          const name = document.getElementById('control-hooks_name')
          name?.focus()
        }, 1000)
      } else {
        form.resetFields()
        thisContent.style.height = '0vh'
        setTimeout(() => {
          setShowForm('none')
        }, 1000)
      }
    }
  }

  const sendAnother = () => {
    form.resetFields()
    if (msgCount >= 3) {
      setExpand(!expand)
      setShowDone('none')
      setShowWait('block')
      const current = new Date().getTime()
      const expirationDate = new Date(current + elapse)
      localStorage.setItem('expiration', expirationDate.toISOString())
      let loopTimer: any
      let countTimer: any
      countTimer = setTimeout(() => {
        setShowBtn('block')
        setShowWait('none')
        setMsgCount(0)
        setSecond(0)
        setMinute(0)
        clearInterval(loopTimer)
        clearTimeout(countTimer)
        localStorage.removeItem('msgCount')
        localStorage.removeItem('expiration')
      }, elapse)
      const then = expirationDate.getTime()
      loopTimer = setInterval(() => {
        const current = new Date().getTime()
        setSecond(Math.floor(((then - current) / 1000) % 60))
        setMinute(Math.floor((then - current) / 1000 / 60))
        if (then - current < 0) {
          clearInterval(loopTimer)
          clearTimeout(countTimer)
        }
      }, 1000)
      return
    }
    setShowForm('block')
    setShowDone('none')
    setShowBtn('block')
    const thisContent = content!.current! as HTMLElement
    setTimeout(() => {
      thisContent.style.height = '52vh'
    }, 50)
    setTimeout(() => {
      const item = document.getElementById('contact')
      item?.scrollIntoView({ behavior: 'smooth' })
      const name = document.getElementById('control-hooks_name')
      name?.focus()
    }, 1000)
  }

  return (
    <div
      id="contact"
      style={{
        backgroundImage: `-webkit-linear-gradient(0deg, ${props.color1} 0%, ${props.color2} 100%`
      }}
    >
      <div id="contact-container">
        <div id="contact-text-container">
          <h1>CONTACT ME</h1>
        </div>
        <Button
          id="contact-toggle-button"
          type="primary"
          size="large"
          danger={expand}
          onClick={() => {
            toggleExpand()
          }}
          style={{ display: showBtn }}
        >
          Click here to {expand ? 'close' : 'show'} the contact form
        </Button>
        <div id="contact-form" ref={content} style={{ display: showForm }}>
          <Form
            form={form}
            layout="vertical"
            name="control-hooks"
            onFinish={onFinish}
            requiredMark={customizeRequiredMark}
            validateMessages={validateMessages}
            style={{ margin: '1rem' }}
          >
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input placeholder="John Doe" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
              <Input placeholder="john.doe@example.com" />
            </Form.Item>
            <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
              <Select placeholder="Choose Subject" onChange={onSubjectChange} allowClear>
                {contactSelect.map((item: { label: string; value: string }) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="message" label="Message" rules={[{ required: true }]}>
              <Input.TextArea
                showCount
                maxLength={100}
                autoSize={{ minRows: 5 }}
                placeholder="Please leave your message"
              />
            </Form.Item>
            <div id="contact-form-btns">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Send Message
                </Button>
              </Form.Item>
              <Form.Item>
                <Button danger htmlType="button" onClick={onReset}>
                  Reset Form
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div id="contact-done" style={{ display: showDone }}>
          <h1>Thank you!</h1>
          <h3>Your request has been sent.</h3>
          <h3>We will contact you shortly.</h3>
          <Button id="contact-done-button" type="primary" onClick={() => sendAnother()}>
            Send Another
          </Button>
        </div>
        <div id="contact-wait" style={{ display: showWait }}>
          <h1>Thank you!</h1>
          <h3>Your request has been sent.</h3>
          <h3>
            Please wait for {minute} minute{minute > 1 ? 's' : ''} {second} second
            {second > 1 ? 's' : ''}
          </h3>
          <h3>before sending another request.</h3>
        </div>
      </div>
    </div>
  )
}

export default Contact
