import React from 'react'
import { Button, Col, Row } from 'antd'
import { SystemModel } from '../../enum'

const SystemSwitch: React.FC<{
  label: string
  systemModel: SystemModel
  onChangeSystemModel: (model: SystemModel) => void
}> = ({ label, systemModel, onChangeSystemModel }) => {
  const backgroundStyle = (isModel: boolean) => {
    return isModel ? { backgroundColor: '#1677ff' } : { backgroundColor: '#BEC8C8' }
  }
  return (
    <Row className="common-dropdown-menu">
      <Col span={6} className="common-dropdown-menu-selector">
        {label}
      </Col>
      <Col span={9} style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          onClick={() => onChangeSystemModel(SystemModel.SSH)}
          style={backgroundStyle(systemModel === SystemModel.SSH)}
        >
          SSH Model
        </Button>
      </Col>
      <Col span={9} style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          onClick={() => onChangeSystemModel(SystemModel.XXZ)}
          style={backgroundStyle(systemModel === SystemModel.XXZ)}
        >
          XXZ Model
        </Button>
      </Col>
    </Row>
  )
}

export default SystemSwitch
