const SVGDefs = (props: { id: string; color1: string; color2: string }) => {
  return (
    <defs>
      <linearGradient id={props.id} x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: props.color1, stopOpacity: '1' }} />
        <stop offset="100%" style={{ stopColor: props.color2, stopOpacity: '1' }} />
      </linearGradient>
    </defs>
  )
}

export default SVGDefs
