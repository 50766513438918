import React from 'react'
import { DataSetIF, GraphOptionsIF, MinMaxIF } from '../../interface/common'
import { GenerateDataIF } from '../../interface/functionAnimator'

declare global {
  interface Window {
    MyNamespace: any
    MyNamespace1: any
  }
}

export const jsFunctionFilter = function (equation: string, parameter: string) {
  if (equation.split('=').length - 1 > 1) {
    return 'invalid'
  }

  // equation = equation.split("=")[equals];
  const symbolObject: any = {
    '\\frac{': '(', // eslint-disable-next-line
    '}{': ')/(', // eslint-disable-next-line
    '\\left(': '(', // eslint-disable-next-line
    '\\right)': ')', // eslint-disable-next-line
    '^': '**', // eslint-disable-next-line
    '{': '(', // eslint-disable-next-line
    '}': ')', // eslint-disable-next-line
    '\\pi': 'Math.PI', // eslint-disable-next-line
    '\\cdot': '*', // eslint-disable-next-line
    '\\left|': 'Math.abs(', // eslint-disable-next-line
    '\\right|': ')' // eslint-disable-next-line
  }
  Object.keys(symbolObject).forEach((element) => {
    const count = equation.split(element).length - 1
    for (let j = 1; j <= count; j++) {
      equation = equation.replace(element, symbolObject[element])
    }
  })

  // eslint-disable-next-line
  let functionObject: any = {
    '\\arccos': 'Math.acos(',
    '\\arcsin': 'Math.asin(',
    '\\arctan': 'Math.atan(',
    '\\arccosh': 'Math.acosh(',
    '\\arcsinh': 'Math.asinh(',
    '\\arctanh': 'Math.atanh(',
    '\\cos': 'Math.cos(',
    '\\cosh': 'Math.cosh(',
    '\\sin': 'Math.sin(',
    '\\sinh': 'Math.sinh(',
    '\\tan': 'Math.tan(',
    '\\tanh': 'Math.tanh(',
    '\\sec': '1/Math.cos(',
    '\\csc': '1/Math.sin(',
    '\\cot': '1/Math.tan(',
    '\\coth': 'Math.coth',
    '\\sqrt': 'Math.sqrt(',
    '\\exp': 'Math.exp(',
    '\\ln': 'Math.log(',
    '\\log': 'Math.log(',
    'e^': 'Math.exp('
  }

  Object.keys(functionObject).forEach((element) => {
    if (equation.includes(element) === true) {
      const count = equation.split(element).length - 1
      for (let j = 1; j <= count; j++) {
        const countPM = equation.indexOf(element)
        const equationL = equation.length
        const pTrue = equation.slice(countPM, equationL).includes('+')
        const mTrue = equation.slice(countPM, equationL).includes('-')
        if (pTrue === true || mTrue === true) {
          for (let k = countPM; k <= equationL; k++) {
            if (equation[k] === '+' || equation[k] === '-') {
              equation = equation.slice(0, k) + ')' + equation.slice(k)
              break
            }
          }
        } else if (pTrue === false || mTrue === false) {
          equation = equation.concat(')')
        }
        equation = equation.replace(element, functionObject[element])
      }
    }
  })

  if (equation.includes('()')) {
    const count = equation.split('()').length - 1
    for (let j = 1; j <= count; j++) {
      equation = equation.replace('()', '')
    }
  }
  if (equation.includes(' ') === true) {
    const count = equation.split(' ').length - 1
    for (let j = 1; j <= count; j++) {
      equation = equation.replace(' ', '')
    }
  }

  if (equation.includes('Math.PI')) {
    const npList = equation.split('Math.PI')
    for (let i = 0; i < npList.length; i++) {
      if (i === 0) {
        const element = npList[i]
        const lastChar: any = element[element.length - 1]
        if (isNaN(lastChar) === false) {
          npList[i] = npList[i].concat('*')
        }
      } else {
        // let element = npList[i];
        // let lastChar:any = element[element.length - 1];
        // let firstChar:any = element[0];
        npList[i] = '*'.concat(npList[i])
        // if (isNaN(lastChar) === false) {
        //     npList[i] = npList[i].concat("*");
        // } else if (firstChar.toUpperCase() !== firstChar.toLowerCase()) {
        //     npList[i] = "*".concat(npList[i]);
        // }
      }
    }
    equation = npList.join('Math.PI')
  }

  const variable: any = parameter.toUpperCase() !== parameter.toLowerCase() ? parameter : 'x'

  const countx = equation.split(variable).length - 1
  const eqx1: string[] = []
  if (countx >= 1) {
    for (let i = 1; i <= countx; i++) {
      const LofX = equation.indexOf(variable) - 1
      const RofX = equation.indexOf(variable) + 1
      const cc: any = equation.charAt(LofX)
      if (!isNaN(cc)) {
        eqx1.push(equation.slice(0, LofX + 1) + '*' + variable)
        equation = equation.slice(RofX)
      } else {
        eqx1.push(equation.slice(0, RofX))
        equation = equation.slice(RofX)
      }
    }
    equation = eqx1.join('') + equation
  } else {
    const LofX = equation.indexOf(variable)
    const cc: any = equation.charAt(LofX)
    if (!isNaN(cc)) {
      equation = equation.slice(0, LofX) + '*' + equation.slice(LofX)
    }
  }

  const equals = equation.split('=').length - 1
  equation = equation.split('=')[equals]
  let MN = window.MyNamespace
  MN = 1
  window[variable] = MN
  try {
    // eslint-disable-next-line
    eval(equation)
  } catch (error) {
    return 'invalid'
  }
  return equation
}

export const pyFunctionFilter = (equation: string) => {
  const functionObject: any = {
    'Math.acos(': 'np.acos(',
    'Math.asin(': 'np.asin(',
    'Math.atan(': 'np.atan(',
    'Math.acosh(': 'np.acosh(',
    'Math.asinh(': 'np.asinh(',
    'Math.atanh(': 'np.atanh(',
    'Math.cos(': 'np.cos(',
    'Math.cosh(': 'np.cosh(',
    'Math.sin(': 'np.sin(',
    'Math.sinh(': 'np.sinh(',
    'Math.tan(': 'np.tan(',
    'Math.tanh(': 'np.tanh(',
    '1/Math.cos(': 'npth.cos(',
    '1/Math.sin(': 'npth.sin(',
    '1/Math.tan(': 'npth.tan(',
    'Math.coth': 'np.coth',
    'Math.sqrt(': 'np.sqrt(',
    'Math.exp(': 'np.exp(',
    'Math.log(': 'np.log('
  }

  Object.keys(functionObject).forEach((element) => {
    if (equation.includes(element) === true) {
      // let count = equation.split(element).length - 1;
      // for (let j = 1; j <= count; j++) {
      //   let countPM = equation.indexOf(element);
      //   let equationL = equation.length;
      //   let pTrue = equation.slice(countPM, equationL).includes("+");
      //   let mTrue = equation.slice(countPM, equationL).includes("-");
      //   if (pTrue === true || mTrue === true) {
      //     for (let k = countPM; k <= equationL; k++) {
      //       if (equation[k] === "+" || equation[k] === "-") {
      //         equation = equation.slice(0, k) + ")" + equation.slice(k);
      //         break;
      //       };
      //     };
      //   } else if (pTrue === false || mTrue === false) {
      //     equation = equation.concat(")");
      //   };
      equation = equation.replace(element, functionObject[element])
      // };
    }
  })
  return equation
}

export const getPrime = (N: number, h: number, f: number[]) => {
  const yp: number[] = []
  yp.push((f[1] - f[0]) / h)
  for (let k = 1; k < N - 1; k++) {
    yp.push((f[k + 1] - f[k - 1]) / (2 * h))
  }
  yp.push((f[N - 1] - f[N - 2]) / h)
  if (yp[3] >= yp[2]) {
    yp[1] = 2 * yp[2] - yp[3]
    yp[0] = 2 * yp[1] - yp[2]
  } else {
    yp[1] = 2 * yp[2] - yp[3]
    yp[0] = 2 * yp[1] - yp[2]
  }
  if (yp[N - 4] >= yp[N - 3]) {
    yp[N - 2] = 2 * yp[N - 3] - yp[N - 4]
    yp[N - 1] = 2 * yp[N - 2] - yp[N - 3]
  } else {
    yp[N - 2] = 2 * yp[N - 3] - yp[N - 4]
    yp[N - 1] = 2 * yp[N - 2] - yp[N - 3]
  }
  return yp
}

export const generateData = (func: string, i1: number, i2: number, step = 1, variable: any) => {
  const xValues: number[] = []
  const yValues: number[] = []
  for (let x = i1; x <= i2; x += step) {
    // eslint-disable-next-line
    let MN = window.MyNamespace1
    MN = x
    window[variable] = MN
    // eslint-disable-next-line
    yValues.push(eval(func))
    xValues.push(x)
  }
  const vValues: number[] = getPrime(Number(xValues.length), step, yValues)
  const aValues: number[] = getPrime(Number(xValues.length), step, vValues)
  return { xValues, yValues, vValues, aValues }
}

export const handleDatasets = (
  graph: GraphOptionsIF,
  result: GenerateDataIF,
  setDatasets: React.Dispatch<React.SetStateAction<DataSetIF[]>>,
  setYMinMax: React.Dispatch<React.SetStateAction<MinMaxIF>>
) => {
  let newDatasets: DataSetIF[] = []
  let minArr = []
  let maxArr = []
  for (let item of graph.plot) {
    if (item === 'Function') {
      newDatasets.push({
        data: result.yValues,
        label: item,
        borderColor: graph.y
      })
      minArr.push(Math.min(...result.yValues))
      maxArr.push(Math.max(...result.yValues))
    } else if (item === 'Derivative') {
      newDatasets.push({
        data: result.vValues,
        label: item,
        borderColor: graph.yp
      })
      minArr.push(Math.min(...result.vValues))
      maxArr.push(Math.max(...result.vValues))
    } else if (item === 'Acceleration') {
      newDatasets.push({
        data: result.aValues,
        label: item,
        borderColor: graph.ypp
      })
      minArr.push(Math.min(...result.aValues))
      maxArr.push(Math.max(...result.aValues))
    }
  }
  setDatasets(newDatasets)
  setYMinMax({ min: Math.min(...minArr), max: Math.max(...maxArr) })
}
