import React from 'react'
import Header from './components/Navigation'
import Introduction from './components/Introduction'
import AboutMe from './components/AboutMe'
import Experience from './components/Experience'
import Services from './components/Services'
import Projects from './components/Projects'
import Construction from './components/Construction'
import Footer from './components/Footer'
import Contact from './components/Contact'
import { colors } from './utils/resources'

class Home extends React.Component<
  {},
  { count: number; effect: number; color1: string; color2: string }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      count: 0,
      effect: 0,
      color1: colors[1],
      color2: colors[15]
    }
  }

  componentDidMount() {
    setInterval(() => {
      const length = ' am Lewis Chen'.length
      const mod: number = this.state.count % length
      const mod2 = this.state.count % 40
      if (Math.floor((this.state.count / length) % 2) === 0) {
        if ([0, 3, 9].includes(mod)) {
          this.setState({ count: this.state.count + 2 })
        } else {
          this.setState({ count: this.state.count + 1 })
        }
      } else {
        if ([4, 10, 12].includes(mod)) {
          this.setState({ count: this.state.count + 2 })
        } else {
          this.setState({ count: this.state.count + 1 })
        }
      }
      if (this.state.count > 0 && mod2 === 0) {
        const color1 = colors[Math.floor(Math.random() * 20)]
        const color2 = colors[Math.floor(Math.random() * 20 + 20)]
        this.setState({ effect: this.state.effect + 1, color1: color1, color2: color2 })
      }
      if (this.state.effect > 9) {
        this.setState({ effect: 0 })
      }
    }, 500)
    document.addEventListener('scroll', function () {
      const nav = document.getElementById('navigation')
      if (nav) nav!.style.height = window.scrollY > 200 ? '4vh' : '6vh'
    })
  }

  render() {
    return (
      <>
        <Header count={this.state.count} color1={this.state.color1} color2={this.state.color2} />
        <Introduction count={this.state.count} effect={this.state.effect} />
        <AboutMe count={this.state.count} />
        <Experience
          effect={this.state.effect}
          color1={this.state.color1}
          color2={this.state.color2}
        />
        <Services
          color1={this.state.color1}
          color2={this.state.color2}
          background1={this.state.color1}
          background2={this.state.color2}
        />
        <Projects
          color1={this.state.color1}
          color2={this.state.color2}
          background1={this.state.color1}
          background2={this.state.color2}
        />
        <Contact color1={this.state.color1} color2={this.state.color2} />
        <Construction />
        <Footer />
      </>
    )
  }
}

export default Home
