import React from 'react'
import '../assets/common-components.sass'
import { Col, Row, Select } from 'antd'

const CommonMenu: React.FC<{
  label: string
  menuArray: string[]
  onChangeMenuIndex: (value: number) => void
}> = ({ label, menuArray, onChangeMenuIndex }) => {
  const handleChange = (value: string) => {
    onChangeMenuIndex(menuArray.indexOf(value))
  }

  return (
    <Row className="common-dropdown-menu">
      <Col span={6} className="common-dropdown-menu-selector">
        {label}
      </Col>
      <Col span={18}>
        <Select
          defaultValue={menuArray[0]}
          style={{ width: '100%' }}
          onChange={handleChange}
          options={menuArray.map((item) => ({ value: item, label: item }))}
        />
      </Col>
    </Row>
  )
}

export default CommonMenu
