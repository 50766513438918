import React from 'react'
import '../assets/function-animator.sass'
import { Checkbox, Form, Slider } from 'antd'
import { colorArray } from '../../resources/functionAnimator'
import { GraphOptionsIF } from '../../interface/common'
import CommonMenu from '../../common/components/CommonMenu'
import CommonColorPicker from '../../common/components/CommonColorPicker'
import { ColorPickerType } from '../../enum'
import { ColorResult } from 'react-color'

const GraphMenu: React.FC<{
  graphOptions: GraphOptionsIF
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setGraphOptions: React.Dispatch<React.SetStateAction<GraphOptionsIF>>
}> = ({ graphOptions, setOpen, setGraphOptions }) => {
  const [menuIndex, setMenuIndex] = React.useState<number>(0)
  const [color, setColor] = React.useState<string>(graphOptions.y)

  const onChangeParameters = (value: any, type: string) => {
    setGraphOptions({ ...graphOptions, [type]: value })
  }

  const onFinish = (values: any) => {
    setGraphOptions({ ...values, run: true })
    setOpen(false)
  }

  const onChange = (checkedValues: any) => {
    setGraphOptions({ ...graphOptions, plot: checkedValues })
  }

  const plainOptions = ['Function', 'Derivative', 'Acceleration']

  const onChangeMenuIndex = (value: number) => {
    setMenuIndex(value)
    if (value === 0) {
      setColor(graphOptions.y)
    } else if (value === 1) {
      setColor(graphOptions.yp)
    } else if (value === 2) {
      setColor(graphOptions.ypp)
    } else if (value === 3) {
      setColor(graphOptions.title)
    } else if (value === 4) {
      setColor(graphOptions.grid)
    } else if (value === 5) {
      setColor(graphOptions.axes)
    } else if (value === 6) {
      setColor(graphOptions.background)
    }
  }

  const onChangeColor = (v: ColorResult) => {
    const rgba = `rgba(${v.rgb.r}, ${v.rgb.g}, ${v.rgb.b}, ${v.rgb.a})`
    setColor(rgba)
    if (menuIndex === 0) {
      setGraphOptions({ ...graphOptions, y: rgba })
    } else if (menuIndex === 1) {
      setGraphOptions({ ...graphOptions, yp: rgba })
    } else if (menuIndex === 2) {
      setGraphOptions({ ...graphOptions, ypp: rgba })
    } else if (menuIndex === 3) {
      setGraphOptions({ ...graphOptions, title: rgba })
    } else if (menuIndex === 4) {
      setGraphOptions({ ...graphOptions, grid: rgba })
    } else if (menuIndex === 5) {
      setGraphOptions({ ...graphOptions, axes: rgba })
    } else if (menuIndex === 6) {
      setGraphOptions({ ...graphOptions, background: rgba })
    }
  }

  return (
    <>
      <Form initialValues={graphOptions} onFinish={onFinish} layout="vertical">
        <Form.Item label={'Grid Step'} name={'step'}>
          <Slider
            min={1}
            max={100}
            onChange={(value: number) => onChangeParameters(value, 'step')}
          />
        </Form.Item>
        <Form.Item label={'Plot Options'} name={'plot'}>
          <Checkbox.Group options={plainOptions} onChange={onChange} />
        </Form.Item>
      </Form>
      <CommonMenu
        label={'Color Settings'}
        menuArray={colorArray}
        onChangeMenuIndex={onChangeMenuIndex}
      />
      <CommonColorPicker
        show={true}
        color={color}
        pickerType={ColorPickerType.CHROME_PICKER}
        disableAlpha={false}
        onChangeColor={onChangeColor}
      />
    </>
  )
}

export default GraphMenu
