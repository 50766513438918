import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './landing/Home'
import DoublePendulumContainer from './apps/doublePendulum/containers/DoublePendulumContainer'
import FunctionAnimatorContainer from './apps/functionAnimator/containers/FunctionAnimatorContainer'
import LoschmidtEchoContainer from './apps/loschmidtEcho/containers/LoschmidtEchoContainer'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/project/double-pendulum'} element={<DoublePendulumContainer />} />
        <Route path={'/project/function-animator'} element={<FunctionAnimatorContainer />} />
        <Route path={'/project/loschmidt-echo'} element={<LoschmidtEchoContainer />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
