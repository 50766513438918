import React from 'react'
import '../assets/function-animator.sass'
import { Form } from 'antd'
import { addStyles, EditableMathField } from 'react-mathquill'
import { formParameters } from '../../resources/functionAnimator'
import { GraphParametersIF } from '../../interface/common'
import { FormParametersIF } from '../../interface/functionAnimator'

addStyles()

const FunctionMenu: React.FC<{
  parameters: GraphParametersIF
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setParameters: React.Dispatch<React.SetStateAction<GraphParametersIF>>
}> = ({ parameters, setOpen, setParameters }) => {
  const onChangeParameters = (value: any, type: string) => {
    setParameters({ ...parameters, [type]: value })
  }

  const onFinish = (values: any) => {
    setParameters({ ...values, run: true })
    setOpen(false)
  }

  return (
    <Form initialValues={parameters} onFinish={onFinish} layout="vertical">
      {formParameters.map((item: FormParametersIF) => (
        <Form.Item label={item.label} name={item.type} key={item.label}>
          <EditableMathField
            className="function-animator-function-fields"
            latex={parameters[item.type as keyof GraphParametersIF]}
            onChange={(mathField: any) => onChangeParameters(mathField.latex(), item.type)}
          />
        </Form.Item>
      ))}
    </Form>
  )
}

export default FunctionMenu
