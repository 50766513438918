const BackendSVG = (props: { id: string }) => {
  return (
    <g fill={`url(#${props.id})`}>
      <g>
        <g>
          <path
            d="M511.893,95.253c0-2.347-0.96-4.587-2.56-6.187c-0.107-0.213-0.213-0.427-0.427-0.533L424.32,3.2
				c-2.027-2.027-4.693-3.2-7.573-3.2H95.253c-2.88,0-5.547,1.173-7.573,3.2L3.093,88.533c-0.213,0.213-0.213,0.427-0.32,0.533
				c-0.747,0.853-1.493,1.813-1.92,2.88c-0.427,1.067-0.64,2.24-0.64,3.307C0.107,95.573,0,95.787,0,96v371.52
				C0,491.627,21.333,512,46.507,512h421.12C492.16,511.893,512,492.053,512,467.52V96C512,95.787,511.893,95.573,511.893,95.253z
				 M99.733,21.333h312.533l63.467,64H36.267L99.733,21.333z M490.667,467.52c0,12.693-10.24,23.04-23.04,23.147H46.507
				c-13.12,0-25.173-11.093-25.173-23.147v-94.187h469.333V467.52z M490.667,352H21.333V245.333h469.333V352z M490.667,224H21.333
				V106.667h469.333V224z"
          />
          <path
            d="M85.333,206.187c24.747,0,44.8-20.48,44.8-45.76c0-25.28-20.053-45.76-44.8-45.76c-24.747,0-44.8,20.48-44.8,45.76
				C40.533,185.707,60.587,206.187,85.333,206.187z M85.333,136c12.907,0,23.467,10.987,23.467,24.427
				c0,13.44-10.56,24.427-23.467,24.427c-12.907,0-23.467-10.987-23.467-24.427C61.867,146.987,72.427,136,85.333,136z"
          />
          <path
            d="M303.787,191.893c14.827,0,26.773-12.053,26.773-26.773s-12.053-26.773-26.773-26.773s-26.773,12.053-26.773,26.773
				C276.907,179.84,288.96,191.893,303.787,191.893z M303.787,159.573c2.987,0,5.44,2.453,5.44,5.44c0,2.987-2.453,5.44-5.44,5.44
				s-5.44-2.453-5.44-5.44C298.347,162.027,300.693,159.573,303.787,159.573z"
          />
          <path
            d="M367.787,191.893c14.827,0,26.773-12.053,26.773-26.773s-12.053-26.773-26.773-26.773s-26.773,12.053-26.773,26.773
				C340.907,179.84,352.96,191.893,367.787,191.893z M367.787,159.573c2.987,0,5.44,2.453,5.44,5.44c0,2.987-2.453,5.44-5.44,5.44
				c-2.987,0-5.44-2.453-5.44-5.44C362.347,162.027,364.693,159.573,367.787,159.573z"
          />
          <path
            d="M431.787,191.573c14.613-0.213,26.347-12.16,26.133-26.773c-0.213-14.613-12.16-26.347-26.773-26.133
				c-14.507,0.213-26.133,11.947-26.133,26.453C405.013,179.733,417.067,191.68,431.787,191.573z M431.787,159.893
				c2.88,0.213,5.013,2.667,4.907,5.44c-0.213,2.88-2.667,5.013-5.44,4.907c-2.773-0.213-4.907-2.453-4.907-5.12
				C426.347,162.133,428.8,159.787,431.787,159.893z"
          />
          <path
            d="M85.333,343.893c24.747,0,44.8-20.267,44.8-45.227c0-24.96-20.053-45.227-44.8-45.227c-24.747,0-44.8,20.267-44.8,45.227
				C40.533,323.627,60.587,343.893,85.333,343.893z M84.48,274.773c13.227-0.213,24.107,10.24,24.32,23.467c0,0.107,0,0.32,0,0.427
				c0.107,13.12-10.347,23.787-23.467,23.893c-13.227,0.213-24.107-10.24-24.32-23.467C60.8,285.867,71.253,274.987,84.48,274.773z"
          />
          <path
            d="M304.427,330.133c14.507-0.213,26.133-11.947,26.133-26.453c-0.107-14.613-12.16-26.56-26.773-26.453
				c-14.613,0.213-26.347,12.16-26.133,26.773C277.867,318.613,289.813,330.347,304.427,330.133z M304.32,298.56
				c2.773,0.213,4.907,2.453,4.907,5.12c-0.107,2.987-2.56,5.333-5.44,5.227c-2.88-0.213-5.013-2.667-4.907-5.44
				C299.093,300.587,301.547,298.453,304.32,298.56z"
          />
          <path
            d="M367.787,330.453c14.72,0,26.773-12.053,26.773-26.773c0-14.72-12.053-26.773-26.773-26.773
				c-14.827,0-26.773,12.053-26.773,26.773C341.013,318.4,353.067,330.453,367.787,330.453z M367.787,298.347
				c2.987,0,5.44,2.453,5.44,5.44s-2.453,5.44-5.44,5.44c-2.987,0-5.44-2.453-5.44-5.44S364.8,298.347,367.787,298.347z"
          />
          <path
            d="M432.427,330.133c14.507-0.213,26.133-11.947,26.133-26.453c-0.107-14.613-12.16-26.56-26.773-26.453
				c-14.613,0.213-26.347,12.16-26.133,26.773C405.867,318.613,417.813,330.347,432.427,330.133z M432.32,298.56
				c2.773,0.213,4.907,2.453,4.907,5.12c-0.107,2.987-2.56,5.333-5.44,5.227c-2.88-0.213-5.013-2.667-4.907-5.44
				C427.093,300.587,429.547,298.453,432.32,298.56z"
          />
          <path
            d="M303.787,458.453c14.72,0,26.773-12.053,26.773-26.773s-12.053-26.773-26.773-26.773
				c-14.827,0-26.773,12.053-26.773,26.773S289.067,458.453,303.787,458.453z M303.787,426.347c2.987,0,5.44,2.453,5.44,5.44
				s-2.453,5.44-5.44,5.44s-5.44-2.453-5.44-5.44S300.8,426.347,303.787,426.347z"
          />
          <path
            d="M367.787,458.453c14.72,0,26.773-12.053,26.773-26.773s-12.053-26.773-26.773-26.773
				c-14.827,0-26.773,12.053-26.773,26.773S353.067,458.453,367.787,458.453z M367.787,426.347c2.987,0,5.44,2.453,5.44,5.44
				s-2.453,5.44-5.44,5.44c-2.987,0-5.44-2.453-5.44-5.44S364.8,426.347,367.787,426.347z"
          />
          <path
            d="M432.427,458.133c14.507-0.213,26.133-11.947,26.133-26.453c-0.107-14.613-12.16-26.56-26.773-26.453
				c-14.613,0.213-26.347,12.16-26.133,26.773C405.867,446.613,417.813,458.347,432.427,458.133z M432.32,426.56
				c2.773,0.213,4.907,2.453,4.907,5.12c-0.107,2.987-2.56,5.333-5.44,5.227c-2.88-0.213-5.013-2.667-4.907-5.44
				C427.093,428.587,429.547,426.453,432.32,426.56z"
          />
          <path
            d="M83.2,471.787c25.707,0.96,46.933-19.627,46.933-45.12c0-24.96-20.16-45.12-45.12-45.227
				c-25.493,0-46.08,21.12-45.12,46.933C40.747,451.84,59.84,470.827,83.2,471.787z M61.867,425.173
				c0.747-11.733,10.24-21.227,21.973-21.973c13.653-0.853,24.96,9.92,25.067,23.467c-0.107,13.013-10.56,23.467-23.573,23.467
				C71.893,450.133,61.013,438.827,61.867,425.173z"
          />
        </g>
      </g>
    </g>
  )
}

export default BackendSVG
