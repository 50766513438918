import React from 'react'
import '../assets/common-components.sass'

const CommonContainer: React.FC<{
  initialWidth: number
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  backgroundStyle: React.CSSProperties
  menuElement: JSX.Element
  componentElement: JSX.Element
  drawerElement: JSX.Element
}> = ({ initialWidth, setOpen, backgroundStyle, menuElement, componentElement, drawerElement }) => {
  const [width, setWidth] = React.useState<number>(initialWidth)
  const sliderRef: React.MutableRefObject<null | HTMLDivElement> = React.useRef(null)

  React.useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth < 551) {
      setWidth(0)
    } else if (windowWidth < 769) {
      setWidth(windowWidth * 0.4)
    } else if (windowWidth < 1441) {
      setWidth(windowWidth * 0.35)
    } else if (windowWidth < 1921) {
      setWidth(windowWidth * 0.3)
    } else {
      setWidth(initialWidth)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    dragElement(sliderRef.current)
  }, [sliderRef])

  const dragElement = (current: null | HTMLDivElement) => {
    if (current) {
      current!.onmousedown = dragMouseDown
      current!.ontouchstart = dragTouchStart
    }
    function dragTouchStart(e: any) {
      e.preventDefault()
      document.ontouchend = closeTouchElement
      document.ontouchmove = elementTouchMove
    }
    function dragMouseDown(e: any) {
      e.preventDefault()
      document.onmouseup = closeDragElement
      document.onmousemove = elementDrag
    }
    function elementDrag(e: any) {
      e.preventDefault()
      if (e.clientX >= 310 && e.clientX <= 500) {
        setWidth(Math.floor(e.clientX * 1.018 - 41))
      } else if (e.clientX > 500 && e.clientX <= Math.floor(window.innerWidth / 2)) {
        setWidth(Math.floor(e.clientX * 1.0128 - 38))
      }
    }
    function elementTouchMove(e: any) {
      if (e.touches[0].clientX >= 320 && e.touches[0].clientX <= 600) {
        setWidth(Math.floor(e.touches[0].clientX * 1.018 - 41))
      }
    }
    function closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    }
    function closeTouchElement() {
      document.ontouchend = null
      document.ontouchmove = null
    }
  }

  return (
    <div id="common-application-container">
      <div
        id="common-application-nav-container"
        onClick={() => {
          setOpen(true)
        }}
      >
        <div>Application</div>
        <div>Menu</div>
      </div>
      <div id="common-application-menu-container" style={{ width: `${width}px` }}>
        <h1>Web Applications</h1>
        <div id="common-application-items-container"></div>
        {menuElement}
      </div>
      <div id="common-application-slider" ref={sliderRef}></div>
      <div
        id="common-application-animation-container"
        style={{ ...backgroundStyle, width: `${window.innerWidth - width}px` }}
      >
        {componentElement}
      </div>
      {drawerElement}
    </div>
  )
}

export default CommonContainer
