import { Button } from 'antd'
import React from 'react'

const CommonButtonTop: React.FC<{}> = () => {
  return (
    <div id="common-application-top-button">
      <Button
        type="primary"
        ghost
        onClick={() => {
          const container = document.getElementById('common-application-menu-container')
          container!.scrollTo({ top: 0, behavior: 'smooth' })
        }}
      >
        Top
      </Button>
    </div>
  )
}

export default CommonButtonTop
