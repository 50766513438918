import React from 'react'
import { Button, Checkbox, Col, Form, Row, Slider, Switch } from 'antd'
import { SystemModel, SystemType } from '../../enum'
import { FormParametersIF, HiddenIF } from '../../interface/loschmidtEcho'
import { expandPanel } from './functions'
import { DataSetIF } from '../../interface/common'
import CommonColorPicker from '../../common/components/CommonColorPicker'
import { ColorPickerType } from '../../enum'
import { ColorResult } from 'react-color'
import { initialDataSetLESSH } from '../../resources/loschmidtEcho'

const ParameterForm: React.FC<{
  index: number
  disableBtn: boolean
  parameters: FormParametersIF[]
  systemModel: SystemModel
  initialParameters: FormParametersIF
  datasetsLE: DataSetIF[]
  datasetsRR: DataSetIF[]
  setDatasetsLE: React.Dispatch<React.SetStateAction<DataSetIF[]>>
  setDatasetsRR: React.Dispatch<React.SetStateAction<DataSetIF[]>>
  setDisableBtn: React.Dispatch<React.SetStateAction<boolean>>
  setParameters: React.Dispatch<React.SetStateAction<FormParametersIF[]>>
}> = ({
  index,
  disableBtn,
  parameters,
  systemModel,
  initialParameters,
  datasetsLE,
  datasetsRR,
  setDatasetsLE,
  setDatasetsRR,
  setDisableBtn,
  setParameters
}) => {
  const [color, setColor] = React.useState<string>(
    datasetsLE[index]?.borderColor || initialDataSetLESSH.borderColor
  )
  const [maxSize, setMaxSize] = React.useState<number>(130)
  const [hidden, setHidden] = React.useState<HiddenIF>({
    interaction: true,
    delta: false,
    fix: true
  })

  React.useEffect(() => {
    setHidden({
      ...hidden,
      delta: systemModel === SystemModel.XXZ,
      fix: parameters[index].type === SystemType.SINGLE_PARTICLE || systemModel === SystemModel.XXZ
    })
    // eslint-disable-next-line
  }, [systemModel])

  const onRemovePanel = () => {
    if (parameters.length <= 1) return
    const newParameters = [...parameters!.slice(0, index), ...parameters!.slice(index + 1)]
    const newDatasetsLE = [...datasetsLE.slice(0, index), ...datasetsLE.slice(index + 1)]
    const newDatasetsRR = [...datasetsRR.slice(0, index), ...datasetsRR.slice(index + 1)]
    setParameters(newParameters)
    setDatasetsLE(newDatasetsLE)
    setDatasetsRR(newDatasetsRR)
    expandPanel(setDisableBtn)
  }

  const handleValuesChange = (_: { [key: string]: any }, newParameters: FormParametersIF) => {
    const newType = newParameters.type ? SystemType.SINGLE_PARTICLE : SystemType.MANY_PARTICLE
    newParameters = { ...newParameters, type: newType, model: systemModel }
    setParameters([...parameters.slice(0, index), newParameters, ...parameters.slice(index + 1)])
    setMaxSize(newParameters.type === SystemType.SINGLE_PARTICLE ? 130 : 8)
    setHidden({
      ...hidden,
      interaction: newParameters.type === SystemType.SINGLE_PARTICLE,
      fix: newParameters.type === SystemType.SINGLE_PARTICLE || systemModel === SystemModel.XXZ
    })
  }

  const onChangeColor = (v: ColorResult) => {
    setColor(v.hex)
    setDatasetsLE([
      ...datasetsLE.slice(0, index),
      { ...datasetsLE[index], borderColor: v.hex },
      ...datasetsLE.slice(index + 1)
    ])
    setDatasetsRR([
      ...datasetsRR.slice(0, index),
      { ...datasetsRR[index], borderColor: v.hex },
      ...datasetsRR.slice(index + 1)
    ])
  }

  return (
    <>
      <Form initialValues={initialParameters} onValuesChange={handleValuesChange}>
        <Form.Item
          label={'System Type'}
          name={'type'}
          valuePropName="checked"
          wrapperCol={{ span: 16, offset: 8 }}
        >
          <Switch
            checkedChildren="Single-Particle"
            unCheckedChildren="Many-Particle"
            checked={parameters[index].type === SystemType.SINGLE_PARTICLE}
            style={{ backgroundColor: '#1677ff' }}
          />
        </Form.Item>
        <Form.Item label={'System Size'} name={'size'}>
          <Slider min={2} max={maxSize} step={2} />
        </Form.Item>
        <Form.Item label={'Disorder Potential'} name={'disorder'}>
          <Slider min={0} max={5} step={0.1} />
        </Form.Item>
        <Form.Item label={'Interaction Strength'} name={'interaction'} hidden={hidden.interaction}>
          <Slider min={0} max={5} step={0.1} />
        </Form.Item>
        <Form.Item label={'Quench Strength'} name={'delta'} hidden={hidden.delta}>
          <Slider min={-1} max={1} step={0.1} />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label={'Open BC'} name={'openbc'} valuePropName="checked">
              <Checkbox checked={initialParameters.openbc} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Fix Potential'}
              name={'fix'}
              valuePropName="checked"
              hidden={hidden.fix}
            >
              <Checkbox checked={initialParameters.fix} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonColorPicker
        show={true}
        color={color}
        pickerType={ColorPickerType.HUE_PICKER}
        disableAlpha={true}
        onChangeColor={onChangeColor}
      />
      <Button
        type="primary"
        onClick={onRemovePanel}
        style={{ display: 'block', margin: 'auto' }}
        disabled={parameters.length === 1 || disableBtn}
      >
        Remove Parameter Set
      </Button>
    </>
  )
}

export default ParameterForm
