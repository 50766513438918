import React from 'react'
import '../assets/navigation.sass'
import { naviTexts, colors } from '../utils/resources'
import { Drawer } from 'antd'

class Navigation extends React.Component<
  { count: number; color1: string; color2: string },
  { open: boolean }
> {
  constructor(props: { count: number; color1: string; color2: string }) {
    super(props)
    this.state = {
      open: false
    }
    this.showDrawer = this.showDrawer.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  componentDidUpdate(prevProps: { count: number; color1: string; color2: string }): void {
    const antBackgrounds = Array.from(
      document.getElementsByClassName('ant-drawer-content') as HTMLCollectionOf<HTMLElement>
    )
    if (antBackgrounds.length > 0) {
      const thisBackground: HTMLElement = antBackgrounds[0]
      thisBackground.style.backgroundImage = `-webkit-linear-gradient(0deg, ${this.props.color1} 0%, ${this.props.color2} 100%`
    }
  }

  clickScroll(value: string) {
    const item = document.getElementById(value)
    item?.scrollIntoView({ behavior: 'smooth' })
  }

  onHoverItem(evt: React.MouseEvent<HTMLSpanElement, MouseEvent>): void {
    const spanElm = evt.target as HTMLSpanElement
    spanElm.style.backgroundColor = colors[this.props.count % 40]
    spanElm.style.borderColor = colors[this.props.count % 40]
  }

  onLeavingItem(evt: React.MouseEvent<HTMLSpanElement, MouseEvent>): void {
    const spanElm = evt.target as HTMLSpanElement
    spanElm.style.backgroundColor = 'rgb(255, 255, 255, 0)'
    spanElm.style.borderColor = 'rgb(255, 255, 255, 0)'
  }

  showDrawer(): void {
    this.setState({ open: true })
  }

  onClose(): void {
    this.setState({ open: false })
  }

  render() {
    return (
      <>
        <div
          id="navigation"
          style={{
            backgroundImage: `-webkit-linear-gradient(0deg, ${this.props.color1} 0%, ${this.props.color2} 100%`
          }}
        >
          <div id="navigation-container">
            {naviTexts.map((item: { label: string; value: string }) => (
              <span
                className="navigation-text"
                key={item.label}
                onMouseOver={(evt) => this.onHoverItem(evt)}
                onMouseOut={(evt) => this.onLeavingItem(evt)}
                onClick={() => {
                  this.clickScroll(item.value)
                }}
              >
                {item.label}
              </span>
            ))}
          </div>
        </div>
        <div
          id="menu"
          style={{
            backgroundImage: `-webkit-linear-gradient(0deg, ${this.props.color1} 0%, ${this.props.color2} 100%`
          }}
        >
          <div id="menu-button" onClick={() => this.showDrawer()}>
            Menu
          </div>
        </div>
        <Drawer
          title="Menu"
          placement={'left'}
          closable={true}
          onClose={this.onClose}
          open={this.state.open}
          key={'leftDrawer'}
          width={150}
        >
          {naviTexts.map((item: { label: string; value: string }) => (
            <p
              className="menu-text"
              key={item.label}
              onClick={() => {
                this.onClose()
                this.clickScroll(item.value)
              }}
            >
              {item.label}
            </p>
          ))}
        </Drawer>
      </>
    )
  }
}

export default Navigation
