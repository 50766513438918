import React from 'react'
import '../assets/double-pendulum.sass'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { algorithms } from './functions'
import { GraphParametersIF, TextureIF } from '../../interface/doublePendulum'

export const DoublePendulum = (props: { parameters: GraphParametersIF; textures: TextureIF }) => {
  const { parameters, textures } = props
  const canvasDiv: any = React.useRef(null)

  const cameraRatio = window.innerWidth / window.innerHeight
  const camera = new THREE.PerspectiveCamera(100, cameraRatio, 1, 1000)
  const scene = new THREE.Scene()
  const axesHelper = new THREE.AxesHelper(5)
  camera.position.z = (parameters.length + parameters.size) * 2

  const geometry_orb0 = new THREE.SphereGeometry(0.5, 32, 16)
  const geometry_orb1 = new THREE.SphereGeometry(parameters.size, 32, 16)
  const geometry_orb2 = new THREE.SphereGeometry(parameters.size, 32, 16)
  const geometry_rod1 = new THREE.CylinderGeometry(0.3, 0.3, parameters.length, 32)
  const geometry_rod2 = new THREE.CylinderGeometry(0.3, 0.3, parameters.length, 32)
  const material0 = new THREE.MeshNormalMaterial()

  const r1 = algorithms(parameters).r1
  const r2 = algorithms(parameters).r2
  const x1 = algorithms(parameters).x1
  const y1 = algorithms(parameters).y1
  const x2 = algorithms(parameters).x2
  const y2 = algorithms(parameters).y2

  let ball_material1
  if (textures.orb1_type === 0) {
    ball_material1 = new THREE.MeshNormalMaterial()
  } else if (textures.orb1_type === 1) {
    const texture = new THREE.TextureLoader().load(textures.orb1_image)
    texture.repeat.set(1, 1)
    ball_material1 = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
  } else {
    ball_material1 = new THREE.MeshBasicMaterial({ color: textures.orb1_color })
  }

  let ball_material2
  if (textures.orb2_type === 0) {
    ball_material2 = new THREE.MeshNormalMaterial()
  } else if (textures.orb2_type === 1) {
    const texture = new THREE.TextureLoader().load(textures.orb2_image)
    texture.repeat.set(1, 1)
    ball_material2 = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
  } else {
    ball_material2 = new THREE.MeshBasicMaterial({ color: textures.orb2_color })
  }

  let rod_material1
  if (textures.rod1_type === 0) {
    rod_material1 = new THREE.MeshNormalMaterial()
  } else if (textures.rod1_type === 1) {
    const texture = new THREE.TextureLoader().load(textures.rod1_image)
    texture.repeat.set(0, 1)
    rod_material1 = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
  } else {
    rod_material1 = new THREE.MeshBasicMaterial({ color: textures.rod1_color })
  }

  let rod_material2
  if (textures.rod2_type === 0) {
    rod_material2 = new THREE.MeshNormalMaterial()
  } else if (textures.rod2_type === 1) {
    const texture = new THREE.TextureLoader().load(textures.rod2_image)
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(1, 0)
    rod_material2 = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
  } else {
    rod_material2 = new THREE.MeshBasicMaterial({ color: textures.rod2_color })
  }

  const ball0 = new THREE.Mesh(geometry_orb0, material0)
  const orb1 = new THREE.Mesh(geometry_orb1, ball_material1)
  const orb2 = new THREE.Mesh(geometry_orb2, ball_material2)
  const rod1 = new THREE.Mesh(geometry_rod1, rod_material1)
  const rod2 = new THREE.Mesh(geometry_rod2, rod_material2)

  // Change pivot position for rod1
  const box1 = new THREE.Box3().setFromObject(rod1)
  box1.getCenter(rod1.position)
  rod1.position.multiplyScalar(-1)
  const pivot1 = new THREE.Group()
  rod1.position.set(parameters.length / 2, 0, 0)
  rod1.rotateZ(Math.PI / 2)
  orb1.position.set(x1[0], y1[0], 0)
  orb1.rotateY(Math.PI / 2)

  // Change pivot position for rod2
  const box2 = new THREE.Box3().setFromObject(rod2)
  box2.getCenter(rod2.position)
  rod2.position.multiplyScalar(-1)
  rod2.rotateZ(Math.PI / 2)
  const pivot2 = new THREE.Group()
  rod2.position.set(parameters.length / 2 + parameters.size, 0, 0)
  orb2.position.set(x2[0], y2[0], 0)
  orb2.rotateY(Math.PI / 2)

  React.useEffect(() => {
    if (canvasDiv.current !== null) {
      let step = 0
      if (canvasDiv.current.childNodes.length > 0) {
        for (const item of canvasDiv.current.childNodes) item.remove()
      }
      scene.add(orb1, orb2, rod1, rod2, pivot1, pivot2, ball0, axesHelper)
      pivot1.add(rod1)
      pivot2.add(rod2)
      pivot2.position.set(parameters.length + parameters.size, 0, 0)
      const renderer = new THREE.WebGLRenderer({ alpha: true })
      renderer.setClearColor(0x000000, 0)
      renderer.setSize(canvasDiv.current.offsetWidth, canvasDiv.current.offsetHeight)
      if (parameters.run) {
        const animation1 = () => {
          pivot1.rotation.z = r1[step % r1.length]
          pivot2.position.x = x1[step % x1.length]
          pivot2.position.y = y1[step % y1.length]
          pivot2.rotation.z = r2[step % r2.length]
          orb1.position.x = x1[step % x1.length]
          orb1.position.y = y1[step % y1.length]
          orb1.rotation.z = (parameters.speed * step * Math.PI) / 180
          orb2.position.x = x2[step % x2.length]
          orb2.position.y = y2[step % y2.length]
          orb2.rotation.z = (parameters.speed * step * Math.PI) / 180
          step += 1
          renderer.render(scene, camera)
        }
        renderer.setAnimationLoop(animation1)
        canvasDiv.current.appendChild(renderer.domElement)
        const controls = new OrbitControls(camera, renderer.domElement)
        camera.position.set(0, 0, (parameters.length + parameters.size * 1.5) * 2)
        controls.update()
      } else {
        const animation2 = () => {
          pivot1.rotation.z = r1[0]
          pivot2.position.x = x1[0]
          pivot2.position.y = y1[0]
          pivot2.rotation.z = r2[0]
          orb1.position.x = x1[0]
          orb1.position.y = y1[0]
          orb2.position.x = x2[0]
          orb2.position.y = y2[0]
          renderer.render(scene, camera)
        }
        renderer.setAnimationLoop(animation2)
        canvasDiv.current.appendChild(renderer.domElement)
      }
    }
    // eslint-disable-next-line
  }, [canvasDiv, parameters, textures])

  return <div id="double-pendulum-canvas-div" ref={canvasDiv} />
}
