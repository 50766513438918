import React from 'react'
import { Button, Input, Typography } from 'antd'
import { LoadStatusIF, ValidObjectIF } from '../../interface/common'
import { validObjectData } from '../../resources/common'
const { Text } = Typography

const CommonURLInput: React.FC<{
  show: boolean
  imageElement: any
  loadStatus: LoadStatusIF
  onChangeImage: (url: string) => void
}> = ({ show, imageElement, loadStatus, onChangeImage }) => {
  const [validURL, setValidURL] = React.useState<ValidObjectIF>(validObjectData)

  const divStyle = show ? { display: 'flex' } : { display: 'none' }
  const showButton =
    imageElement.current === null
      ? false
      : imageElement.current.style.display === 'block' && imageElement.current.complete

  const loadImage = async (url: string, elem: any) => {
    return new Promise((resolve, reject) => {
      elem.onload = () => resolve(elem)
      elem.onerror = reject
      elem.src = url
    })
  }

  const onValidateURL = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new URL(event.target.value)
      if (imageElement.current !== null) {
        const elem = imageElement.current
        elem.style.display = 'block'
        await loadImage(event.target.value, elem)
          .then((resolve) => {
            setValidURL({ isValid: true, content: event.target.value })
          })
          .catch((error) => {
            elem.style.display = 'none'
            setValidURL({
              isValid: false,
              content: 'Please enter a valid URL to display the background'
            })
          })
      }
    } catch (err) {
      setValidURL({
        isValid: false,
        content: 'Please enter a valid URL to display the background'
      })
    }
  }
  return (
    <div className="common-url-input-container" style={divStyle}>
      <Input
        style={{ width: '100%' }}
        placeholder="Enter your image URL here"
        onChange={onValidateURL}
      />
      {!validURL.isValid && <Text type="danger">{validURL.content}</Text>}
      <div className="common-url-image-container">
        <img ref={imageElement} alt="common-url-input" />
      </div>
      {showButton && (
        <Button
          type="primary"
          onClick={() => onChangeImage(validURL.content)}
          style={{ width: '100%' }}
          loading={loadStatus.loading}
          disabled={loadStatus.loading}
        >
          Use Image
        </Button>
      )}
    </div>
  )
}

export default CommonURLInput
