import { DataSetIF, GraphOptionsIF, GraphParametersIF } from '../interface/common'
import { AnimationIF, FormParametersIF } from '../interface/functionAnimator'

export const initialParameters: GraphParametersIF = {
  function: 'y=\\sin(x)',
  jsFunction: 'Math.sin(x)',
  pyFunction: 'np.sin(x)',
  parameter: 'x',
  left: '-10',
  right: '10',
  title: 'y=\\sin(x)',
  xLabel: 'x',
  yLabel: 'y'
}

export const initialDataSet: DataSetIF = {
  data: [0],
  label: 'y=\\sin(x)',
  borderColor: 'rgba(255, 99, 132, 1)'
}

export const initialGraphData: GraphOptionsIF = {
  step: 80,
  plot: ['Function'],
  y: 'rgb(20, 75, 205, 1)',
  yp: 'rgba(255, 255, 0, 1)',
  ypp: 'rgba(0, 255, 66, 1)',
  title: 'rgba(0, 0, 0, 1)',
  grid: 'rgba(0, 0, 0, 1)',
  axes: 'rgba(0, 0, 0, 1)',
  background: 'rgba(255, 255, 255, 1)'
}

export const initialAnimation: AnimationIF = {
  style: 'bubble',
  point: 'circle',
  size: 20,
  speed: 10,
  spin: 10,
  rotate: true,
  color: 'rgba(255, 255, 0, 1)'
}

export const formParameters: FormParametersIF[] = [
  {
    label: 'Function',
    type: 'function'
  },
  {
    label: 'Left Bound',
    type: 'left'
  },
  {
    label: 'Right Bound',
    type: 'right'
  },
  {
    label: 'Title',
    type: 'title'
  },
  {
    label: 'Horizontal Label',
    type: 'xLabel'
  },
  {
    label: 'Vertical Label',
    type: 'yLabel'
  }
]

export const colorArray = [
  'Function Color',
  'Derivative Color',
  'Acceleration Color',
  'Title Color',
  'Grid Color',
  'Axes Color',
  'Background Color'
]
