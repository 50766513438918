import React from 'react'
import '../assets/common-components.sass'
import { Col, Row } from 'antd'
import { backgroundList } from '../../resources/common'
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons'
import { LoadStatusIF } from '../../interface/common'

const CommonImagePicker: React.FC<{
  show: boolean
  imageIndex: number
  loadStatus: LoadStatusIF
  onChangeImage: (image: string) => void
  onChangeImageIndex: (index: number) => void
}> = ({ show, imageIndex, loadStatus, onChangeImage, onChangeImageIndex }) => {
  const divStyle = show ? { display: 'flex' } : { display: 'none' }
  return (
    <Row style={divStyle}>
      {backgroundList.map((item, index) => {
        const showLoading =
          imageIndex === index && loadStatus.loading ? { display: 'block' } : { display: 'none' }
        const showLoaded =
          imageIndex === index && loadStatus.loaded ? { display: 'block' } : { display: 'none' }
        return (
          <Col
            span={8}
            key={index}
            style={index === imageIndex ? { opacity: '0.6' } : { opacity: '1' }}
          >
            <div
              className="common-image-icon-container"
              onMouseOver={() => onChangeImageIndex(index)}
              onClick={() => onChangeImage(item.url)}
            >
              <LoadingOutlined className="common-image-icon" style={showLoading} />
              <CheckCircleTwoTone
                className="common-image-icon"
                twoToneColor="#52c41a"
                style={showLoaded}
              />
            </div>
            <img
              className="common-image-picker"
              src={item.url}
              alt={`common-picker-${item.name}`}
            />
          </Col>
        )
      })}
    </Row>
  )
}

export default CommonImagePicker
