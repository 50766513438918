import { BackgroundIF, LoadStatusIF, UploadFileIF, ValidObjectIF } from '../interface/common'

export const backgroundList = [
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg0.jpg',
    name: 'homeBG0'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg1.jpg',
    name: 'homeBG1'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg2.jpg',
    name: 'homeBG2'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg3.jpg',
    name: 'homeBG3'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg4.jpg',
    name: 'homeBG4'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg5.jpg',
    name: 'homeBG5'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg6.jpg',
    name: 'homeBG6'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg7.jpg',
    name: 'homeBG7'
  },
  {
    url: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/industry/home_bg8.jpg',
    name: 'homeBG8'
  }
]

export const doublePendulumImage = 'https://assets.havkonsoft.com/projects/double_pendulum.png'

export const loadState: LoadStatusIF = {
  loading: false,
  loaded: false
}

export const validObjectData: ValidObjectIF = {
  isValid: true,
  content: ''
}

export const uploadFileData: UploadFileIF = {
  fileName: '',
  fileObject: ''
}

export const initialBackground: BackgroundIF = {
  type: 3,
  image: 'https://assets.havkonsoft.com/landing/sand.jpg',
  color: 'rgba(255, 99, 132, 1)',
  inverse: 'rgb(251, 243, 250)',
  dominant: 'rgb(4, 12, 5)'
}
