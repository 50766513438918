import React from 'react'
import '../assets/double-pendulum.sass'
import { Button, Form, Select, Slider } from 'antd'
import { FormParametersIF, GraphParametersIF } from '../../interface/doublePendulum'
import { formParameters } from '../../resources/doublPendulum'

const AnimationMenu: React.FC<{
  parameters: GraphParametersIF
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setParameters: React.Dispatch<React.SetStateAction<GraphParametersIF>>
}> = ({ parameters, setOpen, setParameters }) => {
  const onChangeParameters = (value: any, type: string) => {
    setParameters({ ...parameters, [type]: value })
  }

  const onFinish = (values: any) => {
    setParameters({ ...values, run: true })
    setOpen(false)
  }

  return (
    <Form initialValues={parameters} onFinish={onFinish} layout="vertical">
      <Form.Item label="Algorithm" name="algorithm">
        <Select
          onChange={(value: { value: string; label: React.ReactNode }) =>
            onChangeParameters(value.value, 'algorithm')
          }
          options={[
            {
              value: 'pseudo-realistic',
              label: 'Pseudo Realistic'
            },
            {
              value: 'energy-conserved',
              label: 'Energy Conserved'
            }
          ]}
        />
      </Form.Item>
      {formParameters.map((item: FormParametersIF) => (
        <Form.Item label={item.label} name={item.type} key={item.label}>
          <Slider
            min={item.min}
            max={item.max}
            onChange={(value: number) => onChangeParameters(value, item.type)}
          />
        </Form.Item>
      ))}
      <div id="animation-menu-submit-container">
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Run
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" ghost onClick={() => onChangeParameters(false, 'run')}>
            Stop
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default AnimationMenu
