import React from 'react'
import { Button, Col, Row } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'

const AddParameterSet: React.FC<{
  label: string
  disableBtn: boolean
  onClick: () => void
}> = ({ label, disableBtn, onClick }) => {
  return (
    <Row className="common-dropdown-menu">
      <Col span={6} className="common-dropdown-menu-selector">
        {label}
      </Col>
      <Col span={18} className="common-dropdown-menu-selectee">
        <Button type="primary" onClick={onClick} disabled={disableBtn}>
          <PlusCircleFilled /> Add Parameter Set
        </Button>
      </Col>
    </Row>
  )
}

export default AddParameterSet
