import React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Input, Typography } from 'antd'
import { LoadStatusIF, UploadFileIF, ValidObjectIF } from '../../interface/common'
import { uploadFileData, validObjectData } from '../../resources/common'

const { Text } = Typography

const CommonUploader: React.FC<{
  show: boolean
  imageFile: any
  loadStatus: LoadStatusIF
  onChangeImage: (image: string) => void
}> = ({ show, imageFile, loadStatus, onChangeImage }) => {
  const [validUpload, setValidUpload] = React.useState<ValidObjectIF>(validObjectData)
  const [uploadFile, setUploadFile] = React.useState<UploadFileIF>(uploadFileData)
  const [preview, setPreview] = React.useState<string>('')

  const divStyle = show ? { display: 'block' } : { display: 'none' }

  const handlePreview = (event: React.ChangeEvent<HTMLInputElement>) => {
    const thisFile = event.target.files![0]
    if (thisFile === undefined) return
    if (thisFile.size > 5242880) {
      setValidUpload({ isValid: false, content: 'Please upload a file with size less than 5MB.' })
      return
    }
    setValidUpload({ isValid: true, content: '' })
    const imageReader = new FileReader()
    imageReader.onload = (event_) => {
      setPreview(event_!.target!.result! as string)
      setUploadFile({ fileName: thisFile.name, fileObject: event_!.target!.result! as string })
    }
    imageReader.readAsDataURL(thisFile)
  }
  return (
    <div style={divStyle}>
      <div className="common-uploader-container">
        <Input
          type="file"
          ref={imageFile}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handlePreview}
        />
        <Button
          type="primary"
          disabled={loadStatus.loading}
          size="small"
          onClick={() => {
            imageFile!.current?.input.click()
          }}
        >
          Upload <UploadOutlined />
        </Button>
        <div className="common-uploader-file-name">{uploadFile.fileName}</div>
      </div>
      {!validUpload.isValid && <Text type="danger">{validUpload.content}</Text>}
      {validUpload.isValid && preview !== '' && (
        <div className="common-uploader-image-container">
          <img src={preview} alt="common-upload-img" />
        </div>
      )}
      {validUpload.isValid && preview !== '' && (
        <Button
          type="primary"
          onClick={() => onChangeImage(uploadFile.fileObject)}
          style={{ width: '100%' }}
          loading={loadStatus.loading}
          disabled={loadStatus.loading}
        >
          Use Image
        </Button>
      )}
    </div>
  )
}

export default CommonUploader
