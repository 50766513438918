import React from 'react'
import '../assets/aboutme.sass'
import { colors, skills } from '../utils/resources'

class AboutMe extends React.Component<{ count: number }, {}> {
  onHoverCard(evt: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    const cardBacks = Array.from(
      document?.getElementsByClassName('skill-card-back') as HTMLCollectionOf<HTMLElement>
    )
    const randomColor = colors[this.props.count % 40]
    cardBacks.forEach((item: any) => (item.style.backgroundColor = randomColor))
  }
  render() {
    return (
      <div id="aboutme">
        <div id="aboutme-container">
          <div id="aboutme-text-container">
            <h1>ABOUT MYSELF</h1>
            <h4>
              I am a full stack software developer focusing on web applications. I have many years
              of working experience in scientific research, application development and system
              engineering. My primary tech stacks include Python, Django, Node.js, React.js, Linux,
              Docker and AWS. I have extensively used Django for backend applications, React.js for
              frontend development, AWS for web services and Docker for microservices. I enjoy
              working-out, reading books, playing video games and dinning out for pastime
              activities. My career goal is to continue to learn and and become an expert software
              developer.
            </h4>
          </div>
          <div id="aboutme-skills-container">
            {skills.map(
              (item: { title: string; image: string; skills: string[] }, index1: number) => (
                <div
                  className="aboutme-skills"
                  key={index1}
                  onMouseOver={(evt) => this.onHoverCard(evt)}
                >
                  <div className="skill-card">
                    <div className="skill-card-inner">
                      <div
                        className="skill-card-front"
                        style={{ borderColor: colors[this.props.count % 40] }}
                      >
                        <img src={item.image} alt={item.title} />
                        <h3>{item.title}</h3>
                      </div>
                      <div
                        className="skill-card-back"
                        style={{ borderColor: colors[this.props.count % 40] }}
                      >
                        {item.skills.map((skill: string, index2: number) =>
                          index2 === item.skills.length - 1 ? `${skill}, etc` : `${skill}, `
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AboutMe
