import { ReferenceIF } from '../../interface/loschmidtEcho'

export const goToReference = (items: ReferenceIF[], mark: string) => {
  return (
    <span>
      {' '}
      [
      {items.map((item: ReferenceIF, index: number) =>
        index > 0 ? (
          <span
            key={index}
            className="loschmidt-echo-go-to-reference"
            onClick={() => {
              const element = document.getElementById(`loschmidt-echo-reference-${item.order}`)
              element!.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            , {item.order}
          </span>
        ) : (
          <span
            key={index}
            className="loschmidt-echo-go-to-reference"
            onClick={() => {
              const element = document.getElementById(`loschmidt-echo-reference-${item.order}`)
              element!.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            {item.order}
          </span>
        )
      )}
      ]{`${mark} `}
    </span>
  )
}

export const expandPanel = (setDisableBtn: React.Dispatch<React.SetStateAction<boolean>>) => {
  const parameterSet = Array.from(
    document.getElementsByClassName(
      'ant-collapse-item loschmidt-echo-parameter-set'
    ) as HTMLCollectionOf<HTMLElement>
  )
  const params = parameterSet.length
  if (parameterSet[params - 1]) {
    const firstChild = parameterSet[params - 1]!.firstChild as HTMLElement
    firstChild.click()
  }
  setTimeout(() => {
    const parameterSet = Array.from(
      document.getElementsByClassName(
        'ant-collapse-item loschmidt-echo-parameter-set'
      ) as HTMLCollectionOf<HTMLElement>
    )
    const total = parameterSet.length
    if (parameterSet[total - 1]) {
      const firstChild = parameterSet[total - 1]!.firstChild as HTMLElement
      firstChild.click()
    }
    setDisableBtn(parameterSet!.length >= 5)
  }, 500)
}

