const NumericalSVG = (props: { id: string }) => {
  return (
    <g fill={`url(#${props.id})`}>
      <g>
        <g>
          <path
            d="M517.661,420.29c-26.8,0-48.602,21.803-48.602,48.6c0,26.8,21.803,48.602,48.602,48.602
				c26.798,0,48.601-21.803,48.601-48.602C566.262,442.094,544.459,420.29,517.661,420.29z"
          />
          <path
            d="M66.593,420.29c-26.8,0-48.602,21.803-48.602,48.6c0,26.8,21.803,48.602,48.602,48.602
				c26.798,0,48.601-21.803,48.601-48.602C115.193,442.094,93.391,420.29,66.593,420.29z"
          />
          <path
            d="M195.469,420.29c-26.8,0-48.602,21.803-48.602,48.6c0,26.8,21.803,48.602,48.602,48.602s48.602-21.803,48.602-48.602
				C244.072,442.094,222.269,420.29,195.469,420.29z"
          />
          <path
            d="M324.346,420.29c-26.798,0-48.6,21.803-48.6,48.6c0,26.8,21.803,48.602,48.6,48.602c26.8,0,48.602-21.803,48.602-48.602
				C372.948,442.094,351.145,420.29,324.346,420.29z"
          />
          <path
            d="M215.265,391.092V164.477h-39.592v226.616c6.335-1.613,12.966-2.475,19.796-2.475
				C202.299,388.617,208.93,389.479,215.265,391.092z"
          />
          <path
            d="M563.352,62.528H16.668C7.478,62.528,0,70.006,0,79.196v36.939c0,9.19,7.478,16.668,16.668,16.668h546.684
				c9.19,0,16.668-7.478,16.668-16.668V79.196C580.02,70.006,572.543,62.528,563.352,62.528z"
          />
          <path
            d="M86.389,391.092V164.477H46.797v226.616c6.335-1.613,12.966-2.475,19.796-2.475
				C73.422,388.617,80.054,389.479,86.389,391.092z"
          />
          <path
            d="M344.141,391.092V164.477H304.55v226.616c6.335-1.613,12.966-2.476,19.796-2.476
				C331.176,388.617,337.807,389.479,344.141,391.092z"
          />
          <path
            d="M517.661,388.617c5.405,0,10.684,0.545,15.792,1.568l-84.641-225.709h-42.285l85.621,228.321
				C500.169,390.102,508.743,388.617,517.661,388.617z"
          />
        </g>
      </g>
    </g>
  )
}

export default NumericalSVG
