import axios from 'axios'

// export const websocketHost = () => {
//   const server = process.env.REACT_APP_API_URL
//   if (server!.includes('http://')) {
//     return server!.replace('http://', 'ws://')
//   } else {
//     return server!.replace('https://', 'wss://')
//   }
// }

export const request = async (url: string, data: any, method: any) => {
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8'
  }

  if (data && data instanceof FormData) headers['Content-Type'] = 'multipart/form-data'

  const api_url = `${process.env.REACT_APP_API_URL}/${url}`

  return axios(api_url, { method, headers, data })
}

// export const requestBinary = async (url: string, token: any, body: any, method: any) => {
//   const cookie = localStorage.getItem('sessionCookie') || EM.STAT.status.COOKIE
//   const headers = {
//     Authorization: '',
//     'Content-Type': 'application/json;charset=UTF-8',
//     'X-CSRFToken': cookie
//   }
//   const api_url = `${process.env.REACT_APP_API_URL}${url}`

//   if (token !== null) headers['Authorization'] = `Bearer ${token}`
//   if (body && body instanceof FormData) headers['Content-Type'] = 'multipart/form-data'
//   if (!(body instanceof FormData)) body = JSON.stringify(body)

//   return axios(api_url, {
//     responseType: 'arraybuffer',
//     method: method,
//     headers: headers,
//     data: body
//   })
// }
