import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { goToReference } from './functions'
import { references } from '../../resources/loschmidtEcho'
import { mathJaxConfig } from '../../resources/loschmidtEcho'

const TheSSHModel = () => {
  return (
    <MathJaxContext version={3} config={mathJaxConfig}>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`The Su-Schrieffer-Heeger (SSH) model has served as the
          standard model of a 1D system characterized by charge fractionalization and 
          topological properties`}
        {goToReference(references.slice(8, 10), '.')}
        {`The Su-Shrieffer-Heeger (SSH) model describes spinless fermions hopping on a 
          one-dimensional lattice with staggered hopping amplitudes. As shown in Figure 2.1, 
          the chain consist of $N$ unit cells, and each unit cell has two sites: one on 
          sublattice $A$ and another on sublattice $B$. The lattice structure of the SSH model 
          has only one conduction electron attached to each carbon atom, so each unit cell of 
          the fermionic chain can only have one particle. This situation, called half-filling, 
          can only happen for fermionic systems with zero chemical potential, and all fermions 
          specifying all negative energy eigenstates are singly occupied as the ground state of 
          the Hamiltonian. Such state is a preferable initiate state of a closed system to 
          investigate localization phenomena.`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/ssh_1.png'}
          alt="loschmidt-echo-schematics"
        />
        <MathJax className="align-left">
          {`Figure 2.1: Geometry of the SSH model. Filled (empty) circles are sites on sublattice 
            $A (B)$, each hosting a single state. Hopping amplitudes are staggered: intracell 
            hopping $v$ (double lines) is different from intercell hopping $w$ (single lines)`}
          {goToReference(references.slice(10, 11), '.')}
        </MathJax>
      </div>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`For a system with N unit cells, there are L = 2N lattice sites and N eigenstates
          satisfying half-flling. The hopping amplitudes are assumed to be real and nonnegative,
          $v, w > 0$. Due to the absence of interaction and onsite potential, the SSH model can
          be described by the single-particle Hamiltonian operator
          $$\\mathcal{\\hat{H}}=v\\sum^N_{n=1}(|\\psi_{n,A}\\rangle\\langle\\psi_{n,B}|+h.c.)+w\\sum^{N-1}_{n=1}(|\\psi_{n+1,A}\\rangle\\langle\\psi_{n,B}|+h.c.).$$
          Here $|\\psi_{n,A}\\rangle$ and $|\\psi_{n,B}\\rangle$, with $n\\in\\{1,2,...,N\\}$, denote 
          the state of the chain where the electron is on unit cell $j$, in the sublattice site 
          $A$, and $B$, respectively; $h.c.$ stands for Hermitian Conjugate (e.g., $h.c.$ of 
          $|\\psi_{n,B}\\rangle\\langle\\psi_{n,A}$ is $|\\psi_{n,A}\\rangle\\langle\\psi_{n,B}$). 
          $v$ is hopping amplitude for intracell hopping, and $w$ is the intercell hopping amplitude`}
        {goToReference(references.slice(10, 11), '.')}
        {`This is the first quantization representation of the single-particle SSH Hamiltonian.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`The single-particle operator $\\hat{\\mathcal{H}}$ is diagonal in the basis 
          $\\{\\psi_{n,l}\\rangle\\}$, where $l=A,B$ is the sublattice index. For $L=2N$ lattice 
          sites, we can use the following indices
          $$|\\psi_{2j-1}\\rangle=|\\psi_{n,A}\\rangle,~|\\psi_{2j}\\rangle=|\\psi_{n,B}\\rangle.$$
          The second quantization representation of acting on 
          $|\\psi_j\\rangle$ can therefore be expressed as
          $$\\hat{H}=\\sum^L_{j}\\langle\\psi_{j}|\\mathcal{\\hat{H}}|\\psi_{j+1}\\rangle c^\\dagger_jc_{j+1}+h.c.,$$
          where $c^\\dagger_j$ and $c_j$ are the fermion creation and annihilation operators on 
          site $j$. The matrix $\\hat{H}=\\sum^L_{j}\\langle\\psi_{j}|\\mathcal{\\hat{H}}|\\psi_{j+1}\\rangle$ 
          has the following single-particle expression for periodic boundary conditions (PBC),`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/ssh_2.png'}
          alt="ssh-Hamiltonian"
        />
      </div>
      <MathJax className="loschmidt-echo-normal-container">
        {`$\\mathcal{H}$ for open boundary conditions (OBC) omits the two $w$'s on the upper-right 
          and lower-left corners. Let $v=J(1-\\delta)$ and $w=J(1+\\delta)$, we arrive the second 
          quantization representation of the many-particle Hamiltonian for the SSH model
          $$\\hat{H}=J\\sum^L_{j=1}[(1+\\delta e^{i\\pi j})\\hat{c}^\\dagger_j\\hat{c}_{j+1} + h.c.],$$
          Here $\\delta\\in[-1,1]$ is the dimerization, and $J$ is the hopping amplitude for 
          nearest-neighbor sites and is set to 1 for numerical calculations, but we include $J$ in 
          the formula expressions. For periodic boundary conditions the sum is taken up to site $L$ 
          and $c_{L+1}= c_1$ while the sum is taken up to $L-1$  for open boundary conditions. 
          We will soon discuss the difference between the single-particle and the many-particle 
          Hamiltonians.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`Let $v=J(1-\\delta)$ and $w=J(1+\\delta)$, we arrive the second quantization 
          representation of the many-particle Hamiltonian for the SSH model
          $$\\hat{H}=J\\sum^L_{j=1}[(1+\\delta e^{i\\pi j})\\hat{c}^\\dagger_j\\hat{c}_{j+1} + h.c.],$$
          Here $\\delta\\in[-1,1]$ is the dimerization, and $J$ is the hopping amplitude for 
          nearest-neighbor sites and is set to 1 for numerical calculations, but we include $J$ 
          in the formula expressions. For periodic boundary conditions the sum is taken up to 
          site $L$ and $c_{L+1}= c_1$ while the sum is taken up to $L-1$ for open boundary 
          conditions.`}
      </MathJax>
    </MathJaxContext>
  )
}

export default TheSSHModel
