import React from 'react'
import '../assets/double-pendulum.sass'
import type { CollapseProps } from 'antd'
import { Button, Collapse, Drawer, theme } from 'antd'
import { addStyles, StaticMathField } from 'react-mathquill'
import { DoublePendulum } from '../components/DoublePendulum'
import AnimationMenu from '../components/AnimationMenu'
import BackgroundMenu from '../components/BackgroundMenu'
import { BackgroundIF } from '../../interface/common'
import { GraphParametersIF } from '../../interface/doublePendulum'
import { initialParameters, initialTextures } from '../../resources/doublPendulum'
import { doublePendulumImage, initialBackground } from '../../resources/common'
import CommonContainer from '../../common/components/CommonContainer'

addStyles()

const DoublePendulumContainer = () => {
  const { token } = theme.useToken()
  const [open, setOpen] = React.useState<boolean>(false)

  const commonStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '1rem',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG
  }
  const panelStyle = { ...commonStyle, border: 'none' }
  const panelStyleBottom: React.CSSProperties = {
    ...commonStyle,
    borderBottom: '1px solid #d9d9d9'
  }

  const [background, setBackground] = React.useState<BackgroundIF>(initialBackground) // static page data
  const [parameters, setParameters] = React.useState<GraphParametersIF>(initialParameters)

  const getItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Animation Parameters',
      showArrow: false,
      style: panelStyle,
      children: (
        <AnimationMenu parameters={parameters} setOpen={setOpen} setParameters={setParameters} />
      )
    },
    {
      key: '2',
      label: 'Background Selection',
      showArrow: false,
      style: panelStyleBottom,
      children: <BackgroundMenu background={background} setBackground={setBackground} />
    }
  ]

  const buttons = (
    <>
      <Button
        type="primary"
        onClick={() => {
          setParameters({ ...parameters, run: true })
          setOpen(false)
        }}
      >
        Run
      </Button>
      <Button
        ghost
        type="primary"
        style={{ marginLeft: '10px' }}
        onClick={() => {
          setParameters({ ...parameters, run: false })
          setOpen(false)
        }}
      >
        Stop
      </Button>
    </>
  )

  const backgroundStyle: React.CSSProperties =
    background.type === 3 ? { background: background.color } : { backgroundImage: background.image }

  const menuElement = (
    <div>
      <div id="common-application-menu-title">
        <h2>Double Pendulum Simulation</h2>
        <div id="double-pendulum-buttons">{buttons}</div>
      </div>
      <div id="double-pendulum-description">
        <p>
          A double pendulum is a pendulum with another pendulum attached to its end. Our double
          pendulum has the following configuration:
        </p>
        <img src={doublePendulumImage} alt="double-pendulum-question-img" />
        <p>
          Assume that the motion is restricted to the <i>x-y</i>-plane and that the limbs are taken
          to be identical compound pendulums of length <i>l</i> and mass <i>m</i>. One limb has the
          moment of inertia of <StaticMathField>{'I=\\frac{1}{12}ml^2'}</StaticMathField>. We use
          the angle between each limb and the vertical as the generalized coordinates. The
          Lagrangian of the system described above has the following form:
        </p>
        <div>
          <StaticMathField>
            {
              'L = \\frac{1}{6}ml^2\\left(\\dot{\\theta}^2_2+4\\dot{\\theta}^2_1+3\\dot{\\theta_1}\\dot{\\theta_2}cos(\\theta_1-\\theta_2)\\right)+\\frac{1}{2}mgl(3cos(\\theta_1+cos(\\theta_2)'
            }
          </StaticMathField>
        </div>
      </div>
      <Collapse accordion items={getItems} />
    </div>
  )

  const componentElement = <DoublePendulum textures={initialTextures} parameters={parameters} />

  const onClose = () => {
    setOpen(false)
  }

  const drawerElement = (
    <Drawer
      title="Web Applications"
      placement="left"
      closable={true}
      onClose={onClose}
      open={open}
      key="left-drawer"
      width={window.innerWidth}
    >
      {menuElement}
    </Drawer>
  )

  return (
    <CommonContainer
      initialWidth={547}
      setOpen={setOpen}
      backgroundStyle={backgroundStyle}
      menuElement={menuElement}
      componentElement={componentElement}
      drawerElement={drawerElement}
    />
  )
}

export default DoublePendulumContainer
