import React from 'react'
import { ReferenceIF } from '../../interface/loschmidtEcho'

const CommonReferences: React.FC<{ references: ReferenceIF[]; tagPrefix: string }> = ({
  references,
  tagPrefix
}) => {
  const getReference = (title: string, link: string) => {
    if (title.includes('</i>')) {
      const titleLeft = title.split('<i>')[0]
      const italics = title.split('<i>')[1].split('</i>')[0]
      const titleRight = title.split('</i>')[1]
      return (
        <>
          {titleLeft}
          <i>{italics}</i>
          {titleRight}{' '}
          <a href={link} target="_blank" rel="noopener noreferrer">
            LINK
          </a>
        </>
      )
    }
    return (
      <>
        {title} <a href={link}>LINK</a>
      </>
    )
  }

  return (
    <div id="common-application-references">
      <h3>References</h3>
      <ol type="1" style={{ paddingLeft: '20px' }}>
        {references.map((item: ReferenceIF, index: number) => (
          <li key={index} id={`${tagPrefix}-${item.order}`} className='common-application-references-list'>
            {getReference(item.title, item.link)}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default CommonReferences
