import { Button, Popover } from 'antd'
import '../assets/common-components.sass'
import { ColorResult } from 'react-color'
import { ChromePicker, HuePicker, SwatchesPicker } from 'react-color'
import { ColorPickerType } from '../../enum'

const CommonColorPicker = (props: {
  show: boolean
  color: string
  pickerType: ColorPickerType
  disableAlpha: boolean
  onChangeColor: (value: ColorResult) => void
}) => {
  const { show, color, pickerType, disableAlpha, onChangeColor } = props
  const divStyle = show ? { display: 'flex' } : { display: 'none' }

  const content = () => {
    return <SwatchesPicker color={color} onChange={(color: ColorResult) => onChangeColor(color)} />
  }

  return (
    <div className="common-color-picker" style={divStyle}>
      {pickerType === ColorPickerType.HUE_PICKER && (
        <HuePicker
          color={color}
          width={'100%'}
          onChange={(value: ColorResult) => onChangeColor(value)}
        />
      )}
      {pickerType === ColorPickerType.CHROME_PICKER && (
        <ChromePicker
          color={color}
          disableAlpha={disableAlpha}
          onChange={(value: ColorResult) => onChangeColor(value)}
        />
      )}
      <Popover placement="bottom" content={content()} trigger="click">
        <Button type="primary" style={{ backgroundColor: color }}>
          More Color Options
        </Button>
      </Popover>
    </div>
  )
}

export default CommonColorPicker
