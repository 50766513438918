import React from 'react'
import '../assets/function-animator.sass'
import { Collapse, Drawer, theme } from 'antd'
import type { CollapseProps } from 'antd'
import { DataSetIF, GraphOptionsIF, MinMaxIF, GraphParametersIF } from '../../interface/common'
import { AnimationIF } from '../../interface/functionAnimator'
import {
  initialDataSet,
  initialParameters,
  initialGraphData,
  initialAnimation
} from '../../resources/functionAnimator'
import { generateData, handleDatasets, jsFunctionFilter } from '../components/functions'
import { BackgroundIF } from '../../interface/common'
import { initialBackground } from '../../resources/common'
import FunctionAnimator from '../components/FunctionAnimator'
import BackgroundMenu from '../components/BackgroundMenu'
import CommonContainer from '../../common/components/CommonContainer'
import CommonFunctionGraph from '../../common/components/CommonFunctionGraph'
import FunctionMenu from '../components/FunctionMenu'
import GraphMenu from '../components/GraphMenu'
import AnimationMenu from '../components/AnimationMenu'

const FunctionAnimatorContainer = () => {
  const { token } = theme.useToken()
  const [open, setOpen] = React.useState<boolean>(false)

  const commonStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '1rem',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG
  }
  const panelStyle = { ...commonStyle, border: 'none' }
  const panelStyleBottom: React.CSSProperties = {
    ...commonStyle,
    borderBottom: '1px solid #d9d9d9'
  }

  const [labels, setLabels] = React.useState<number[]>([0])
  const [datasets, setDatasets] = React.useState<DataSetIF[]>([initialDataSet])
  const [background, setBackground] = React.useState<BackgroundIF>(initialBackground)
  const [parameters, setParameters] = React.useState<GraphParametersIF>(initialParameters)
  const [graphOptions, setGraphOptions] = React.useState<GraphOptionsIF>(initialGraphData)
  const [animation, setAnimation] = React.useState<AnimationIF>(initialAnimation)
  const [yMinMax, setYMinMax] = React.useState<MinMaxIF>({ min: 0, max: 0 })

  React.useEffect(() => {
    const jsFunction = jsFunctionFilter(parameters.function, parameters.parameter)
    if (jsFunction !== 'invalid') {
      const result = generateData(
        jsFunction,
        Number(parameters.left),
        Number(parameters.right),
        -0.01 * graphOptions.step + 1.01,
        parameters.parameter
      )
      setLabels(result.xValues)
      handleDatasets(graphOptions, result, setDatasets, setYMinMax)
    }
    // eslint-disable-next-line
  }, [parameters, graphOptions])

  const getItems: (panelStyle: React.CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      label: 'Function Parameters',
      showArrow: false,
      style: panelStyle,
      children: (
        <FunctionMenu parameters={parameters} setOpen={setOpen} setParameters={setParameters} />
      )
    },
    {
      key: '2',
      label: 'Graph Setting',
      showArrow: false,
      style: panelStyle,
      children: (
        <GraphMenu
          graphOptions={graphOptions}
          setOpen={setOpen}
          setGraphOptions={setGraphOptions}
        />
      )
    },
    {
      key: '3',
      label: 'Animation Setting',
      showArrow: false,
      style: panelStyle,
      children: (
        <AnimationMenu animation={animation} setOpen={setOpen} setAnimation={setAnimation} />
      )
    },
    {
      key: '4',
      label: 'Background Setting',
      showArrow: false,
      style: panelStyleBottom,
      children: <BackgroundMenu background={background} setBackground={setBackground} />
    }
  ]

  const backgroundStyle: React.CSSProperties =
    background.type === 3 ? { background: background.color } : { backgroundImage: background.image }

  const menuElement = (
    <div>
      <div id="common-application-menu-title">
        <h2>Function Animator</h2>
      </div>
      <div id="function-animator-description">
        <CommonFunctionGraph
          labels={labels}
          datasets={datasets}
          parameters={parameters}
          graphOptions={graphOptions}
          yMinMax={yMinMax}
          showTitle={true}
          customStyle={{ width: '100%' }}
        />
      </div>
      <Collapse accordion items={getItems(panelStyle)} />
    </div>
  )

  const componentElement = (
    <FunctionAnimator animation={animation} datasets={datasets} labels={labels} yMinMax={yMinMax} />
  )

  const onClose = () => {
    setOpen(false)
  }

  const drawerElement = (
    <Drawer
      title="Web Applications"
      placement="left"
      closable={true}
      onClose={onClose}
      open={open}
      key="left-drawer"
      width={window.innerWidth}
    >
      {menuElement}
    </Drawer>
  )

  return (
    <CommonContainer
      initialWidth={547}
      setOpen={setOpen}
      backgroundStyle={backgroundStyle}
      menuElement={menuElement}
      componentElement={componentElement}
      drawerElement={drawerElement}
    />
  )
}

export default FunctionAnimatorContainer
