export const naviTexts = [
  {
    label: 'Home',
    value: 'introduction'
  },
  {
    label: 'About Me',
    value: 'aboutme'
  },
  {
    label: 'Experiences',
    value: 'experience'
  },
  {
    label: 'Services',
    value: 'services'
  },
  {
    label: 'Projects',
    value: 'projects'
  },
  {
    label: 'Contact',
    value: 'contact'
  }
]

export const colors = [
  'rgb(255, 255, 0)',
  'rgb(216, 255, 0)',
  'rgb(180, 255, 0)',
  'rgb(144, 255, 0)',
  'rgb(108, 255, 0)',
  'rgb(72, 255, 0)',
  'rgb(36, 255, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 255, 36)',
  'rgb(0, 255, 72)',
  'rgb(0, 255, 108)',
  'rgb(0, 255, 144)',
  'rgb(0, 255, 180)',
  'rgb(0, 255, 216)',
  'rgb(0, 255, 255)',
  'rgb(0, 216, 255)',
  'rgb(0, 180, 255)',
  'rgb(0, 144, 255)',
  'rgb(0, 108, 255)',
  'rgb(0, 72, 255)',
  'rgb(0, 36, 255)',
  'rgb(0, 0, 255)',
  'rgb(36, 0, 255)',
  'rgb(72, 0, 255)',
  'rgb(108, 0, 255)',
  'rgb(144, 0, 255)',
  'rgb(180, 0, 255)',
  'rgb(216, 0, 255)',
  'rgb(255, 0, 255)',
  'rgb(255, 0, 225)',
  'rgb(255, 0, 172)',
  'rgb(255, 0, 129)',
  'rgb(255, 0, 86)',
  'rgb(255, 0, 43)',
  'rgb(255, 0, 0)',
  'rgb(255, 43, 0)',
  'rgb(255, 86, 0)',
  'rgb(255, 129, 0)',
  'rgb(255, 172, 0)',
  'rgb(255, 225, 0)'
]

export const colorTypes = [
  'drop-shadow',
  'brightness',
  'blur',
  'contrast',
  'grayscale',
  'hue-rotate',
  'invert',
  'opacity',
  'saturate',
  'sepia'
]

export const skills = [
  {
    title: 'Software Development',
    image: 'https://assets.havkonsoft.com/landing/skill_sd.png',
    skills: [
      'System Design',
      'Distributed System',
      'Asynchronous',
      'Concurrency',
      'Parallel Programming',
      'Python',
      'Django',
      'Flask',
      'JavaScript',
      'SQL',
      'NoSQL',
      'Caching',
      'Celery',
      'NodeJS',
      'Git',
      'Linux',
      'CI/CD',
      'Nginx',
      'Docker',
      'ReactJS',
      'VueJS',
      'AngularJS',
      'AWS',
      'GCP',
      'Terraform'
    ]
  },
  {
    title: 'Scientific Research',
    image: 'https://assets.havkonsoft.com/landing/skill_sr.png',
    skills: [
      'Python',
      'Mathematica',
      'Matlab',
      'Numpy',
      'SciPy',
      'math modeling',
      'large matrix calculation',
      'root finder',
      'interpolation',
      'differential equations',
      'finite difference approximation',
      'double pendulum',
      'optimization'
    ]
  },
  {
    title: 'Machine Learning',
    image: 'https://assets.havkonsoft.com/landing/skill_ml.png',
    skills: [
      'Tensorflow',
      'PyTorch',
      'Numpy',
      'SciPy',
      'Scikit-learn',
      'Pandas',
      "Bellman's equation",
      'SVM',
      'K-NN'
    ]
  }
]

export const experiences = [
  {
    date: 'Jun 2019 to Present',
    title: 'Havkonsoft Inc.',
    content: [
      'Full Stack Software Developer',
      'Developed and maintained multiple web applications',
      'Django, React.js, Node.js, PostgreSQL, Docker, AWS'
    ]
  },
  {
    date: 'Apr 2022 to Dec 2022',
    title: 'BC Registry Online Services',
    content: [
      'Full Stack Software Developer',
      'Developed and maintained multiple web applications',
      'Vue.js, Flask, Pytest, Jest, Lerna, Agile/Scrum, '
    ]
  },
  {
    date: 'Mar 2021 to Mar 2022',
    title: 'Discover My Franchise',
    content: [
      'Full Stack Software Developer',
      'Developed and maintained multiple web applications',
      'Django, React.js, PostgreSQL, AWS, GCP, Docker, Terraform'
    ]
  },
  {
    date: 'Dec 2020 to Mar 2021',
    title: 'Securigene Technologies',
    content: [
      'Full Stack Software Developer',
      'Initialized an e-commerce web application',
      'Django, React.js, Pandas, PostgreSQL, AWS, Docker, Heroku'
    ]
  },
  {
    date: 'Sep 2016 to May 2019',
    title: 'University of Manitoba',
    content: [
      'Scientific Researcher / Graduate Student',
      'Formulated a set of unique algorithms for computing' +
        'dynamical phase transitions for strongly-correlated, ' +
        'disordered, closed quantum systems',
      'Python, Matlab, Mathematica, Numpy, Finite Difference Approach'
    ]
  }
]

export const education = [
  {
    date: 'Sep 2016 to May 2019',
    title: 'University of Manitoba',
    content: [
      'Master of Science',
      'Condensed Matter Physics Major',
      'Quantum Mechanics, Electromagnetism, Computational Physics'
    ]
  },
  {
    date: 'Sep 2009 to May 2015',
    title: 'University of British',
    content: [
      'Bachelor of Science',
      'Physics Major',
      'Quantum Mechanics, Statistical Mechanics, Solid State Physics'
    ]
  }
]

export const services = [
  {
    title: 'FRONTEND DEVELOPMENT',
    key: 'frontend',
    color1: 'black',
    color2: 'black',
    viewBox: '0 0 100 100',
    background1: 'white',
    background2: 'white',
    body: 'Build modern frontend applications using React, Vue or Angular frameworks integrated with third-party CSS libraries. Build conventional frontend applications with HTML, CSS, jQuery and AJAX.'
  },
  {
    title: 'BACKEND DEVELOPMENT',
    key: 'backend',
    color1: 'black',
    color2: 'black',
    viewBox: '0 0 550 550',
    background1: 'white',
    background2: 'white',
    body: 'Build backend applications with Django, Node.js, ASP.NET frameworks integrated with relational or NoSQL database, system caching and third-party packages for complete, versatile functionalities.'
  },
  {
    title: 'CLOUD SYSTEM',
    key: 'cloud',
    color1: 'black',
    color2: 'black',
    viewBox: '0 0 65 65',
    background1: 'white',
    background2: 'white',
    body: 'Deploy production-ready applications to cloud computing services hosted on AWS, GCP or Azure cloud infrastructures. Manage applications with version control softwares and keep them updated.'
  },
  {
    title: 'MACHINE LEARNING',
    key: 'machine',
    color1: 'black',
    color2: 'black',
    viewBox: '0 0 32 32',
    background1: 'white',
    background2: 'white',
    body: 'Build machine learning applications by training existing data with Tensorflow, Keras or PyTorch. Build AI-heavy applications by integrating ChatGPT or Generative AI third-party packages.'
  },
  {
    title: 'NUMERICAL SOLUTIONS',
    key: 'numerical',
    color1: 'black',
    color2: 'black',
    viewBox: '0 0 580.02 580.02',
    background1: 'white',
    background2: 'white',
    body: 'Implement numerical solutions for mathematical models using Python and computational techniques like finite difference approximation, matrix calculation, optimization, etc.'
  }
]

export const projects = [
  {
    title: 'Double Pendulum',
    type: 'React Application',
    image: 'https://assets.havkonsoft.com/landing/double_pendulum.gif',
    color1: 'black',
    color2: 'black',
    background1: 'white',
    background2: 'white',
    body: 'Build modern frontend applications using React, Vue or Angular frameworks integrated with third-party CSS libraries. Build conventional frontend applications with HTML, CSS, jQuery and AJAX.',
    url: '/project/double-pendulum'
  },
  {
    title: 'Function Animator',
    type: 'React Application',
    image: 'https://assets.havkonsoft.com/landing/function_animator.gif',
    color1: 'black',
    color2: 'black',
    background1: 'white',
    background2: 'white',
    body: 'Build backend applications with Django, Node.js, ASP.NET frameworks integrated with relational or NoSQL database, system caching and third-party packages for complete, versatile functionalities.',
    url: '/project/function-animator'
  },
  {
    title: 'Loschmidt Echo Research',
    type: 'Scientific Research',
    image: 'https://assets.havkonsoft.com/landing/loschmidt_echo.png',
    color1: 'black',
    color2: 'black',
    background1: 'white',
    background2: 'white',
    body: 'Deploy production-ready applications to cloud computing services hosted on AWS, GCP or Azure cloud infrastructures. Manage applications with version control softwares and keep them updated.',
    url: '/project/loschmidt-echo'
  },
  {
    title: 'Physics Simulations',
    type: 'Computational Physics',
    image: 'https://assets.havkonsoft.com/landing/mandelbro_set.png',
    color1: 'black',
    color2: 'black',
    background1: 'white',
    background2: 'white',
    body: 'Deploy production-ready applications to cloud computing services hosted on AWS, GCP or Azure cloud infrastructures. Manage applications with version control softwares and keep them updated.',
    url: '/project/physics-simulations'
  }
]

export const contactSelect = [
  { label: 'Business Opportunities', value: 'business' },
  { label: 'Job Opportunities', value: 'job' },
  { label: 'Project Inquiries', value: 'project' },
  { label: 'Company Inquiries', value: 'company' },
  { label: 'Other Inquiries', value: 'other' }
]
