import React from 'react'
import CommonMenu from '../../common/components/CommonMenu'
import CommonImagePicker from '../../common/components/CommonImagePicker'
import CommonColorPicker from '../../common/components/CommonColorPicker'
import CommonURLInput from '../../common/components/CommonURLInput'
import CommonUploader from '../../common/components/CommonUploader'
import { LoadStatusIF } from '../../interface/common'
import { BackgroundIF } from '../../interface/common'
import { loadState } from '../../resources/common'
import { ColorPickerType } from '../../enum'
import { ColorResult } from 'react-color'

const BackgroundMenu: React.FC<{
  background: BackgroundIF
  setBackground: React.Dispatch<React.SetStateAction<BackgroundIF>>
}> = ({ background, setBackground }) => {
  const [menuIndex, setMenuIndex] = React.useState<number>(0)
  const [imageIndex, setImageIndex] = React.useState<number>(0)
  const [loadStatus, setLoadStatus] = React.useState<LoadStatusIF>(loadState)
  const imageElement: any = React.useRef(null)
  const imageFile: any = React.useRef(null)

  const menuArray: string[] = ['Image', 'URL', 'Upload', 'Color']

  const onChangeImage = async (image: string) => {
    setLoadStatus({ loading: true, loaded: false })
    setBackground({
      ...background,
      image: `url("${image}")`,
      type: menuIndex
    })
    setLoadStatus({ loading: false, loaded: true })
    setTimeout(() => {
      setLoadStatus({ loading: false, loaded: false })
    }, 2000)
  }

  const onChangeMenuIndex = (value: number) => {
    setMenuIndex(value)
  }

  const onChangeImageIndex = async (index: number) => {
    setImageIndex(index)
    if (index !== imageIndex) setLoadStatus({ loading: false, loaded: false })
  }

  const onChangeColor = (v: ColorResult) => {
    const thisColor = `rgba(${v.rgb.r}, ${v.rgb.g}, ${v.rgb.b}, ${v.rgb.a})`
    setBackground({ ...background, color: thisColor, type: menuIndex })
  }

  return (
    <div>
      <CommonMenu label={'Selector'} menuArray={menuArray} onChangeMenuIndex={onChangeMenuIndex} />
      <CommonImagePicker
        show={menuIndex === 0}
        imageIndex={imageIndex}
        loadStatus={loadStatus}
        onChangeImage={onChangeImage}
        onChangeImageIndex={onChangeImageIndex}
      />
      <CommonURLInput
        show={menuIndex === 1}
        imageElement={imageElement}
        loadStatus={loadStatus}
        onChangeImage={onChangeImage}
      />
      <CommonUploader
        show={menuIndex === 2}
        imageFile={imageFile}
        loadStatus={loadStatus}
        onChangeImage={onChangeImage}
      />
      <CommonColorPicker
        show={menuIndex === 3}
        color={background.color}
        pickerType={ColorPickerType.CHROME_PICKER}
        disableAlpha={false}
        onChangeColor={onChangeColor}
      />
    </div>
  )
}

export default BackgroundMenu
