import React from 'react'
import '../assets/services.sass'
import { LinearSVG } from '../utils/LinearSVG'
import { ColorIF } from '../interfaces/svg'
import { services } from '../utils/resources'
import SVGDefs from '../utils/SVGDefs'
import FrontendSVG from '../icons/FrontendSVG'
import BackendSVG from '../icons/BackendSVG'
import CloudSVG from '../icons/CloudSVG'
import MachineSVG from '../icons/MachineSVG'
import NumericalSVG from '../icons/NumericalSVG'

class Services extends React.Component<ColorIF, { colors: ColorIF[]; index: number }> {
  constructor(props: ColorIF) {
    super(props)
    this.state = {
      colors: services,
      index: -1
    }
  }
  componentDidUpdate(prevProps: Readonly<ColorIF>): void {
    if (prevProps.color1 !== this.props.color1 && this.state.index !== -1) {
      this.setState({
        colors: [
          ...this.state.colors.slice(0, this.state.index),
          {
            ...this.state.colors[this.state.index],
            color1: this.props.color1,
            color2: this.props.color2,
            background1: this.props.background1,
            background2: this.props.background2
          },
          ...this.state.colors.slice(this.state.index + 1)
        ]
      })
    }
  }
  handleHoverItem(index: number, onHover: boolean) {
    let thisColor = {
      ...services[index],
      color1: this.props.color1,
      color2: this.props.color2,
      background1: this.props.background1,
      background2: this.props.background2
    }
    if (!onHover) {
      this.setState({ colors: services, index: -1 })
      return
    }
    this.setState({
      colors: [...services.slice(0, index), thisColor, ...services.slice(index + 1)],
      index: index
    })
  }
  svgMapping(key: string) {
    const svgObject: { [key: string]: JSX.Element } = {
      frontend: <FrontendSVG id={key} />,
      backend: <BackendSVG id={key} />,
      cloud: <CloudSVG id={key} />,
      machine: <MachineSVG id={key} />,
      numerical: <NumericalSVG id={key} />
    }
    return svgObject[key]
  }
  render() {
    return (
      <div id="services">
        <div id="services-container">
          <div id="services-title-container">
            <h1>OFFERINGS TO MY CLIENTS</h1>
          </div>
          <div id="services-items-container">
            {services.map(
              (
                item: { title: string; key: string; viewBox: string; body: string },
                index: number
              ) => (
                <div
                  className="services-items"
                  key={index}
                  onMouseOver={() => this.handleHoverItem(index, true)}
                  onMouseLeave={() => this.handleHoverItem(index, false)}
                  style={{
                    backgroundImage: `-webkit-linear-gradient(0deg, ${this.state.colors[index].background1} 0%, ${this.state.colors[index].background2} 100%`
                  }}
                >
                  <div className="services-items-wrappers">
                    <div className="services-items-top">
                      <LinearSVG viewBox={item.viewBox}>
                        <SVGDefs
                          id={item.key}
                          color1={this.state.colors[index].color1}
                          color2={this.state.colors[index].color2}
                        />
                        {this.svgMapping(item.key)}
                      </LinearSVG>
                      <div className="services-items-title">
                        <div>{item.title.split(' ')[0]}</div>
                        <div>{item.title.split(' ')[1]}</div>
                      </div>
                    </div>
                    <div className="services-items-body">{item.body}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Services
