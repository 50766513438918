import React from 'react'
import '../assets/construction.sass'

class Construction extends React.Component<{}, {}> {
  render() {
    return (
      <div id="construction">
        <div id="construction-container">
          <div id="construction-text-container">
            <h1>Friendly Reminder</h1>
            <h4>Thank you for visiting my website.</h4>
            <h4>Many applications are unavailable as this site is still under construction.</h4>
            <h4>Please come back later. Thank you!</h4>
          </div>
        </div>
      </div>
    )
  }
}

export default Construction
