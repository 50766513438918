import React from 'react'
import '../assets/footer.sass'

class Footer extends React.Component<{}, {}> {
  thisYear = new Date().getFullYear()
  redirectURL(): void {
    window.location.href = 'https://www.havkonsoft.com'
  }
  render() {
    return (
      <div id="footer">
        <div id="footer-container">
          <div className="footer-text">Lewis Chen &copy; {this.thisYear}</div>
          <div className="footer-text" onClick={() => this.redirectURL()}>
            Havkonsoft
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
