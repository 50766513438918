import React from 'react'
import '../assets/experience.sass'
import { Collapse } from 'antd'
import { education, experiences } from '../utils/resources'
import { contentIF } from '../interfaces/landing'

class Experience extends React.Component<
  { effect: number; color1: string; color2: string },
  { num: number; content: contentIF[] }
> {
  constructor(props: { effect: number; color1: string; color2: string }) {
    super(props)
    this.state = {
      num: 1,
      content: experiences
    }
  }

  componentDidMount(): void {
    const btns = Array.from(
      document.getElementsByClassName('experience-btn') as HTMLCollectionOf<HTMLElement>
    )
    btns[0].style.backgroundColor = 'rgba(255, 255, 255)'
    btns[0].style.color = 'rgb(0, 0, 0)'
    this.updateColor(1)
  }

  switchConetnt(evt: React.MouseEvent<HTMLDivElement, MouseEvent>, num: number): void {
    const btns = Array.from(
      document.getElementsByClassName('experience-btn') as HTMLCollectionOf<HTMLElement>
    )
    btns.forEach((item: HTMLElement) => {
      item.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'
      item.style.color = 'rgb(255, 255, 255)'
    })
    const target = evt.target as HTMLElement
    target.style.backgroundColor = 'rgba(255, 255, 255)'
    target.style.color = 'rgb(0, 0, 0)'
    setTimeout(() => {
      const content = num === 1 ? experiences : education
      this.setState({ content: content, num: num })
      const exp = document.getElementById('experience')
      exp?.scrollIntoView({ behavior: 'smooth' })
    }, 200)
    if (this.state.num !== num) {
      setTimeout(() => {
        this.updateColor(0)
      }, 10)
      setTimeout(() => {
        this.updateColor(1)
      }, 400)
    }
  }

  updateColor(num: number): void {
    const dates = Array.from(
      document.getElementsByClassName('experience-item-date') as HTMLCollectionOf<HTMLElement>
    )
    const texts = Array.from(
      document.getElementsByClassName('ant-collapse-header-text') as HTMLCollectionOf<HTMLElement>
    )
    dates.forEach((item: HTMLElement) => {
      item.style.color = `rgba(255, 255, 255, ${num})`
    })
    texts.forEach((item: HTMLElement) => {
      item.style.color = `rgba(255, 255, 255, ${num})`
    })
  }

  render() {
    return (
      <div
        id="experience"
        style={{
          backgroundImage: `-webkit-linear-gradient(0deg, ${this.props.color1} 0%, ${this.props.color2} 100%`
        }}
      >
        <div id="experience-btn-container">
          <div className="experience-btn" onClick={(evt) => this.switchConetnt(evt, 1)}>
            My Experiences
          </div>
          <div className="experience-btn" onClick={(evt) => this.switchConetnt(evt, 2)}>
            My Education
          </div>
        </div>
        <div id="experience-container">
          <ul className="experience-list">
            {this.state.content.map((item: contentIF) => (
              <li key={item.title}>
                <span></span>
                <div className="experience-item-container">
                  <div className="experience-item">
                    <div className="experience-item-date">
                      <p>{item.date}</p>
                    </div>
                    <div className="experience-item-body">
                      <Collapse
                        accordion
                        ghost
                        items={[
                          {
                            key: '1',
                            label: item.title,
                            showArrow: false,
                            children: (
                              <ul style={{ color: 'white' }}>
                                {item.content.map((desc: string, index: number) => (
                                  <li key={index}>{desc}</li>
                                ))}
                              </ul>
                            )
                          }
                        ]}
                      ></Collapse>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default Experience
