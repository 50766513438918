import { GraphParametersIF } from '../../interface/doublePendulum'

const fx = (x: number, y: number, xp: number, yp: number, w: number) => {
  return (
    (-9 * xp ** 2 * Math.sin(x - y) * Math.cos(x - y) +
      9 * w ** 2 * Math.sin(y) * Math.cos(x - y) -
      6 * yp ** 2 * Math.sin(x - y) -
      18 * w ** 2 * Math.sin(x)) /
    (16 - 9 * Math.cos(x - y) ** 2)
  )
}
const fy = (x: number, y: number, xp: number, yp: number, w: number) => {
  return (
    (9 * yp ** 2 * Math.sin(x - y) * Math.cos(x - y) +
      27 * w ** 2 * Math.sin(x) * Math.cos(x - y) +
      24 * xp ** 2 * Math.sin(x - y) -
      24 * w ** 2 * Math.sin(y)) /
    (16 - 9 * Math.cos(x - y) ** 2)
  )
}

const sympleticAlgorithm = (
  h: number,
  N: number,
  x: number[],
  y: number[],
  xz: number[],
  yz: number[],
  w: number,
  l: number,
  run: boolean
) => {
  // Sympletic solver algorithm with prediction correction to conserve energy
  x.push(x[0] + xz[0] * h + 0.5 * fx(x[0], y[0], xz[0], yz[0], w) * h * h)
  y.push(y[0] + yz[0] * h + 0.5 * fy(x[0], y[0], xz[0], yz[0], w) * h * h)
  // Prediction for velocity
  xz.push(xz[0] + h * fx(x[1], y[1], xz[0], yz[0], w) * h * h)
  yz.push(yz[0] + h * fy(x[1], y[1], xz[0], yz[0], w) * h * h)

  if (run) {
    for (let n = 1; n < N; n++) {
      // Position functions
      x.push(x[n] + xz[n] * h + 0.5 * fx(x[n], y[n], xz[n], yz[n], w) * h * h)
      y.push(y[n] + yz[n] * h + 0.5 * fy(x[n], y[n], xz[n], yz[n], w) * h * h)
      // Prediction for velocity
      const xz_np1 = xz[n] + h * fx(x[n + 1], y[n + 1], xz[n], yz[n], w) * h * h
      const yz_np1 = yz[n] + h * fy(x[n + 1], y[n + 1], xz[n], yz[n], w) * h * h
      // Correction terms
      xz.push(
        xz[n] +
          0.5 *
            (fx(x[n + 1], y[n + 1], xz[n], yz[n], w) + fx(x[n + 1], y[n + 1], xz_np1, yz_np1, w)) *
            h
      )
      yz.push(
        yz[n] +
          0.5 *
            (fy(x[n + 1], y[n + 1], xz[n], yz[n], w) + fy(x[n + 1], y[n + 1], xz_np1, yz_np1, w)) *
            h
      )
    }
  }
  const r1 = x.map((item: number) => (3 * Math.PI) / 2 + item)
  const r2 = y.map((item: number) => (3 * Math.PI) / 2 + item)
  const x1 = x.map((item: number) => l * Math.sin(item))
  const y1 = x.map((item: number) => -l * Math.cos(item))
  const x2 = x.map((item: number, index: number) => l * (Math.sin(item) + Math.sin(y[index])))
  const y2 = x.map((item: number, index: number) => -l * (Math.cos(item) + Math.cos(y[index])))
  return { r1, r2, x1, y1, x2, y2 }
}

const conservedAlgorithm = (
  h: number,
  N: number,
  x: number[],
  y: number[],
  xz: number[],
  yz: number[],
  w: number,
  l: number,
  run: boolean
) => {
  // Sympletic solver algorithm with energy conservation: potential energy and
  // kinetic energy transfer to each other completely
  if (!run) N = 1
  for (let n = 0; n < N; n++) {
    // Position functions
    const x_n = x[n] + h * xz[n]
    const y_n = y[n] + h * yz[n]
    x.push(x_n)
    y.push(y_n)
    // Prediction for velocity
    xz.push(xz[n] + h * fx(x_n, y_n, xz[n], yz[n], w))
    yz.push(yz[n] + h * fy(x_n, y_n, xz[n], yz[n], w))
  }
  const r1 = x.map((item: number) => (3 * Math.PI) / 2 + item)
  const r2 = y.map((item: number) => (3 * Math.PI) / 2 + item)
  const x1 = x.map((item: number) => l * Math.sin(item))
  const y1 = x.map((item: number) => -l * Math.cos(item))
  const x2 = x.map((item: number, index: number) => l * (Math.sin(item) + Math.sin(y[index])))
  const y2 = x.map((item: number, index: number) => -l * (Math.cos(item) + Math.cos(y[index])))
  return { r1, r2, x1, y1, x2, y2 }
}

export const algorithms = (parameters: GraphParametersIF) => {
  const x0 = (Math.PI * parameters.theta1) / 180 // initial value of theta_1
  const y0 = (Math.PI * parameters.theta2) / 180 // initial value of theta_2
  const xp0 = parameters.omega1 / 100 // initial time derivative of theta_1
  const yp0 = parameters.omega2 / 100 // initial time derivative of theta_2
  const t = parameters.time // duration
  const g = parameters.gravity // gravitational field
  const l = parameters.length + parameters.size // length of the rods
  const h = parameters.speed / 100 // step size
  const run = parameters.run // run boolean

  const w = Math.sqrt(g / l)
  const N = t / h // Suggested grid size for algorithm convergence
  const x: number[] = [] // array of theta 1
  const y: number[] = [] // array of theta 2
  const xz: number[] = [] // array of angular speed 1
  const yz: number[] = [] // array of angular speed 2
  x.push(x0)
  y.push(y0)
  xz.push(xp0)
  yz.push(yp0)

  const result =
    parameters.algorithm === 'pseudo-realistic'
      ? sympleticAlgorithm(h, N, x, y, xz, yz, w, l, run)
      : conservedAlgorithm(h, N, x, y, xz, yz, w, l, run)
  const r1 = result.r1
  const r2 = result.r2
  const x1 = result.x1
  const y1 = result.y1
  const x2 = result.x2
  const y2 = result.y2
  return { r1, r2, x1, y1, x2, y2 }
}

// const onSetDPBlobImage = (
//   key: string,
//   image: any,
//   color: string,
//   blobTexture: TextureIF,
//   setBlobTexture1: React.Dispatch<React.SetStateAction<TextureIF>>,
//   setBlobTexture2: React.Dispatch<React.SetStateAction<TextureIF>>,
//   saveBlobTexture1: boolean
// ) => {
//   interface textureAttributes {
//     '0': string[]
//     '1': string[]
//     '2': string[]
//     '3': string[]
//     '4': string[]
//   }
//   const textureAttrObj = {
//     '0': ['back_image', 'back_color'],
//     '1': ['rod1_image', 'rod1_color'],
//     '2': ['orb1_image', 'orb1_color'],
//     '3': ['rod2_image', 'rod2_color'],
//     '4': ['orb2_image', 'orb2_color']
//   }
//   const thisImage = textureAttrObj[key as keyof textureAttributes][0]
//   const thisColor = textureAttrObj[key as keyof textureAttributes][1]
//   const newBlobTexture = { ...blobTexture, [thisImage]: image, [thisColor]: color }
//   setBlobTexture2(newBlobTexture)
//   if (saveBlobTexture1) setBlobTexture1(newBlobTexture)
//   return newBlobTexture
// }

// export const loadDPImageUrl = async (
//   token: string,
//   parameters: GraphParametersIF,
//   onLoadDPImage: any,
//   aspectRatio: number,
//   blobTexture: TextureIF,
//   setBlobTexture1: React.Dispatch<React.SetStateAction<TextureIF>>,
//   setBlobTexture2: React.Dispatch<React.SetStateAction<TextureIF>>
// ) => {
//   const attributeObj = [
//     { type: parameters.back_type, text: parameters.back_texture, tKey: 'back_texture' },
//     { type: parameters.rod1_type, text: parameters.rod1_texture, tKey: 'rod1_texture' },
//     { type: parameters.orb1_type, text: parameters.orb1_texture, tKey: 'orb1_texture' },
//     { type: parameters.rod2_type, text: parameters.rod2_texture, tKey: 'rod2_texture' },
//     { type: parameters.orb2_type, text: parameters.orb2_texture, tKey: 'orb2_texture' }
//   ]
//   let thisBlob: TextureIF = blobTexture
//   for (const index in attributeObj) {
//     const type = attributeObj[index].type
//     const text = attributeObj[index].text
//     const tKey = attributeObj[index].tKey
//     if (type === 1) {
//       const response = await onLoadDPImage(token, text, aspectRatio, tKey, false)
//       if (response.status === 200) {
//         const data = new Blob([response.data])
//         thisBlob = onSetDPBlobImage(
//           index,
//           window.URL.createObjectURL(data),
//           '',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           true
//         )
//       } else {
//         thisBlob = onSetDPBlobImage(
//           index,
//           '',
//           '#000000',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           true
//         )
//       }
//     } else {
//       thisBlob = onSetDPBlobImage(index, '', text, thisBlob, setBlobTexture1, setBlobTexture2, true)
//     }
//   }
// }

// export const previewDPTextures = async (
//   token: string,
//   clickType: string,
//   clickIndex: string,
//   clickImage: any,
//   aspectRatio: number,
//   attributes2: GraphParametersIF,
//   onLoadDPImage: any,
//   blobTexture2: TextureIF,
//   setBlobTexture1: React.Dispatch<React.SetStateAction<TextureIF>>,
//   setBlobTexture2: React.Dispatch<React.SetStateAction<TextureIF>>,
//   setAttributes2: React.Dispatch<React.SetStateAction<GraphParametersIF>>
// ) => {
//   interface textureAttributes {
//     '0': string[]
//     '1': string[]
//     '2': string[]
//     '3': string[]
//     '4': string[]
//   }
//   const textureAttrObj = {
//     '0': ['back_type', 'back_texture'],
//     '1': ['rod1_type', 'rod1_texture'],
//     '2': ['orb1_type', 'orb1_texture'],
//     '3': ['rod2_type', 'rod2_texture'],
//     '4': ['orb2_type', 'orb2_texture']
//   }
//   const thisBlob: TextureIF = blobTexture2
//   const thisType: string = textureAttrObj[clickIndex.toString() as keyof textureAttributes][0]
//   const thisText: string = textureAttrObj[clickIndex.toString() as keyof textureAttributes][1]
//   let response: any
//   switch (clickType) {
//     case 'Default':
//       setAttributes2({ ...attributes2, run: false, [thisType]: 0 })
//       break
//     case 'Select':
//       setAttributes2({ ...attributes2, run: false, [thisType]: 1, [thisText]: clickImage })
//       response = await onLoadDPImage(token, clickImage, aspectRatio, thisText, false)
//       if (response.status === 200) {
//         const data = new Blob([response.data])
//         onSetDPBlobImage(
//           clickIndex,
//           window.URL.createObjectURL(data),
//           '',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           false
//         )
//       } else {
//         onSetDPBlobImage(
//           clickIndex,
//           '',
//           '#000000',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           false
//         )
//       }
//       break
//     case 'Url':
//       setAttributes2({ ...attributes2, run: false, [thisType]: 1, [thisText]: clickImage })
//       response = await onLoadDPImage(token, clickImage, aspectRatio, thisText, false)
//       if (response.status === 200) {
//         const data = new Blob([response.data])
//         onSetDPBlobImage(
//           clickIndex,
//           window.URL.createObjectURL(data),
//           '',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           false
//         )
//       } else {
//         onSetDPBlobImage(
//           clickIndex,
//           '',
//           '#000000',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           false
//         )
//       }
//       break
//     case 'Upload':
//       setAttributes2({ ...attributes2, run: false, [thisType]: 1, [thisText]: 'some' })
//       response = await onLoadDPImage(token, clickImage[1], aspectRatio, thisText, true)
//       if (response.status === 200) {
//         const data = new Blob([response.data])
//         onSetDPBlobImage(
//           clickIndex,
//           window.URL.createObjectURL(data),
//           '',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           false
//         )
//       } else {
//         onSetDPBlobImage(
//           clickIndex,
//           '',
//           '#000000',
//           thisBlob,
//           setBlobTexture1,
//           setBlobTexture2,
//           false
//         )
//       }
//       break
//     case 'Picker':
//       setAttributes2({ ...attributes2, run: false, [thisType]: 2, [thisText]: clickImage })
//       onSetDPBlobImage(
//         clickIndex,
//         '',
//         clickImage,
//         thisBlob,
//         setBlobTexture1,
//         setBlobTexture2,
//         false
//       )
//       break
//     default:
//       break
//   }
// }
