import React from 'react'
import '../assets/loschmidt-echo.sass'
import type { CollapseProps } from 'antd'
import { Collapse, Drawer, theme } from 'antd'
import CommonContainer from '../../common/components/CommonContainer'
import CommonButtonTop from '../../common/components/CommonButtonTop'
import CommonReferences from '../../common/components/CommonReferences'
import CommonFunctionGraph from '../../common/components/CommonFunctionGraph'
import { BackgroundIF, DataSetIF, MinMaxIF } from '../../interface/common'
import BackgroundMenu from '../components/BackgroundMenu'
import LoschmidtEcho from '../components/LoschmidtEcho'
import TheSSHModel from '../components/TheSSHModel'
import TheXXZModel from '../components/TheXXZModel'
import DisorderedSystems from '../components/DisorderedSystems'
import Summary from '../components/Summary'
import { initialBackground } from '../../resources/common'
import {
  initialDataSetLESSH,
  initialDataSetRRSSH,
  initialGraphData,
  initialParametersLE,
  initialParametersRR,
  initialSSHParameters,
  initialXXZParameters,
  loschmidtEchoLabel,
  references
} from '../../resources/loschmidtEcho'
import SolutionMenu from '../components/SolutionMenu'
import ParameterForm from '../components/ParameterForm'
import { FormParametersIF } from '../../interface/loschmidtEcho'
import { expandPanel } from '../components/functions'
import { SystemModel } from '../../enum'

const LoschmidtEchoContainer = () => {
  const { token } = theme.useToken()
  const [open, setOpen] = React.useState<boolean>(false)

  const commonStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '1rem',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG
  }
  const panelStyle = { ...commonStyle, border: 'none' }
  const panelStyleBottom: React.CSSProperties = {
    ...commonStyle,
    borderBottom: '1px solid #d9d9d9'
  }

  const [disableBtn, setDisableBtn] = React.useState<boolean>(false)
  const [datasetsLE, setDatasetsLE] = React.useState<DataSetIF[]>([initialDataSetLESSH])
  const [datasetsRR, setDatasetsRR] = React.useState<DataSetIF[]>([initialDataSetRRSSH])
  const [background, setBackground] = React.useState<BackgroundIF>(initialBackground)
  const [parameters, setParameters] = React.useState<FormParametersIF[]>(initialSSHParameters)
  const [systemModel, setSystemModel] = React.useState<SystemModel>(SystemModel.SSH)

  const updateCollapsedItems = (parameters: FormParametersIF[]) => {
    const newCollapseItems = parameters.map((item: FormParametersIF, index: number) => ({
      key: (index + 1).toString(),
      label: 'Parameter Set ' + (index + 1),
      showArrow: false,
      className: 'loschmidt-echo-parameter-set',
      style: index === parameters.length - 1 ? panelStyleBottom : panelStyle,
      children: (
        <ParameterForm
          index={index}
          disableBtn={disableBtn}
          parameters={parameters}
          systemModel={systemModel}
          initialParameters={item}
          datasetsLE={datasetsLE}
          datasetsRR={datasetsRR}
          setDatasetsLE={setDatasetsLE}
          setDatasetsRR={setDatasetsRR}
          setDisableBtn={setDisableBtn}
          setParameters={setParameters}
        />
      )
    }))
    const mapCollapseItems = newCollapseItems!.map((item, i: number) => ({
      ...item,
      key: (i + 1).toString(),
      label: 'Parameter Set ' + (i + 1)
    })) as CollapseProps['items']
    setCollapseItems(mapCollapseItems)
  }

  React.useEffect(() => {
    updateCollapsedItems(parameters)
    // eslint-disable-next-line
  }, [parameters, systemModel, datasetsLE])

  const [collapseItems, setCollapseItems] = React.useState<CollapseProps['items']>(
    initialSSHParameters.map((item: FormParametersIF, index: number) => ({
      key: (index + 1).toString(),
      label: 'Parameter Set ' + (index + 1),
      showArrow: false,
      className: 'loschmidt-echo-parameter-set',
      style: index === initialSSHParameters.length - 1 ? panelStyleBottom : panelStyle,
      children: (
        <ParameterForm
          index={index}
          disableBtn={disableBtn}
          parameters={initialSSHParameters}
          systemModel={systemModel}
          initialParameters={item}
          datasetsLE={datasetsLE}
          datasetsRR={datasetsRR}
          setDatasetsLE={setDatasetsLE}
          setDatasetsRR={setDatasetsRR}
          setDisableBtn={setDisableBtn}
          setParameters={setParameters}
        />
      )
    }))
  )

  const handleAddParameterSet = () => {
    setDisableBtn(true)
    const total = collapseItems!.length
    const newCollapseItems = collapseItems!
    const newParameters = parameters
    const [initialParameters] =
      systemModel === SystemModel.SSH ? initialSSHParameters : initialXXZParameters //getInitialParameters(systemParams.model, systemParams.type)
    newCollapseItems.push({
      key: (total + 1).toString(),
      label: 'Parameter Set ' + (total + 1),
      showArrow: false,
      className: 'loschmidt-echo-parameter-set',
      style: panelStyleBottom,
      children: (
        <ParameterForm
          index={total}
          disableBtn={disableBtn}
          parameters={parameters}
          systemModel={systemModel}
          initialParameters={initialParameters}
          datasetsLE={datasetsLE}
          datasetsRR={datasetsRR}
          setDatasetsLE={setDatasetsLE}
          setDatasetsRR={setDatasetsRR}
          setDisableBtn={setDisableBtn}
          setParameters={setParameters}
        />
      )
    })
    const newDatasetsLE: DataSetIF[] = datasetsLE
    newDatasetsLE.push({ ...initialDataSetLESSH, label: `data ${total + 1}` })
    const newDatasetsRR: DataSetIF[] = datasetsRR
    newDatasetsRR.push({ ...initialDataSetRRSSH, label: `data ${total + 1}` })
    setDatasetsLE(newDatasetsLE)
    setDatasetsRR(newDatasetsRR)
    newParameters.push(initialParameters)
    setCollapseItems(newCollapseItems)
    setParameters(newParameters)
    expandPanel(setDisableBtn)
  }

  const getItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Loschmidt Echo',
      showArrow: false,
      style: panelStyle,
      children: <LoschmidtEcho />
    },
    {
      key: '2',
      label: 'The Su-Shrieffer-Heeger Model',
      showArrow: false,
      style: panelStyle,
      children: <TheSSHModel />
    },
    {
      key: '3',
      label: 'The XXZ Heisenberg Model',
      showArrow: false,
      style: panelStyle,
      children: <TheXXZModel />
    },
    {
      key: '4',
      label: 'Disordered Systems',
      showArrow: false,
      style: panelStyle,
      children: <DisorderedSystems />
    },
    {
      key: '5',
      label: 'Summary',
      showArrow: false,
      style: panelStyle,
      children: <Summary />
    },
    {
      key: '6',
      label: 'Numerical Solutions',
      showArrow: false,
      style: panelStyle,
      children: (
        <SolutionMenu
          collapseItems={collapseItems}
          disableBtn={disableBtn}
          parameters={parameters}
          systemModel={systemModel}
          datasetsLE={datasetsLE}
          datasetsRR={datasetsRR}
          setDatasetsLE={setDatasetsLE}
          setDatasetsRR={setDatasetsRR}
          setDisableBtn={setDisableBtn}
          setParameters={setParameters}
          setSystemModel={setSystemModel}
          handleAddParameterSet={handleAddParameterSet}
          updateCollapsedItems={updateCollapsedItems}
        />
      )
    },
    {
      key: '7',
      label: 'Background Selection',
      showArrow: false,
      style: panelStyleBottom,
      children: <BackgroundMenu background={background} setBackground={setBackground} />
    }
  ]

  const yMinMax: MinMaxIF = { min: 0, max: 1 }
  const backgroundStyle: React.CSSProperties =
    background.type === 3 ? { background: background.color } : { backgroundImage: background.image }

  const scrollTop = () => {
    const element = document.getElementById('common-application-menu-title')
    element!.scrollIntoView({ behavior: 'smooth' })
  }

  const menuElement = (
    <div>
      <div id="common-application-menu-title">
        <h2>Loschmidt Echo in Disordered Systems</h2>
      </div>
      <Collapse accordion defaultActiveKey={'6'} items={getItems} onChange={scrollTop} />
      <CommonReferences references={references} tagPrefix={'loschmidt-echo-reference'} />
      <CommonButtonTop />
    </div>
  )

  const componentElement = (
    <div id="loschmidt-echo-graphs-container">
      <CommonFunctionGraph
        labels={loschmidtEchoLabel}
        datasets={datasetsLE}
        parameters={initialParametersLE}
        graphOptions={initialGraphData}
        yMinMax={yMinMax}
        showTitle={false}
        customStyle={{ height: '40%' }}
      />
      <CommonFunctionGraph
        labels={loschmidtEchoLabel}
        datasets={datasetsRR}
        parameters={initialParametersRR}
        graphOptions={initialGraphData}
        yMinMax={yMinMax}
        showTitle={false}
        customStyle={{ height: '40%' }}
      />
    </div>
  )

  const onClose = () => {
    setOpen(false)
  }

  const drawerElement = (
    <Drawer
      title="Web Applications"
      placement="left"
      closable={true}
      onClose={onClose}
      open={open}
      key="left-drawer"
      width={window.innerWidth}
    >
      {menuElement}
    </Drawer>
  )

  return (
    <CommonContainer
      initialWidth={650}
      setOpen={setOpen}
      backgroundStyle={backgroundStyle}
      menuElement={menuElement}
      componentElement={componentElement}
      drawerElement={drawerElement}
    />
  )
}

export default LoschmidtEchoContainer
