import { FormParametersIF, GraphParametersIF, TextureIF } from '../interface/doublePendulum'

export const initialParameters: GraphParametersIF = {
  algorithm: 'pseudo-realistic',
  length: 5,
  size: 1,
  gravity: 5,
  speed: 5,
  theta1: 135,
  theta2: 90,
  omega1: 0,
  omega2: 0,
  time: 100,
  run: false
}

export const initialTextures: TextureIF = {
  rod1_type: 0,
  rod1_image: '',
  rod1_color: 'rgb(255, 0, 0)',
  rod1_texture: 'rgb(255, 0, 0)',
  orb1_type: 0,
  orb1_image: '',
  orb1_color: 'rgb(255, 0, 0)',
  orb1_texture: 'rgb(255, 0, 0)',
  rod2_type: 0,
  rod2_image: '',
  rod2_color: 'rgb(255, 0, 0)',
  rod2_texture: 'rgb(255, 0, 0)',
  orb2_type: 0,
  orb2_image: '',
  orb2_color: 'rgb(255, 0, 0)',
  orb2_texture: 'rgb(255, 0, 0)'
}

export const formParameters: FormParametersIF[] = [
  {
    label: 'Rod Length',
    type: 'length',
    min: 0,
    max: 100
  },
  {
    label: 'Orb Size',
    type: 'size',
    min: 0,
    max: 100
  },
  {
    label: 'Gravity',
    type: 'gravity',
    min: 0,
    max: 50
  },
  {
    label: 'Angle 1',
    type: 'theta1',
    min: 0,
    max: 360
  },
  {
    label: 'Angle 2',
    type: 'theta2',
    min: 0,
    max: 360
  },
  {
    label: 'Angular Speed 1',
    type: 'omega1',
    min: 0,
    max: 100
  },
  {
    label: 'Angular Speed 2',
    type: 'omega2',
    min: 0,
    max: 100
  },
  {
    label: 'Duration',
    type: 'time',
    min: 0,
    max: 300
  },
  {
    label: 'Animation Speed',
    type: 'speed',
    min: 1,
    max: 20
  }
]
