import React from 'react'
import '../assets/function-animator.sass'
import { Checkbox, Form, Radio, Slider } from 'antd'
import { addStyles } from 'react-mathquill'
import { AnimationIF } from '../../interface/functionAnimator'

addStyles()

const AnimationMenu: React.FC<{
  animation: AnimationIF
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAnimation: React.Dispatch<React.SetStateAction<AnimationIF>>
}> = ({ animation, setOpen, setAnimation }) => {
  const onChangeParameters = (value: any, type: string) => {
    setAnimation({ ...animation, [type]: value })
  }

  const onFinish = () => {
    setOpen(false)
  }

  return (
    <>
      <Form initialValues={animation} onFinish={onFinish} layout="vertical">
        <Form.Item label={'Plot Style'} name={'style'}>
          <Radio.Group
            onChange={(e) => onChangeParameters(e.target.value, 'style')}
            value={animation.style}
          >
            <Radio value={'line'}>Line</Radio>
            <Radio value={'bar'}>Bar</Radio>
            <Radio value={'bubble'}>Bubble</Radio>
            <Radio value={'scatter'}>Scatter</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={'Point Style'} name={'point'}>
          <Radio.Group
            onChange={(e) => onChangeParameters(e.target.value, 'point')}
            value={animation.point}
          >
            <Radio value={'circle'}>Circle</Radio>
            <Radio value={'cross'}>Cross</Radio>
            <Radio value={'dash'}>Dash</Radio>
            <Radio value={'line'}>Line</Radio>
            <Radio value={'rect'}>Rectangle</Radio>
            <Radio value={'rectRounded'}>Rounded</Radio>
            <Radio value={'star'}>Star</Radio>
            <Radio value={'triangle'}>Triangle</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={'Point Size'} name={'size'}>
          <Slider
            min={1}
            max={50}
            onChange={(value: number) => onChangeParameters(value, 'size')}
          />
        </Form.Item>
        <Form.Item label={'Draw Speed'} name={'speed'}>
          <Slider
            min={1}
            max={20}
            onChange={(value: number) => onChangeParameters(value, 'speed')}
          />
        </Form.Item>
        <Form.Item label={'Spin Speed'} name={'spin'}>
          <Slider
            min={1}
            max={20}
            onChange={(value: number) => onChangeParameters(value, 'spin')}
          />
        </Form.Item>
        <Form.Item
          label={'Spin'}
          name={'rotate'}
          valuePropName={'checked'}
          className="function-animation-menu-form-spin"
        >
          <Checkbox onChange={(e) => onChangeParameters(e.target.checked, 'rotate')} />
        </Form.Item>
      </Form>
    </>
  )
}

export default AnimationMenu
