const CloudSVG = (props: { id: string }) => {
  return (
    <g fill={`url(#${props.id})`}>
      <path d="M40,28H24a1,1,0,0,0-1,1V41a1,1,0,0,0,1,1H40a1,1,0,0,0,1-1V29A1,1,0,0,0,40,28ZM25,30h2v2H25ZM35,41H26a1,1,0,0,1-1-1V34h2v5h8Zm4,0H37V39h2Zm0-4H37V32H29V30h9a1,1,0,0,1,1,1Z" />
      <path d="M45,44V26a2,2,0,0,0-2-2H21a2,2,0,0,0-2,2V44a2,2,0,0,0,2,2H43A2,2,0,0,0,45,44Zm-5,0H24a3,3,0,0,1-3-3V29a3,3,0,0,1,3-3H40a3,3,0,0,1,3,3V41A3,3,0,0,1,40,44Z" />
      <path d="M62,57H35.858A4,4,0,0,0,33,54.142V47H31v7.142A4,4,0,0,0,28.142,57H2v2H28.142a3.981,3.981,0,0,0,7.716,0H62ZM32,60a2,2,0,1,1,2-2A2,2,0,0,1,32,60Z" />
      <rect height="4" width="2" x="21" y="47" />
      <rect height="4" width="2" x="25" y="47" />
      <rect height="4" width="2" x="37" y="47" />
      <rect height="4" width="2" x="41" y="47" />
      <path d="M9,31h5v1h4V30H17V28h1V26H17a4,4,0,0,1,4-4V19h2v3h2V19h2v3h2V19h2v3h2V19h2v3h2V19h2v3h2V19h2v3a4,4,0,0,1,4,4H46v2h1v2H46v2h4V31a10,10,0,0,0,0-20h-.61a1,1,0,0,1-.943-.667,9.486,9.486,0,0,0-17.573-.794,1,1,0,0,1-1.619.285A9.5,9.5,0,0,0,13,16.5V20a1,1,0,0,1-1,1H9A5,5,0,0,0,9,31Zm47-7H54V22h2ZM50,14a6.006,6.006,0,0,1,6,6H54a4,4,0,0,0-4-4Z" />
      <rect height="2" width="4" x="46" y="34" />
      <rect height="2" width="4" x="46" y="38" />
      <rect height="2" width="4" x="46" y="42" />
      <rect height="2" width="4" x="14" y="34" />
      <rect height="2" width="4" x="14" y="38" />
      <rect height="2" width="4" x="14" y="42" />
    </g>
  )
}

export default CloudSVG
