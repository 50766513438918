import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { goToReference } from './functions'
import { references } from '../../resources/loschmidtEcho'
import { mathJaxConfig } from '../../resources/loschmidtEcho'

const DisorderedSystems = () => {
  return (
    <MathJaxContext version={3} config={mathJaxConfig}>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`A closed quantum system is a quantum statistical system that is isolated from external 
          reservoirs so that there is no energy fluctuation or particle exchange. If a closed 
          system reacts to an arbitrary local perturbations, under the unitary time evolution, 
          the state of an ergodic system leads to thermalization, and the system becomes thermal 
          and conducting. On the other hand, ergodicity can be simultaneously broken in localized 
          systems due to strong disorder potential, and the system becomes insulating. Localized 
          systems are of interest for possibilities of storing quantum information for they can 
          retain local details of the initial state, whereas thermalization hides information 
          about the system's initial state, making it inaccessible after long time`}
        {goToReference(references.slice(15, 16), '.')}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`Quantum thermalization is the process of a closed quantum system going into thermal 
          equilibrium after an arbitrary long time. The problem associated with thermalization 
          is that this process erases the system's memory and all other details about its initial 
          state. There are two immediate consequences for a closed system that thermalizes: the 
          eigenstate thermalization hypothesis (ETH) and the entanglement entropy. Consider an 
          eigenstate $|\\Psi_\\alpha\\rangle$ of the Hamiltonian $H$ with energy 
          $E_\\alpha=\\langle H\\rangle_{T_\\alpha}$ at thermal equilibrium. The ETH states that 
          the expectation value 
          $\\langle\\Psi_\\alpha|\\hat{O}|\\Psi_\\beta\\rangle$ of a local operator $\\hat{O}$, 
          in an eigenstate $|\\Psi_\\alpha\\rangle$ of the Hamiltonian $H$ with energy $E_\\alpha$, 
          of a large interacting many-body system equals the thermal average 
          $\\langle O\\rangle(E_\\alpha)$`}
        {goToReference(references.slice(16, 19), ':')}
        {`$$\\langle\\Psi_\\alpha|\\hat{O}|\\Psi_\\beta\\rangle=\\langle O\\rangle(E_\\alpha).$$`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`For the closed system thought to be in its pure state $\\rho=|\\Psi\\rangle\\langle\\Psi|$, 
          the von-Neumann entropy is defined as $S(\\rho)=-\\text{Tr}(\\rho\\ln{\\rho})$. The 
          entanglement entropy for the subsystem $A$ is the von-Neumann entropy of the reduced 
          states, given by 
          $$S(\\rho_{A})=-\\text{Tr}(\\rho_A\\ln{\\rho_A}),$$
          where $\\rho_A=\\text{Tr}_B\\{|\\Psi\\rangle\\langle\\Psi|\\}$ is the reduced density 
          matrix of $\\rho$ on subsystem $A$.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`The ETH has two strong implications. First, it asserts that in the thermodynamic 
          limit the subsystem $A$ is at thermal equilibrium: 
          $\\rho_A^{(\\alpha)}=\\rho_A^{(eq)}(T_\\alpha)$. Secondly, because the expectation 
          value of a local operator in eigenstates looks thermal, it implies that every eigenstate 
          implicitly contains a thermal state. That is, if an initial state is an eigenstate, then 
          it is already thermalized, so there is no time evolution of the system: 
          $\\rho(t)=\\rho(0)$. Regardless the initial state is an eigenstate or not, however, if 
          the system is guaranteed to thermalize for every such initial state, then ETH asserts 
          that the initial state must be an eigenstate. Therefore, if a closed system thermalizes, 
          the memory of initial states are hidden in global operators at long times, the ETH is 
          true in both the thermodynamic limit and for small regime in finite systems, and the 
          entanglement entropy is maximized. On the other hand, as we will discuss in the 
          following, for localized systems, some memory of local initial conditions are preserved 
          in local observables at long times, the ETH fails, and DC conductivity is absent.
          Figure 4.1 shows that the EE is indeed linear increasing with respect to the system 
          length $l$ in the regime $vt<l$, where $v$ is the velocity of the excitations.`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/disordered_system_1.png'}
          alt="etangle-entropy-xx"
        />
        <MathJax className="align-left">
          {`Figure 4.1: Entanglement entropy for the open XX chain for different system size $N$ 
            with box potential weak disorder $W = 0.1$`}
          {goToReference(references.slice(19, 20), '.')}
        </MathJax>
      </div>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`In the original tight-binding model formulated by Anderson, electrons are able to tunnel 
          between neighbouring lattice sites and the system is subject to an external random 
          potential modeling the random environment. Consider a three-dimensional periodic lattice 
          of potential wells, the time-dependence of the probability amplitude $\\psi_j$ that a 
          particle is on site $j$ is described by the Schrodinger equation
          \\[i\\hbar\\dot{\\psi}_j=H_j\\psi_j\\]
          where the Hamiltonian $H_j$ is given by \\[H_j=E_j+\\sum_{j\\neq k}V_{jk},\\]
          where $E_j$ is an independent random potential uniformly distributed in a disorder 
          potential box $[-W, W]$, and $V_{jk}$ the overlap (interaction) matrix element that 
          allows electrons hopping from one site to the next. In the second quantization 
          representation, the Hamiltonian $H$ is described by the operator $\\hat{H}$ for a 
          tight-binding model with nearest-neighbor hopping and random on-site potential
          $$\\hat{H}=J\\sum_{j,k}(\\hat{c}^\\dagger_j\\hat{c}_k+h.c)+\\sum_j\\mu_j\\hat{c}^\\dagger_j\\hat{c}_j,$$
          where $\\mu_j$ is a static random onsite potential drawn from $[-W,W]$ with $W$ 
          measuring the disorder strength, $J\\neq0$ is the nearest-neighbour hopping amplitude, 
          and $c^\\dagger_j~(c_j)$ creates (annihilates) a particle on site $j$. Starting with 
          $\\psi_0$ localised at the site $n$ at initial time $t=0$, Anderson was interested 
          in how fast the probability distribution $|\\psi |^{2}$ diffuses by considering the 
          strength of $W$. He found that for three-dimensional lattice systems, "if 
          $V(\\mathbf{r}_{jk})$ falls off at large distances faster than $1/\\mathbf{r}^3$, 
          and if the average value of $V$ is less than a certain critical $V_c$ of the order 
          of magnitude of $W$; then there is actually no transport at all, in the sense that 
          even as $t\\rightarrow\\infty$ the amplitude of the wave function around site $n$ 
          falls off rapidly with distance, the amplitude on site $n$ itself remaining finite". 
          This is the original theorem of the `}
        <b>Anderson localization</b>
        {goToReference(references.slice(20, 21), '.')}
        {`Figure 4.2 shows both the extended and localized states of hopping electrons.`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/disordered_system_2.png'}
          alt="anderson-localization"
        />
        <MathJax className="align-left">
          {`Figure 4.2: Hopping electrons or scattered waves. Top: Extended wavefunction of 
            electrons due to quantum tunnelling. Bottom: Random on-site disorder potential 
            results in localization of wavefunction which decays exponentially in space 
            (the horizontal axis)`}
          {goToReference(references.slice(21, 22), '.')}
        </MathJax>
      </div>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`Many-body localization (MBL) is the generalization of Anderson localization to 
          disordered interacting many-particle systems. The physics of the MBL phenomenon is 
          most simply exposed in the context of spin models. A spin-1/2 model of MBL arises 
          in a system governed by the spin Hamiltonian
          $$H=\\sum_{ij}J_{ij}\\pmb{\\sigma}_i\\cdot\\pmb{\\sigma}_j+\\sum_i\\mu_i\\sigma^z_i$$
          The on-site disorder potential $\\mu_i$ are static random variable drawn from a 
          continuous probability distribution of width $W>0$, and the spin hoppings and 
          interactions $J_{ij}$ are short range in real space. At $J=0$ the system is fully 
          localized. For none-zero $J$, in the regime $J<<W$, the many-body eigenstates of the
          MBL equation on different sites are weakly characterized by $J$ but subject 
          to strong disorder $W$. Hence, for strong disorder, DC spin transport and energy 
          transport are absent, quantum thermalization therefore does not occur, and all of the 
          eigenstates violate the ETH`}
        {goToReference(references.slice(22, 23), '.')}
        {`This is the `}
        <b>MBL localied phase</b>
        {`. For weak disorder, the 
          high-energy entropy eigenstates of the MBL equation do obey the ETH, and the 
          eigenstates are at the thermal phase. There is a quantum phase transition between the 
          thermal phase and the MBL localized phase by varying the disorder strength. Table 4.1 
          summarizes the properties of the thermal phase, the single-particle localized phase, 
          and the many-body localized phase.`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="table-image"
          src={'https://assets.havkonsoft.com/projects/disordered_system_3.png'}
          alt="table-summary"
        />
        <MathJax className="align-left">
          {`Table 4.1: A list of properties for the thermal phase, single-particle localized
            phase, and the MBL phase`}
          {goToReference(references.slice(15, 16), '.')}
        </MathJax>
      </div>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`To study dynamical phase transitions for disordered systems, we need to evaluate the 
          mean value of the Loschmidt echo averaged from many calculations with different 
          disordered configurations, called the disorder-averaged Loschmidt echo. Each 
          configuration corresponds to a set of eigenvalues and eigenvectors obtained by exact 
          diagonalization for a disordered system. To realize a disordered system, we first define 
          the on-site disorder potential according to the Anderson localization Hamiltonian,
          $$w_j=\\sum^L_{j=1}\\mu_j\\hat{c}_{j}\\hat{c}_{j+1}=\\sum^L_{j=1}\\mu_j\\hat{n}_j,$$
          where $\\mu_j\\in[-W,W]$ is the box disorder potential, 
          $\\hat{n}_j=\\hat{c}^\\dagger_j\\hat{c}_{j+1}$ is the occupation number operator on site 
          $j$, and the sum is over the number of lattice sits $L=2N$. Each value of $\\mu_j$ for 
          a given $j$ is randomly generated and independently distinguished from any other values 
          $\\mu_{j+1}$ for $j+1$. The SSH Hamiltonian with disorder potential then takes the form
          $$\\hat{H}_{SSH_\\triangle}=J\\sum^L_{j=1}[(1+\\delta e^{i\\pi j})\\hat{c}^\\dagger_j\\hat{c}_{j+1} + h.c.]+\\sum^L_{j=1}\\mu_j\\hat{n}_j+V,$$
          where $V$ is the nearest-neighbor interaction for free fermions derived by the fermionic
          interacting XXZ model can be expressed as
          $$V = \\triangle\\sum^L_{j=1}\\left(\\hat{n}_j\\hat{n}_{j+1}-\\frac{1}{4}\\right).$$
          The disordered XX model with has the form
          $$\\hat{H}_{XX}=\\frac{J}{2}\\sum^L_{j=1}(\\hat{c}^\\dagger_j\\hat{c}_{j+1}+\\hat{c}^\\dagger_{j+1}\\hat{c}_j)+\\sum^L_{j=1}\\mu_j\\hat{n}_j.$$
          The full XXZ model with disorder potential then has the following form
          $$\\hat{H}_{XXZ}=\\frac{J}{2}\\sum^L_{j=1}(\\hat{c}^\\dagger_j\\hat{c}_{j+1}+\\hat{c}^\\dagger_{j+1}\\hat{c}_j)+\\sum^L_{j=1}\\mu_j\\hat{n}_j + V.$$
          The SSH and Heisenberge models with disordered potentials will be used to evaluate the 
          Loschmidt echo and study the dynamical phase transition in this research.`}
      </MathJax>
    </MathJaxContext>
  )
}

export default DisorderedSystems
