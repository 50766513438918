import React from 'react'
import '../assets/common-components.sass'
import { DataSetIF, GraphOptionsIF, MinMaxIF, GraphParametersIF } from '../../interface/common'
import { GraphDataIF } from '../../interface/common'
import { StaticMathField } from 'react-mathquill'
import { Line } from 'react-chartjs-2'

const CommonFunctionGraph: React.FC<{
  labels: number[]
  datasets: DataSetIF[]
  parameters: GraphParametersIF
  graphOptions: GraphOptionsIF
  yMinMax: MinMaxIF
  showTitle: boolean
  customStyle?: React.CSSProperties
}> = ({ labels, datasets, parameters, graphOptions, yMinMax, showTitle, customStyle }) => {
  const data: GraphDataIF = {
    labels: labels,
    datasets: datasets.map((item: DataSetIF) => ({
      ...item,
      data: item.data
    }))
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          color: 'rgb(0, 0, 0)'
        }
      },
      title: {
        display: false,
        text: parameters.title,
        color: graphOptions.title
      }
    },
    scales: {
      y: {
        suggestedMin: yMinMax.min,
        suggestedMax: yMinMax.max,
        grid: {
          color: graphOptions.grid
        },
        ticks: {
          color: graphOptions.axes
        },
        title: {
          display: true,
          text: parameters.yLabel.replace(/\\/g, ''),
          color: graphOptions.axes
        }
      },
      x: {
        grid: {
          color: graphOptions.grid
        },
        ticks: {
          color: graphOptions.axes,
          min: Number(parameters.left),
          max: Number(parameters.right),
          callback: function (_: any, index: any) {
            return labels[index].toFixed(2)
          }
        },
        title: {
          display: true,
          text: parameters.xLabel.replace(/\\/g, ''),
          color: graphOptions.axes
        }
      }
    },
    elements: {
      point: {
        radius: 1
      }
    }
  }
  return (
    <div className="common-function-graph-chart" style={customStyle}>
      {showTitle && (
        <div className="common-function-graph-chart-title" style={{ color: graphOptions.title }}>
          <StaticMathField>{parameters.title}</StaticMathField>
        </div>
      )}
      <Line
        data={data}
        options={options}
        style={{ height: '100%', width: '100%', backgroundColor: graphOptions.background }}
      />
    </div>
  )
}

export default CommonFunctionGraph
