import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { goToReference } from './functions'
import { references } from '../../resources/loschmidtEcho'
import { mathJaxConfig } from '../../resources/loschmidtEcho'

const TheXXZModel = () => {
  return (
    <MathJaxContext version={3} config={mathJaxConfig}>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`The XXZ model is an interacting Heisenberg model, which consists of a one-dimensional 
          spin-1/2 chain and whose interactions show an anisotropy along a specific direction`}
        {goToReference(references.slice(11, 14), '.')}
        {`The anisotropic coupling constant $J$ induces different ordering in the system, so 
          that three phases can be characterised; namely, an antiferromagnetic one, an entirely 
          critical paramagnetic and a ferromagnetic region. The Heisenberg model describes the 
          nearest-neighbour spin interaction many particles. The most general Hamiltonian of the 
          Heisenberg model with nearest-neighbour interaction for $N$ lattice sites is given by 
          $$\\hat{H}=-\\sum^N_{j=1}[J_xS^x_jS^x_{j+1}+J_yS^y_jS^y_{j+1}+J_zS^z_jS^z_{j+1}].$$
          Here $J_{ij}$ is expressed in terms of three spatial components $J_x,~J_y,~J_z$. The 
          spin operators $\\hat{S}_j$ live on the lattice sites. Spin components on the same 
          lattice site obey the standard angular momentum commutation relations
          $$[S^\\alpha_j,S^\\beta_j]=i\\hbar\\sum_\\gamma\\epsilon_\\alpha\\beta_\\gamma S^\\gamma_j~(\\alpha,\\beta,\\gamma=x,y,z)$$
          and spins on different site commute with each other. The operators $S^2_j$ have 
          eigenvalues $S(S+1)$ where $S$ is an integer or half-integer. The spin interaction in 
          $\\hat{H}$ is called exchange interaction, and $J_{ij}$ is called coupling/exchange constant.
          The values of Jx; Jy, and Jz gives rise to different cases to consider for the general
          Heisenberg model. If Jx = Jy = Jz = J > 0, it is called the isotropic ferromagnetic
          Heisenberg XXX model, and Bethe was the first to provide analytical solution to the model`}
        {goToReference(references.slice(14, 15), '.')}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`The spin-1/2 one-dimensional Heisenberg XXZ model for $N$ lattice sites is given by the 
          following Hamiltonian
          $$\\hat{H}=-J\\sum^N_{j=1}[S^x_jS^x_{j+1}+S^y_jS^y_{j+1}+\\triangle S^z_jS^z_{j+1}]-2h\\sum^N_{j=1}S^z_j.$$
          Here $S^\\alpha_j=\\frac{1}{2}\\sigma^\\alpha_j$ and $\\sigma^\\alpha$ are the Pauli 
          matrices obeying canonical $SU(2)$ commutation relations`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/xxz_1.png'}
          alt="Pauli-matrices"
        />
      </div>
      <MathJax className="loschmidt-echo-normal-container">
        {`Here $h$ accounts for a magnetic field applied along the $\\hat{z}$ direction. The 
          parameter $J$ sets the energy scales, and the parameter $\\triangle$ denotes the 
          strength of the uniaxial anisotropy along the $\\hat{z}$ direction.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`We can map the Hamiltonian to a free fermion Hamiltonian using the `}
        <b>Jordan-Wigner transformation</b>
        {`$$S^+_j=(-1)^j\\hat{c}^\\dagger_je^{i\\pi\\phi_j},~~S^-_j=(-1)^j\\hat{c}_je^{-i\\pi\\phi_j},~~S^z_j=\\hat{c}_j-\\frac{1}{2}$$
          where $n_j=c^\\dagger_jc_j=S^+_jS^-_j$ is the occupation number operator, $\\phi_j$ is 
          the phase factor which contains the sum over all fermion occupations at site to the left 
          of $j$, $\\phi_j=\\sum^{j-1}_{l=1}n_l$, and $c_j(c^\\dagger_j)$ are annihilation 
          (creation) operators of spinless fermions at site $j$. The operator $e^{i\\phi_j}$ is 
          known as a string operator. The JWT really means 
          $$\\text{spin}=\\text{fermion}\\times~\\text{string}.$$
          This transformation solves a major problem in the Hamiltonian, where spins on different 
          sites commute whereas fermion operators anticommute, i.e., $S^+_iS^+_j=S^+_jS^+_i$, but 
          $c^\\dagger_ic^\\dagger_j=-c^\\dagger_jc^\\dagger_i$. The string operator $e^{i\\phi_j}$ 
          anticommutes with all fermions at all sites $l$ to the left of $j$, i.e., 
          $l<j$: $\\{e^{i\\phi_j},c^\\dagger_l\\}=0$, while commuting with fermions at all sites to 
          the right $l\\geq j$: $[e^{i\\phi_j},c^\\dagger_l]=0~~(l\\geq j).$ Using the JWT, the 
          spin operators for sites $j<k$ commutes in the following way,
          $$[S^{\\pm}_j,S^{\\pm}_k]=[\\hat{c}^\\dagger_je^{i\\phi_j},\\hat{c}^\\dagger_ke^{i\\phi_k}]=e^{i\\phi_j}[\\hat{c}^\\dagger_j,\\hat{c}^\\dagger_k\\hat{c}^{i\\phi_k}].$$
          Since $c^\\dagger_j$ anticommutes with both $f^\\dagger_k$ and $e^{i\\phi_k}$, it 
          commutes with their product $f^\\dagger_ke^{i\\phi_k}$. Hence, satisfy the correct 
          commutation relation
          $$[S^{\\pm}_j,S^{\\pm}_k]\\propto[\\hat{c}^\\dagger_j,\\hat{c}^\\dagger_ke^{i\\phi}]=0.$$
          We consider zero magnetic field, $h=0$, for the rest of the thesis. Using the JWT, we can 
          express the spin-1/2 one-dimensional XXZ Hamiltonian in following fermionic Hamiltonian
          $$\\hat{H}_{JWT}=-\\frac{J}{2}\\sum^N_{j=1}(\\hat{c}^\\dagger_j\\hat{c}_{j+1}+\\hat{c}^\\dagger_{j+1}\\hat{c}_j)+J\\triangle\\sum^N_{j=1}\\hat{n}_j-J\\triangle\\sum^N_{j=1}\\hat{n}_j\\hat{n}_{j+1}$$
          Here we omit the term $\\sum^N_j1/4=N/4$ because it doesn't change the energy spectrum`}
        {goToReference(references.slice(2, 3), '.')}
        {`This Hamiltonian allows us investigate quantum behaviours of spin particles. `}
      </MathJax>
    </MathJaxContext>
  )
}

export default TheXXZModel
