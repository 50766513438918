import React from 'react'
import '../assets/introduction.sass'
import { colors } from '../utils/resources'

class Introduction extends React.Component<{ count: number; effect: number }, {}> {
  name = ' am Lewis Chen'
  private myRef: React.RefObject<HTMLImageElement>

  constructor(props: any) {
    super(props)
    this.myRef = React.createRef()
  }

  handleText(): string {
    const length = this.name.length
    const mod: number = this.props.count % length
    if (Math.floor((this.props.count / length) % 2) === 0) {
      return this.name.slice(0, mod)
    }
    return this.name.slice(0, length - mod)
  }

  componentDidUpdate(): void {
    const mod2 = this.props.count % 40
    if (this.myRef.current !== null) {
      if (this.props.effect === 0) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `drop-shadow(${20}px ${20}px ${10}px ${colors[mod2]})`
        } else {
          this.myRef.current!.style.filter = `drop-shadow(${20}px ${20}px ${10}px ${colors[mod2]})`
        }
      } else if (this.props.effect === 1) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `brightness(${10 * (mod2 + 5)}%)`
        } else {
          this.myRef.current!.style.filter = `brightness(${10 * (50 - mod2)}%)`
        }
      } else if (this.props.effect === 2) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `blur(${mod2}px)`
        } else {
          this.myRef.current!.style.filter = `blur(${40 - mod2}px)`
        }
      } else if (this.props.effect === 3) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `contrast(${10 * mod2 + 1}%)`
        } else {
          this.myRef.current!.style.filter = `contrast(${10 * (40 - mod2 + 1)}%)`
        }
      } else if (this.props.effect === 4) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `grayscale(${5 * mod2}%)`
        } else {
          this.myRef.current!.style.filter = `grayscale(${5 * (40 - mod2)}%)`
        }
      } else if (this.props.effect === 5) {
        this.myRef.current!.style.filter = `hue-rotate(${9 * mod2}deg)`
      } else if (this.props.effect === 6) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `invert(${5 * mod2}%)`
        } else {
          this.myRef.current!.style.filter = `invert(${5 * (40 - mod2)}%)`
        }
      } else if (this.props.effect === 7) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `opacity(${5 * mod2}%)`
        } else {
          this.myRef.current!.style.filter = `opacity(${5 * (40 - mod2)}%)`
        }
      } else if (this.props.effect === 8) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `saturate(${mod2})`
        } else {
          this.myRef.current!.style.filter = `saturate(${40 - mod2})`
        }
      } else if (this.props.effect === 9) {
        if (mod2 <= 20) {
          this.myRef.current!.style.filter = `sepia(${5 * mod2}%)`
        } else {
          this.myRef.current!.style.filter = `sepia(${5 * (40 - mod2)}%)`
        }
      }
    }
  }

  render() {
    return (
      <div id="introduction">
        <div id="introduction-container" style={{ borderColor: colors[this.props.count % 40] }}>
          <div id="introduction-text-container">
            <h1>Welcome to My Website</h1>
            <h2>I {this.handleText()}</h2>
            <h3>I am a software developer, science researcher and machine learning engineer.</h3>
            <h3>I like physics, math, computer science, programming and software development.</h3>
          </div>
          <div id="introduction-image-container">
            <img
              ref={this.myRef}
              id="introduction-avatar"
              src={'https://assets.havkonsoft.com/landing/avatar.jpg'}
              alt="my-avatar"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Introduction
