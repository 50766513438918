import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { goToReference } from './functions'
import { mathJaxConfig, references } from '../../resources/loschmidtEcho'

const LoschmidtEcho = () => {
  return (
    <MathJaxContext version={3} config={mathJaxConfig}>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`For a closed quantum system, the time evolution of a quantum system is governed by 
          a unitary operator $U(t)=e^{-iHt}$. Applying the unitary operator $U(t)$ on an 
          initial quantum state $|\\Psi_0\\rangle$, the time evolution of the quantum state 
          is $|\\Psi(t)\\rangle = U(t)|\\Psi_0\\rangle$. As a consequence, initial quantum 
          state can be recovered by applying the inverse of $|\\Psi(t)\\rangle$ such that 
          $|\\Psi_0\\rangle=U^{\\dagger}(t)|\\Psi(t)\\rangle$.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        The <b>Loschmidt echo</b> is defined as the measure of the stability of time reversal,
        {`$$\\mathcal{L}=|\\langle\\Psi_0|e^{iH(g_2)t}e^{-iH(g_1)t}|\\Psi_0\\rangle|^2.$$`}
      </MathJax>
      <MathJax className="loschmidt-echo-normal-container">
        {`Here, $H(g)$ is the time-independent Hamiltonian of the system which is a function 
          of a microscopic parameter $g$. This equation states that the system is prepared 
          in an initial state $|\\Psi_0\\rangle$ which is allowed to time evolve during the 
          forward time evolution for $g = g_1$. Then applying the backward time evolution 
          $g = g_2$ would bring the quantum state back to the initial state, and 
          $\\mathcal{L}(t) = 1$ if $g_1 = g_2$. Hence, $\\mathcal{L}(t)$ is the overlap of 
          two quantum states between the time evolution state with 
          $H(g_1)$ and the state with $H(g_2)$.`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/loschmidt_echo_1.png'}
          alt="loschmidt-echo-schematics"
        />
        <MathJax>
          {`Figure 1.1: Schematic flow of the time evolution for the Loschmidt echo`}
          {goToReference(references.slice(0, 1), '.')}
        </MathJax>
      </div>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`To study and classify quenches in closed quantum systems by means of the Loschmidt
          echo, we concentrate on the case where $|\\Psi_0\\rangle$ is the ground state 
          of $H(g_2)$. In this case, the Loschmidt echo takes the following form
          $$\\mathcal{L}(t)=|\\langle\\Psi_0|e^{-iHt}|\\Psi_0\\rangle^2,$$
          where we have set $H \\equiv H(g_1)$.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`Because of the non-interacting character of initial state of a localized system, the 
          system is non-interacting and the Hamiltonian is bilinear and hermitian`}
        {goToReference(references.slice(1, 2), '.')}
        {`Generally, 
          a bilinear Hamiltonian following Jordan-Wigner transformation (JWT) can be described 
          by the quadratic form in the freefermion model. The bilinear Hamiltonian following 
          Jordan-Wigner transformation (JWT) can be described by the quadratic form in the 
          free-fermion model 
          $$\\hat{H}=\\sum_{i,j}\\left[\\hat{c}^\\dagger_iA_{i,j}\\hat{c}_j+\\frac{1}{2}(\\hat{c}^\\dagger_iB_{i,j}\\hat{c}^\\dagger_j+h.c)\\right],$$
          where $\\mathbf{A}=A_{i,j}$ is a real symmetric matrix due to hermiticity of $H$, 
          and $\\mathbf{B}=B_{i,j}$ is a real antisymmetric matrix which follows from the 
          anticommutation rules of the the annihilation and creation operators $c_i$ and 
          $c_i^\\dagger$, respectively`}
        {goToReference(references.slice(2, 3), '.')}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`To evaluate the time evolution of the Loschmidt echo, we can rewrite the above 
          Hamiltonian in the following generic quadratic form
          $$H=\\mathbf{\\Psi^\\dagger\\mathcal{H}\\Psi},$$
          where $\\mathbf{\\Psi^\\dagger}=\\left(c^\\dagger_1\\ldots c^\\dagger_N,c_1\\ldots c_N\\right)$, 
          with $c_k$ being the spinless Jordan-Wigner Fermion operators. 
          $\\mathcal{H}=\\hat{\\sigma}^z\\otimes\\mathbf{A}+i\\hat{\\sigma}^y\\otimes\\mathbf{B}$ 
          is the matrix of the single-particle Hamiltonian`}
        {goToReference(references.slice(2, 3), '.')}
        {`The evaluation of the Loschmidt 
          echo for single-particle Hamiltonians can then have the following formula
          $$\\mathcal{L}(t)=|\\text{det}(\\mathbb{1}-\\mathbf{C+C}e^{-i\\mathcal{H}t})|^2.$$
          Here $\\mathcal{H}$ is the matrix of the final Hamiltonian $H(g_1)$ after the quench 
          during the  forward evolution $g_1$. $\\mathbf{C}$ is the $2N\\times2N$ correlation 
          matrix whose matrix elements are simply the two-point correlation functions of the 
          fermionic operators: 
          $C_{ij}=\\langle\\Psi_0|\\Psi^\\dagger_i\\Psi_j|\\Psi_0\\rangle$. $|\\Psi_0\\rangle$ is 
          the ground state of the initial Hamiltonian $H(g_2)$. 
          The symbol $\\mathbb{1}$ is an $2N\\times2N$ identity matrix. The formula is a 
          function of three ingredients: the two-point correlation matrix $\\mathbf{C}$, the 
          exponential matrix $e^{-i\\mathcal{H}t}$, and the determinant form.`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        {`The Loschmidt echo can be used in both many-particle systems and non-interacting 
          single-particle quantum systems, where the Hamiltonians are bilinear and hermitian 
          and can by described by the quadratic form following Jordan-Wigner transformation`}
        {goToReference(references.slice(3, 6), ',')}
        {`$$\\mathcal{L}(t)=|\\text{det}(\\mathbb{1}-\\mathbf{C+C}e^{-i\\mathcal{H}t})|^2=|\\langle\\Psi_0|e^{-iHt}|\\Psi_0\\rangle|^2.$$`}
      </MathJax>
      <MathJax className="loschmidt-echo-paragraph-container">
        The term <b>dynamical phase transition</b> for non-analytical behavior of a thermalizing
        {` system in time $t$ corresponds to the breakdown of short time expansion in the 
          thermodynamic limit in a critical time`}
        {goToReference(references.slice(6, 7), '.')}
        {`As the complex parameter z transitions 
          from a real-valued temperature $\\beta$ to an imaginary time it, we transition 
          from thermalizing systems to localizing systems. For a system that reacts to a 
          global quench $g:~g_0\\rightarrow g_1$, the dynamic overlap between the initial 
          state $|\\Psi_0\\rangle$ and the initial state $|\\Psi(t)\\rangle$ is defined as the
          Loschmidt echo. $\\mathcal{L}(t)$ is a particular functional dependence on the 
          number of degrees of freedom $N$ in the large $N$ limit, 
          $$\\mathcal{L}(t)=e^{-Nl(t)},$$
          where $\\mathcal{l}(t)$ is called the return rate function`}
        {goToReference(references.slice(6, 8), ',')}
        {`and it is defined as
          $$\\mathcal{l}(t)=-\\lim_{N\\rightarrow\\infty}\\frac{1}{N}\\ln{\\mathcal{L}(t)}.$$
          Figure 1.2 shows the schematic illustration for the Loschmidt echo $\\mathcal{L}(t)$ and 
          return rate $\\mathcal{l}(t)$ as functions of time. We will look at calculate the 
          Loschmidt echo and the return rate for both the Su-Shrieffer-Heeger (SSH) model and 
          the XXZ Heisenberg model on both interacting and non-interacting systems.`}
      </MathJax>
      <div className="loschmidt-echo-image-container">
        <img
          className="loschmidt-echo-image"
          src={'https://assets.havkonsoft.com/projects/loschmidt_echo_2.png'}
          alt="return-rate-schematics"
        />
        <MathJax className="align-left">
          {`Figure. 1.2: Schematic illustration of a dynamical quantum phase transition. (A) The 
            Loschmidt echo $\\mathcal{L}(t) $decays exponentially and gives no further 
            information. (B) The return rate $\\mathcal{l}(t)$ shows non-analyticities as cusps 
            at several critical times $t=t_c$. and return rate $\\mathcal{l}(t)$ as functions of 
            time.`}
        </MathJax>
      </div>
    </MathJaxContext>
  )
}

export default LoschmidtEcho
