import { SystemModel, SystemType } from '../enum'
import { FormParametersIF, ReferenceIF } from '../interface/loschmidtEcho'
import { DataSetIF, GraphOptionsIF, GraphParametersIF } from '../interface/common'

export const mscThesisLink =
  'https://mspace.lib.umanitoba.ca/items/ca682b83-f452-49ed-ae1f-acba34a5ca55'

export const mscThesisView = '/project/loschmidt-echo/view'

export const mathJaxConfig = {
  loader: { load: ['[tex]/html'] },
  tex: {
    packages: { '[+]': ['html'] },
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)']
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]']
    ]
  }
}

export const initialParametersLE: GraphParametersIF = {
  function: '',
  jsFunction: '',
  pyFunction: '',
  parameter: '',
  left: '0',
  right: '10',
  title: 'Loschmidt Echo',
  xLabel: 't',
  yLabel: 'Loschmidt Echo L(t)'
}

export const initialParametersRR: GraphParametersIF = {
  function: '',
  jsFunction: '',
  pyFunction: '',
  parameter: '',
  left: '0',
  right: '10',
  title: 'Return Rate',
  xLabel: 't',
  yLabel: 'Return Rate l(t)'
}

export const initialGraphData: GraphOptionsIF = {
  step: 80,
  plot: ['Function'],
  y: 'rgb(20, 75, 205, 1)',
  yp: 'rgba(255, 255, 0, 1)',
  ypp: 'rgba(0, 255, 66, 1)',
  title: 'rgba(0, 0, 0, 1)',
  grid: 'rgba(0, 0, 0, 1)',
  axes: 'rgba(0, 0, 0, 1)',
  background: 'rgba(255, 255, 255, 1)'
}

export const leReferences: ReferenceIF[] = [
  {
    order: 1,
    title:
      'A. Goussev, R. A. Jalabert, H. M. Pastawski, and D. Wisniacki. Loschmidt echo. <i>arXiv preprint arXiv:1206.6348,</i> 2012.',
    link: 'https://arxiv.org/abs/1206.6348'
  },
  {
    order: 2,
    title:
      'A. Rajak and U. Divakaran. Effect of double local quenches on the loschmidt echo and entanglement entropy of a one-dimensional ' +
      'quantum system. <i>Journal of Statistical Mechanics: Theory and Experiment,</i> 2016(4), April 2016. ISSN 1742-5468.',
    link: 'https://arxiv.org/abs/1507.00963'
  },
  {
    order: 3,
    title:
      'E. Lieb, T. Schultz, and D. Mattis. Two soluble models of an antiferromagnetic chain. <i>Annals of Physics,</i> 16:407-466, ' +
      'Dec. 1961. doi:10.1016/0003-4916(61)90115-4.',
    link: 'https://arxiv.org/abs/1507.00963'
  },
  {
    order: 4,
    title:
      "I. Klich. Full counting statistics: An elementary derivation of levitov's formula. September 2002.",
    link: 'https://arxiv.org/abs/cond-mat/0209642'
  },
  {
    order: 5,
    title:
      'L. S. Levitov, H. Lee, and G. B. Lesovik. Electron counting statistics and coherent states of electric current. ' +
      '<i>Journal of Mathematical Physics,</i> 37(10): 4845-4866, 1996. doi:10.1063/1.531672.',
    link: 'https://doi.org/10.1063/1.531672'
  },
  {
    order: 6,
    title:
      'D. Rossini, T. Calarco, V. Giovannetti, S. Montangero, and R. Fazio. Decoherence induced by interacting quantum spin baths. ' +
      '<i>Phys. Rev. A,</i> 75:032333, Mar 2007. doi:10.1103/PhysRevA.75.032333.',
    link: 'https://journals.aps.org/pra/abstract/10.1103/PhysRevA.75.032333'
  },
  {
    order: 7,
    title:
      'M. Heyl, A. Polkovnikov, and S. Kehrein. Dynamical quantum phase transitions in the transverse-field ising model. ' +
      '<i>Physical review letters,</i> 110(13):135704, 2013.',
    link: 'https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.110.135704'
  },
  {
    order: 8,
    title:
      'M. Heyl. Quenching a quantum critical state by the order parameter: Dynamical quantum phase transitions and quantum speed limits. ' +
      '<i>Phys. Rev. B,</i> 95:060504, Feb 2017. doi:10.1103/PhysRevB.95.060504.',
    link: 'https://journals.aps.org/prb/abstract/10.1103/PhysRevB.95.060504'
  }
]

export const sshReferences: ReferenceIF[] = [
  {
    order: 9,
    title:
      'A. J. Heeger. Nobel lecture: Semiconducting and metallic polymers: The fourth generation of polymeric materials. ' +
      '<i>Rev. Mod. Phys.,</i> 73:681-700, Sep 2001. doi:10.1103/RevModPhys.73.681.',
    link: 'https://journals.aps.org/rmp/abstract/10.1103/RevModPhys.73.681'
  },
  {
    order: 10,
    title:
      'E. J. Meier, F. A. An, and B. Gadway. Observation of the topological soliton state in the su-schrieffer-heeger model. ' +
      '<i>Nature Communications,</i> 7, 2016. ISSN 2041-1723.',
    link: 'https://www.nature.com/articles/ncomms13986'
  },
  {
    order: 11,
    title:
      'J. K. Asbóth, L. Oroszlány, and A. Pályi. ' +
      '<i>A Short Course on Topological Insulators: Band Structure and Edge States in One and Two Dimensions, volume 919 of Lecture Notes in Physics.</i> ' +
      'Springer International Publishing, Cham, 2016. ISBN 978-3-319-25605-4.',
    link: 'https://arxiv.org/abs/1509.02295'
  }
]

export const xxzReferences: ReferenceIF[] = [
  {
    order: 12,
    title:
      'A. Altland. <i>Condensed matter field theory / Alexander Altland and Ben Simons.</i>' +
      'Cambridge University Press, Cambridge ; New York, 2nd ed.. edition, 2010. ISBN 9780521769754.',
    link: 'https://assets.cambridge.org/97805217/69754/frontmatter/9780521769754_frontmatter.pdf'
  },
  {
    order: 13,
    title:
      'R. J. Baxter. <i>Exactly solved models in statistical mechanics / Rodney J. Baxter.</i> ' +
      'Dover Publications, Mineola, N.Y., dover ed.. edition, 2007. ISBN 9780486462714.',
    link: 'https://physics.anu.edu.au/research/ftp/mpg/baxter_book.php'
  },
  {
    order: 14,
    title:
      'S. Blundell. <i>Magnetism in Condensed Matter.</i> Oxford University Press, 2014. ISBN 9780198505914.',
    link: 'https://global.oup.com/academic/product/magnetism-in-condensed-matter-9780198505914?cc=ca&lang=en&'
  },
  {
    order: 15,
    title:
      'H. Bethe. Zur theorie der metalle. <i>Zeitschrift für Physik,</i> 71(3-4):205-226, 1931.',
    link: 'https://link.springer.com/article/10.1007/BF01341708'
  }
]

export const disReferences: ReferenceIF[] = [
  {
    order: 16,
    title:
      'R. Nandkishore and D. A. Huse. Many-body localization and thermalization in um statistical mechanics. ' +
      '<i>Annu. Rev. Condens. Matter Phys.,</i> 6(1):15-38, 2015.',
    link: 'https://www.annualreviews.org/content/journals/10.1146/annurev-conmatphys-031214-014726'
  },
  {
    order: 17,
    title:
      'J. M. Deutsch. Quantum statistical mechanics in a closed system. <i>Phys. Rev. A,</i> ' +
      '43:2046-2049, Feb 1991. doi:10.1103/PhysRevA.43.2046.',
    link: 'https://journals.aps.org/pra/abstract/10.1103/PhysRevA.43.2046'
  },
  {
    order: 18,
    title:
      'M. Rigol, V. Dunjko, and M. Olshanii. Thermalization and its mechanism for generic isolated quantum systems. ' +
      '<i>Nature,</i> 452(7189):854, 2008.',
    link: 'https://www.nature.com/articles/nature06838'
  },
  {
    order: 19,
    title:
      'M. Srednicki. Chaos and quantum thermalization. <i>Phys. Rev. E,</i> 50:888-901, Aug 1994. doi:10.1103/PhysRevE.50.888.',
    link: 'https://journals.aps.org/pre/abstract/10.1103/PhysRevE.50.888'
  },
  {
    order: 20,
    title:
      'Y. Zhao, F. Andraschko, and J. Sirker. Entanglement entropy of disordered quantum chains following a global quench. ' +
      '<i>Physical Review B,</i> 93(20):205146, 2016.',
    link: 'https://journals.aps.org/prb/abstract/10.1103/PhysRevB.93.205146'
  },
  {
    order: 21,
    title:
      'P. W. Anderson. Absence of diffusion in certain random lattices. <i>Phys. Rev.,</i>' +
      ' 109:1492-1505, Mar 1958. doi:10.1103/PhysRev.109.1492.',
    link: 'https://journals.aps.org/pr/abstract/10.1103/PhysRev.109.1492'
  },
  {
    order: 22,
    title:
      'A. Aspect and M. Inguscio. Anderson localization of ultracold atoms. <i>Physics Today,</i> 62(8):30-35, 2009. ISSN 0031-9228.',
    link: 'https://pubs.aip.org/physicstoday/issue/62/8'
  },
  {
    order: 23,
    title:
      'D. M. Basko, I. L. Aleiner, and B. L. Altshuler. Metal-insulator transition in a weakly interacting many-electron system with localized single-particle states. ' +
      '<i>Annals of physics,</i> 321(5):1126-1205, 2006.',
    link: 'https://www.sciencedirect.com/science/article/abs/pii/S0003491605002630'
  }
]

export const references = leReferences.concat(sshReferences, xxzReferences, disReferences)

export const loschmidtEchoDataSSH = {
  LE: [
    1.0, 0.999752963621827, 0.9990122174277682, 0.9977788493042897, 0.9960546692823607,
    0.993842204879072, 0.9911446945985591, 0.9879660796147991, 0.9843109936652427,
    0.9801847511905442, 0.9755933337618589, 0.970543374843337, 0.9650421429434599,
    0.9590975232147734, 0.952717997567384, 0.9459126233671858, 0.9386910107953264,
    0.9310632989507044, 0.9230401307824807, 0.914632626944537, 0.9058523586686109,
    0.8967113197574099, 0.8872218978033806, 0.8773968447429799, 0.8672492468602248,
    0.8567924943570165, 0.8460402506112273, 0.8350064212467828, 0.8237051231430165,
    0.8121506535133376, 0.8003574591858349, 0.7883401062207676, 0.7761132500019878,
    0.7636916059412597, 0.7510899209361158, 0.7383229457233993, 0.7254054082719692,
    0.7123519883592281, 0.6991772934771859, 0.6858958362147233, 0.6725220132636271,
    0.6590700861968447, 0.6455541641683089, 0.6319881886846632, 0.6183859206003315,
    0.604760929488669, 0.5911265855434574, 0.5774960541668278, 0.5638822934018296,
    0.5502980543703248, 0.5367558848796724, 0.5232681363646627, 0.5098469743342375,
    0.4965043924954202, 0.4832522307291075, 0.47010219709332435, 0.45706589402824715,
    0.4441548489323576, 0.4313805492686236, 0.41875448234095075, 0.40628817985072907,
    0.39399326729635464, 0.38188151820878685, 0.3699649131153448, 0.3582557029814794,
    0.34676647668301297, 0.3355102317929987, 0.3245004476084852, 0.3137511588706484,
    0.3032770280225816, 0.2930934130781069, 0.28321642722186935, 0.2736629851153994,
    0.26445082955545174, 0.2555985306632078, 0.2471254482710659, 0.2390516467863293,
    0.2313977508110867, 0.22418472955671495, 0.2174335990855708, 0.2111650341824815,
    0.20539888671899068, 0.20015361505673704, 0.1954456392824175, 0.19128864919484387,
    0.18769290451487938, 0.18466457754761084, 0.18220519483850123, 0.1803112337632969,
    0.1789739210093179, 0.17817926280522287, 0.17790831381622385, 0.17813766676004933,
    0.17884012257183635, 0.1799854852731777, 0.18154141881422892, 0.18347430516852864,
    0.18575005213126408, 0.188334812791098, 0.1911955935282929, 0.19430074118885832,
    0.19762031124095328, 0.20112632659614008, 0.20479294145218946, 0.2085965265026971,
    0.2125156918600288, 0.21653126274657572, 0.2206262210113625, 0.2247856232634285,
    0.22899650417421571, 0.2332477714619913, 0.23753009731636915, 0.24183580957416198,
    0.24615878480665124, 0.25049434459106956, 0.2548391555762858, 0.2591911334743338,
    0.2635493507788564, 0.26791394779703975, 0.27228604645697796, 0.2766676662965653,
    0.281061642036388, 0.2854715421747632, 0.28990158810813493, 0.2943565733666447,
    0.29884178265672207, 0.3033629105148246, 0.30792597949470646, 0.3125372579307756,
    0.31720317743860754, 0.3219302504269217, 0.3267249879999713, 0.3315938187220888,
    0.33654300879412813, 0.3415785842521704, 0.34670625584002257, 0.35193134722729036,
    0.3572587272434413, 0.36269274677535984, 0.36823718093242436, 0.37389517702084785,
    0.37966920879042315, 0.3855610373251151, 0.3915716788476878, 0.3977013796016886,
    0.40394959786554624, 0.4103149930471127, 0.416795421706157, 0.4233879402601036,
    0.43008881404709765, 0.436893532352036, 0.44379682894652084, 0.4507927076532151,
    0.4578744724185246, 0.4650347613641779, 0.47226558428695187, 0.47955836308499405,
    0.48690397460730767, 0.4942927954481836, 0.5017147482389817, 0.5091593490239926,
    0.5166157553436285, 0.5240728146855753, 0.5315191130016056, 0.5389430230236057,
    0.546332752146286, 0.553676389675463, 0.5609619532694246, 0.5681774344264809,
    0.5753108428943069, 0.5823502498961763, 0.5892838300857175, 0.5960999021556589,
    0.6027869680373381, 0.6093337506368077, 0.6157292300604158, 0.6219626782880845,
    0.6280236922563539, 0.6339022253158964, 0.6395886170298309, 0.64507362128003,
    0.6503484326488512, 0.655404711043571, 0.6602346045303547, 0.6648307703440196,
    0.6691863940392538, 0.6732952067484353, 0.6771515005108314, 0.6807501416378692,
    0.6840865820793397, 0.6871568687559662, 0.6899576508247457, 0.692486184844889,
    0.6947403378141287, 0.6967185880476193, 0.6984200238747083, 0.6998443401324941,
    0.7009918324393912, 0.7018633892368731, 0.7024604815932627, 0.7027851507698307,
    0.7028399935566776, 0.7026281453938372, 0.7021532613018863, 0.701419494655995,
    0.7004314738478857, 0.6991942768916104, 0.6977134040413022, 0.6959947485022514,
    0.6940445653306101, 0.6918694386318324, 0.6894762471834446, 0.6868721286238693,
    0.6840644423657061, 0.6810607314088485, 0.6778686832460888, 0.6744960900710063,
    0.6709508085149316, 0.6672407191561429, 0.6633736860600534, 0.6593575166235107,
    0.6551999220092166, 0.6509084784671821, 0.6464905898488246, 0.6419534516252509,
    0.6373040167242126, 0.6325489634997506, 0.6276946661443688, 0.6227471678454616,
    0.6177121569754931, 0.6125949465889176, 0.6074004574781323, 0.6021332050158995,
    0.5967972899828641, 0.5913963935464186, 0.5859337765215835, 0.5804122830063654,
    0.5748343484438729, 0.5692020121220469, 0.5635169340799098, 0.5577804163477627,
    0.5519934284084042, 0.5461566367282646, 0.5402704381720109, 0.5343349970825051,
    0.5283502857806692, 0.5223161282173597, 0.5162322464921998, 0.5100983099428371,
    0.5039139865023416, 0.49767899602240556, 0.4913931652656094, 0.48505648428067305,
    0.4786691638900442, 0.47223169403851034, 0.46574490277392777, 0.4592100156556151,
    0.4526287154111546, 0.4460032016867583, 0.4393362507583692, 0.4326312750881028,
    0.4258923826212352, 0.41912443571984226, 0.4123331096171832, 0.40552495024814483,
    0.39870743126113384, 0.39188900994054143, 0.38507918166062693, 0.37828853234491244,
    0.37152878821311347, 0.3648128618534618, 0.3581548933554651, 0.3515702848720229,
    0.3450757265482465, 0.3386892112603009, 0.3324300350614399, 0.32631877965523115,
    0.3203772726432081, 0.3146285207798065, 0.3090966110868343, 0.30380657453169374,
    0.29878420717863907, 0.29405584441543, 0.2896480851743986, 0.28558746511951777,
    0.2819000806170947, 0.27861116891166693, 0.27574465412481114, 0.27332267316119885,
    0.27136509986540247, 0.26988908922720667, 0.26890866544667325, 0.26843437768670564,
    0.2684730450175224, 0.2690276073794382, 0.2700970927275601, 0.2716767026004939,
    0.2737580101395878, 0.2763292570973943, 0.279375730496147, 0.28288019591263897,
    0.28682336307912387, 0.2911843604351275, 0.2959411979931515, 0.3010712017860773,
    0.30655140761378835, 0.31235890624674023, 0.3184711362606623, 0.32486612401440207,
    0.331522672834517, 0.33842050524283185, 0.3455403631406932, 0.35286407137066206,
    0.36037457014958346, 0.36805592163604733, 0.3758932954717095, 0.3838729376085312,
    0.3919821261688216, 0.40020911752776245, 0.40854308528782957, 0.41697405434748674,
    0.4254928318596292, 0.4340909365290219, 0.44276052740917105, 0.45149433312195303,
    0.46028558223125227, 0.46912793534810676, 0.47801541942262893, 0.4869423645815322,
    0.49590334379404644, 0.5048931155889482, 0.5139065699976368, 0.5229386778596176,
    0.5319844435949427, 0.5410388615213652, 0.5500968757705125, 0.5591533438364611,
    0.5682030037708099, 0.5772404450201756, 0.5862600828847914, 0.5952561365602768,
    0.6042226107086792, 0.6131532804896023, 0.6220416799676789, 0.6308810937988826,
    0.6396645520854858, 0.6483848282777164, 0.6570344399897193, 0.6656056525881765,
    0.6740904854040636, 0.6824807204114853, 0.6907679132124365, 0.6989434061625253,
    0.7069983434703504, 0.7149236881020239, 0.722710240322437, 0.7303486577060445,
    0.7378294764521309, 0.7451431338426326, 0.7522799916844825, 0.759230360582986,
    0.7659845248979005, 0.7725327682394336, 0.7788653993672924, 0.7849727783621164,
    0.7908453429448957, 0.7964736348264072, 0.8018483259750867, 0.8069602446980761,
    0.8118004014364367, 0.8163600141815673, 0.8206305334257292, 0.8246036665652806,
    0.8282714016805991, 0.8316260306218733, 0.834660171334853, 0.8373667893653167,
    0.839739218485431, 0.8417711803893546, 0.8434568034093765, 0.8447906402076248,
    0.8457676844019073, 0.8463833860875997, 0.8466336662207001, 0.8465149298302183,
    0.8460240780310082, 0.8451585188109836, 0.8439161765694301, 0.8422955003858017,
    0.8402954710010737, 0.8379156064963406, 0.8351559666559746, 0.8320171560053141,
    0.8285003255154776, 0.824607172970626, 0.8203399419956989, 0.8157014197454681,
    0.8106949332585991, 0.8053243444833297, 0.7995940439843857, 0.793508943343826,
    0.7870744662716773, 0.7802965384454473, 0.7731815761009452, 0.7657364734002202,
    0.7579685886059336, 0.7498857290949936, 0.7414961352479464, 0.732808463254226,
    0.7238317668771662, 0.7145754782263801, 0.7050493875889342, 0.6952636223745892,
    0.685228625234137, 0.674955131413757, 0.6644541454120587, 0.6537369170102638,
    0.6428149167496803, 0.6316998109342694, 0.6204034362396592, 0.6089377740134092,
    0.5973149243546327, 0.5855470800643131, 0.5736465005606309, 0.5616254858564695,
    0.549496350698918, 0.537271398973011, 0.5249628984741336, 0.512583056155502, 0.5001439939587893,
    0.4876577253374741, 0.47513613258366866, 0.4625909450701726, 0.4500337185202761,
    0.43747581541848996, 0.4249283866759022, 0.4124023546644551, 0.3999083977351421,
    0.3874569363362126, 0.37505812084913986, 0.36272182126269126, 0.35045761880945175,
    0.3382747996950737, 0.3261823510592484, 0.31418895931983215, 0.3023030110692016,
    0.29053259671637877, 0.2788855171024325, 0.26736929336304033, 0.2559911803755286,
    0.24475818421392861, 0.233677084152792, 0.22275445991985388, 0.2119967251145788,
    0.20141016800609415, 0.19100100133063477, 0.18077542326928422, 0.17073969256469185,
    0.1609002218215881, 0.15126369456374358, 0.14183721378438427, 0.13262849281524958,
    0.12364610377283357, 0.11489980523421603, 0.10640098002933122, 0.09816322732016933,
    0.09020317198254571, 0.08254158018152027, 0.07520490305223455, 0.06822740542171422,
    0.061654052792421955, 0.05554426631802048, 0.04997636039847933, 0.045051636643455156,
    0.040895268374583946, 0.03764826396177158, 0.03544329570013338, 0.03436351073983907,
    0.034401331386845924, 0.03544717932853832, 0.03731995177831216, 0.03981678383559892,
    0.04275197111187127, 0.04597434079887698, 0.04936884269665825, 0.05285114444079922,
    0.05636076597182882, 0.05985504868628784, 0.06330451610462758, 0.06668951138070313,
    0.06999782666339456, 0.07322305141220967, 0.07636342559088562, 0.07942104195169865,
    0.08240128722319959, 0.08531244463591453, 0.08816540264281918, 0.09097342985624693,
    0.09375198648888416, 0.09651854968215837, 0.09929243525740267, 0.1020946024962919,
    0.10494743214309421, 0.1078744713118681, 0.11090014258800782, 0.11404941838200951,
    0.11734746541632783, 0.12081926786227902, 0.12448924075994806, 0.12838084758656618,
    0.13251623687250294, 0.13691591240831202, 0.1415984498338414, 0.14658026944545427,
    0.1518754712709958, 0.15749573432465877, 0.1634502779658519, 0.16974587987920536,
    0.17638694266498328, 0.18337559951437316, 0.1907118489205322, 0.1983937087013208,
    0.206417380569083, 0.21477741784038346, 0.22346689041453144, 0.23247754268607732,
    0.24179994146678835, 0.2514236122015482, 0.26133716273772606, 0.27152839464836315,
    0.28198440263616353, 0.292691662889704, 0.3036361114607183, 0.3148032138166778,
    0.3261780267271473, 0.33774525359150287, 0.34948929422999103, 0.3613942900557889,
    0.37344416543357567, 0.3856226659184354, 0.3979133939623236, 0.4102998425772558,
    0.42276542735614103, 0.4352935171742482, 0.44786746382678366, 0.46047063080007533,
    0.4730864213247588, 0.4856983058181919, 0.49828984878919474, 0.510844735250025,
    0.5233467966577277, 0.5357800363885534, 0.5481286547345596, 0.5603770734001164,
    0.5725099594673891, 0.5845122487933249, 0.5963691687961874, 0.608066260586639,
    0.6195894003966441, 0.6309248202587909, 0.6420591278888896, 0.6529793257256007,
    0.663672829082376, 0.6741274833689535, 0.684331580342073, 0.6942738733476526,
    0.7039435915196257, 0.7133304529036945, 0.7224246764774275, 0.7312169930414489,
    0.7396986549598296, 0.7478614447311502, 0.7556976823751033, 0.7632002316229224,
    0.7703625049032434, 0.7771784671183725, 0.783642638209192, 0.7897500945101722,
    0.7954964688991453, 0.8008779497495939, 0.8058912786962782, 0.8105337472279973,
    0.8148031921242334, 0.818697989755312, 0.8222170492685047, 0.8253598046853118,
    0.8281262059378945, 0.8305167088753257, 0.8325322642729983, 0.8341743058812092,
    0.8354447375515502, 0.8363459194824058, 0.8368806536274715, 0.8370521683138731,
    0.8368641021191024, 0.8363204870587007, 0.8354257311392757, 0.834184600334212,
    0.8326022000421223, 0.8306839560909248, 0.828435595353165, 0.8258631260410366,
    0.8229728177523469, 0.8197711813414854, 0.8162649486922279, 0.8124610524719531,
    0.808366605949548, 0.8039888829618855, 0.7993352981162497, 0.7944133873185145,
    0.7892307887190557, 0.7837952241704306, 0.7781144812926891, 0.7721963962437366,
    0.766048837293428, 0.7596796893010688, 0.753096839196583, 0.746308162565879, 0.7393215114407345,
    0.732144703392992, 0.7247855120318052, 0.7172516590011807, 0.7095508075732008,
    0.7016905579298701, 0.6936784442237349, 0.6855219335041767, 0.6772284265926534,
    0.6688052609860695, 0.6602597158631605, 0.6515990192640464, 0.642830357508137,
    0.6339608869104058, 0.6249977478505072, 0.6159480812435619, 0.6068190474553796,
    0.5976178476985924, 0.5883517479392164, 0.579028105335709, 0.5696543972239789,
    0.5602382526518898, 0.5507874864548552, 0.5413101358495415, 0.5318144995043729,
    0.5223091790222643, 0.5128031227413813, 0.5033056717214981, 0.4938266077345836,
    0.4843762030156285, 0.47496527144990985, 0.4656052207720446, 0.45630810522579784,
    0.44708667797657303, 0.43795444237567605, 0.428925700941693, 0.4200156006447792,
    0.4112401727513633, 0.4026163651086644, 0.3941620643241076, 0.3858961048332454,
    0.3778382613693084, 0.37000922087718746, 0.36243052949803894, 0.35512450994829353,
    0.34811414450724354, 0.3414229190061505, 0.3350746237872636, 0.3290931086836567,
    0.32350199075661656, 0.31832431587509596, 0.31358217822408074, 0.3092963053817947,
    0.30548562048601924, 0.3021667968652391, 0.29935382387696297, 0.2970576050475557,
    0.29528561043866164, 0.29404160409084257, 0.2933254642563067, 0.2931331090684235,
    0.2934565337593756, 0.2942839582428544, 0.2956000766825011, 0.2973863944119365,
    0.2996216329354639, 0.30228218111913246, 0.30534257015755417, 0.3087759512556853,
    0.3125545577660601, 0.31665013723358115, 0.32103434288302485, 0.3256790780772621,
    0.33055679083868583, 0.33564071845968513, 0.340905084450073, 0.34632525159498206,
    0.3518778358042433, 0.35754078583478655, 0.36329343398265135, 0.36911652258537075,
    0.37499221074819544, 0.3809040651882267, 0.3868370385387702, 0.39277743791405384,
    0.3987128860287983, 0.4046322767135414, 0.4105257262721757, 0.4163845217941888,
    0.42220106725776585, 0.42796882803689534, 0.4336822742496695, 0.43933682325016776,
    0.4449287814665629, 0.4504552857177288, 0.4559142440946453, 0.4613042764668171,
    0.4666246546638864, 0.4718752423853141, 0.4770564349033517, 0.48216909864442775,
    0.48721451075901306, 0.4921942988183161, 0.49711038080615383, 0.5019649056045479,
    0.5067601942006568, 0.5114986818695322, 0.5161828616108041, 0.5208152291367041,
    0.5253982297234057, 0.5299342072465163, 0.5344253557244543, 0.5388736736899573,
    0.5432809217001086, 0.5476485832788318, 0.5519778295633044, 0.5562694878972706,
    0.5605240145806301, 0.5647414719463605, 0.5689215098938898, 0.5730633519631537,
    0.5771657859869166, 0.5812271593114172, 0.5852453785281421, 0.5892179136133534,
    0.5931418063280608, 0.5970136826900754, 0.6008297692923803, 0.6045859132089849,
    0.6082776052009904, 0.6119000059121991, 0.6154479747253756, 0.6189161009372177,
    0.6222987369020629, 0.6255900327912796, 0.628783972616654, 0.6318744111716362,
    0.6348551115535483, 0.6377197829423169, 0.6404621183264786, 0.6430758318846022,
    0.645554695749376, 0.6478925759020585, 0.6500834669661636, 0.6521215256909974,
    0.6540011029374148, 0.6557167739997831, 0.6572633671193155, 0.6586359900644339,
    0.6598300546735473, 0.6608412992744398, 0.6616658089122157, 0.6623000333345187,
    0.6627408026983771, 0.6629853409775961, 0.663031277063155, 0.662876653561549,
    0.6625199333075501, 0.6619600036184479, 0.6611961783265922, 0.6602281976360151,
    0.6590562258571101, 0.657680847080948, 0.6561030588617538, 0.654324263982494,
    0.6523462603844928, 0.6501712293474947, 0.6478017220117296, 0.6452406443383379,
    0.6424912406089818, 0.6395570755696739, 0.6364420153278224, 0.6331502071151565,
    0.6296860580327074, 0.6260542128972254, 0.6222595313114475, 0.6183070640833779,
    0.6142020291222223, 0.6099497869408652, 0.6055558158966472, 0.6010256873037169,
    0.5963650405514407, 0.5915795583639843, 0.5866749423364461, 0.5816568888825784,
    0.5765310657282091, 0.5713030890828826, 0.5659785016200095, 0.5605627513926924,
    0.5550611718086579, 0.5494789627829371, 0.5438211731814705, 0.5380926846623005,
    0.532298197013715, 0.5264422150804416, 0.5205290373598819, 0.51456274634048, 0.5085472006436065,
    0.502486029018982, 0.4963826262317052, 0.49024015086660105, 0.4840615250628561,
    0.4778494361790632, 0.4716063403759992, 0.4653344680918266, 0.4590358313721861,
    0.45271223300615443, 0.44636527740826626, 0.43999638317708195, 0.4336067972524856,
    0.4271976105868439, 0.42076977523991965, 0.4143241228040825, 0.4078613840648412,
    0.40138220980266076, 0.39488719264510386, 0.3883768898839916, 0.3818518471805788,
    0.37531262309262975, 0.3687598143712441, 0.3621940819920564, 0.3556161779052663,
    0.349026972512243, 0.3424274829028138, 0.33581890191726754, 0.32920262813083834,
    0.32258029689554274, 0.3159538126154891, 0.30932538247679614, 0.3026975519018946,
    0.2960732420504295, 0.289455789744123, 0.28284899025033017, 0.27625714341670154,
    0.2696851037048846, 0.2631383347209516, 0.2566229688782888, 0.2501458728468806,
    0.24371471943002584, 0.23733806644881592, 0.23102544308455958, 0.224787443900111,
    0.21863583039374812, 0.21258363938498148, 0.20664529672982468, 0.20083673374294028,
    0.19517550219015325, 0.18968088173524275, 0.18437397123255805, 0.1792777522590662,
    0.17441710988628212, 0.16981879217992402, 0.16551128679756852, 0.16152459115050974,
    0.15788985304472683, 0.15463886289414142, 0.15180338786476846, 0.1494143535275142,
    0.14750089949110126, 0.14608935997286582, 0.14520224414045818, 0.14485730843902467,
    0.14506681788181566, 0.1458370812621455, 0.14716831635454772, 0.1490548602992667,
    0.15148569627465447, 0.1544452300718636, 0.15791422684443024, 0.16187081215352483,
    0.16629145072482968, 0.17115183602700978, 0.17642764776008182, 0.18209515735413193,
    0.1881316802651464, 0.19451588685547364, 0.20122799112893322, 0.20824983958958487,
    0.2155649223371549, 0.22315832644808434, 0.2310166487040573, 0.2391278815016886,
    0.24748128272217737, 0.25606723767040873, 0.26487711898509925, 0.27390314866948,
    0.2831382650469004, 0.2925759964423417, 0.3022103426617781, 0.3120356648247044,
    0.3220465837491922, 0.33223788685100025, 0.3426044433653237, 0.3531411276066869,
    0.3638427499306224, 0.37470399503605095, 0.38571936724042893, 0.3968831423637421,
    0.40818932586744244, 0.4196316169078957, 0.4312033779782517, 0.4428976098271476,
    0.4547069313563363, 0.466623564212166, 0.4786393217972967, 0.4907456024396847,
    0.5029333864653684, 0.5151932369302423, 0.5275153037741458, 0.5398893311679158,
    0.5523046678310863, 0.5647502801046486, 0.5772147675695555, 0.5896863810078946,
    0.6021530425096346, 0.6146023675337109, 0.6270216887378847, 0.6393980813975257,
    0.6517183902388721, 0.6639692575177135, 0.6761371521797925, 0.6882083999443545,
    0.7001692141572563, 0.7120057272651854, 0.7237040227672694, 0.7352501675051564,
    0.7466302441574343, 0.7578303838087984, 0.7688367984689527, 0.7796358134208897,
    0.7902138992825294, 0.8005577036702928, 0.8106540823576901, 0.8204901298264551,
    0.8300532091123035, 0.8393309808520233, 0.8483114314431756, 0.8569829002323646,
    0.8653341056529117, 0.8733541702374209, 0.8810326444358363, 0.8883595291744836,
    0.8953252970967238, 0.901920912431064, 0.908137849437827, 0.9139681093908272,
    0.9194042360560392, 0.9244393296346629, 0.9290670591436518, 0.933281673212431,
    0.9370780092802006, 0.94045150118399, 0.9433981851334051, 0.9459147040737745,
    0.9479983104452054, 0.9496468673508115, 0.9508588481531243, 0.95163333452337,
    0.9519700129739281, 0.9518691699098267, 0.9513316852405483, 0.9503590245987661,
    0.9489532302177919, 0.947116910524557, 0.9448532285098064, 0.9421658889418334,
    0.9390591244945896, 0.9355376808651815, 0.9316068009598202, 0.927272208231016,
    0.9225400892522816, 0.9174170756198086, 0.9119102252735236, 0.9060270033324848,
    0.8997752625418933, 0.893163223431026, 0.8861994542829483, 0.878892851018309,
    0.8712526170964955, 0.8632882435380642, 0.855009489172899, 0.8464263612184604,
    0.8375490962924048
  ],
  RR: [
    -0.0, 6.176672417133061e-5, 0.0002470676877348783, 0.0005559052774256354, 0.0009882835165410887,
    0.0015442081334663383, 0.002223686658432365, 0.003026728549330948, 0.003953345347626504,
    0.005003550865620828, 0.00617736140653523, 0.007474796019057954, 0.00889587678818024,
    0.010440629164295587, 0.012109082332652347, 0.013901269625351069, 0.01581722897811435,
    0.01785700343406953, 0.020020641696719892, 0.022308198734157188, 0.02471973643635591,
    0.027255324327082817, 0.02991504033152846, 0.032698971600200626, 0.035607215388893144,
    0.03863987999362088, 0.04179708573826187, 0.0450789660112357, 0.04848566834581484,
    0.0520173555365802, 0.055674206782006325, 0.05945641884014211, 0.06336420718075467,
    0.06739780711301009, 0.0715574748626874, 0.07584348856690834, 0.080256149147268,
    0.08479578101388463, 0.0894627325430401, 0.09425737625951093, 0.09918010864108974,
    0.1042313494468448, 0.10941154045195164, 0.11472114345000177, 0.12016063735799894,
    0.12573051422917733, 0.13143127394358922, 0.1372634173052711, 0.14322743722675627,
    0.1493238076256691, 0.15555296959288106, 0.1619153143158905, 0.16841116215322385,
    0.17504073715419205, 0.1818041362017176, 0.18870129182268114, 0.19573192755911023,
    0.20289550462387115, 0.21019115837654404, 0.21761762295058912, 0.2251731421457795,
    0.23285536447763108, 0.24066122006069257, 0.2485867768146119, 0.25662707335032925,
    0.26477592586131943, 0.273025706471973, 0.2813670908649862, 0.28978877373283407,
    0.29827715181820147, 0.30681597620246137, 0.3153859782794749, 0.3239644777431491,
    0.33252498614164305, 0.3410368262685699, 0.3494647958870936, 0.3577689137638928,
    0.365904296046829, 0.3738212203680776, 0.3814654416562369, 0.3887788246760857,
    0.3957003503914261, 0.4021675329923209, 0.40811824953975795, 0.41349293484976923,
    0.4182370345922729, 0.42230354847150736, 0.4256554457064055, 0.4282677111902404,
    0.4301287941102418, 0.4312412852645107, 0.43162173807876064, 0.4312996547130425,
    0.4303157605612768, 0.4287197671786323, 0.4265678621195801, 0.42392016190541454,
    0.42083832868286825, 0.41738349525167656, 0.41361458125763756, 0.40958702698596383,
    0.4053519273174823, 0.4009555194531152, 0.3964389629940542, 0.39183834700681763,
    0.38718486232420213, 0.38250508532183564, 0.3778213292451921, 0.3731520291155525,
    0.36851213528979915, 0.3639134983974382, 0.35936523449845936, 0.3548740639815861,
    0.350444621145025, 0.3460797337987066, 0.34178067382099303, 0.3375473805910116,
    0.33337865976347, 0.3292723600853411, 0.32522553097190293, 0.3212345634365037,
    0.3172953167570513, 0.3134032330000893, 0.30955344123639394, 0.3057408529881878,
    0.30196025015940087, 0.2982063664252847, 0.29447396280186183, 0.2907578978834949,
    0.2870531930314855, 0.28335509262070685, 0.2796591193068153, 0.2759611241651134,
    0.27225733147445846, 0.2685443778758011, 0.26481934562414317, 0.2610797896730492,
    0.25732375837939814, 0.25354980768887403, 0.24975700875486065, 0.2459449490493517,
    0.24211372713818105, 0.2382639414081011, 0.23439667314405882, 0.23051346445595522,
    0.2266162916406081, 0.22270753463296059, 0.21878994324838175, 0.2148666009440139,
    0.21094088683157236, 0.2070164366579013, 0.20309710343502094, 0.199186918351072,
    0.19529005253072926, 0.19141078014173876, 0.18755344326669057, 0.18372241887925664,
    0.17992208818482291, 0.1761568085092647, 0.17243088784855928, 0.16874856212751937,
    0.16511397515918413, 0.1615311612478576, 0.15800403033862376, 0.15453635558418796,
    0.15113176317566918, 0.1477937242668732, 0.14452554881084442, 0.14133038112230448,
    0.13821119697910528, 0.1351708020791887, 0.1322118316760196, 0.1293367512242717,
    0.12654785787809167, 0.1238472826959642, 0.1212369934185916, 0.11871879769886763,
    0.1162943466756773, 0.11396513879562187, 0.11173252379868238, 0.10959770679514645,
    0.10756175237176309, 0.10562558867499215, 0.10379001142836931, 0.10205568784941797,
    0.100423160439223, 0.09889285062477819, 0.0974650622405802, 0.09613998484169631,
    0.0949176968457679, 0.09379816850614772, 0.0927812647226757, 0.09186674770052795,
    0.09105427947115483, 0.09034342429261688, 0.08973365094964549, 0.08922433497654414,
    0.08881476082860945, 0.08850412403011816, 0.08829153332907949, 0.08817601289091986,
    0.08815650456499953, 0.08823187025938053, 0.08840089446050423, 0.0886622869353951,
    0.08901468565462557, 0.08945665997449495, 0.08998671411668019, 0.09060329098289223,
    0.0913047763408122, 0.09208950341567856, 0.09295575791931956, 0.09390178354510101,
    0.09492578795312373, 0.09602594926505648, 0.09720042308212927, 0.0984473500331095,
    0.09976486385145429, 0.10115109997239077, 0.10260420463140356, 0.10412234443564629,
    0.10570371636920917, 0.10734655818215819, 0.10904915910193788, 0.11080987079435692,
    0.11262711849014088, 0.11449941218220976, 0.11642535778868437, 0.11840366816739999,
    0.1204331738596679, 0.12251283343444115, 0.12464174329909235, 0.126819146839889,
    0.12904444275408186, 0.13131719243633141, 0.1336371262849989, 0.1360041487985074,
    0.13841834233835376, 0.14087996944315137, 0.14338947358699322, 0.14594747828495336,
    0.1485547844582866, 0.151212365981216, 0.15392136333958925, 0.15668307533850398,
    0.1594989488006306, 0.16237056619879855, 0.16529963116489463, 0.1682879518116686,
    0.1713374217942092, 0.1744499990232351, 0.1776276819225447, 0.18087248309787482,
    0.1841864002538009, 0.18757138415928698, 0.191029303421253, 0.19456190577947904,
    0.1981707755860517, 0.20185728707949446, 0.20562255300923088, 0.2094673681124248,
    0.2133921468955418, 0.217396855131422, 0.22148093445482622, 0.22564321943275426,
    0.22988184650995594, 0.23419415429739035, 0.23857657479746502, 0.24302451536400957,
    0.24753223150033013, 0.252092691032435, 0.2566974307872482, 0.26133640768962196,
    0.26599784719886954, 0.27066809326135627, 0.27533146547391374, 0.2799701309244499,
    0.28456400015649075, 0.2890906587971532, 0.29352534842813366, 0.2978410120170359,
    0.3020084203186981, 0.3059963956724607, 0.30977214807170983, 0.31330173477935724,
    0.31655064872111954, 0.3194845322556663, 0.3220700019274612, 0.32427555719556317,
    0.3260725332073779, 0.3274360462543042, 0.3283458726725975, 0.32878719957651625,
    0.3287511902672308, 0.3282353187457556, 0.327243445482662, 0.3257856282510046,
    0.3238776843739081, 0.3215405409447849, 0.3187994247166033, 0.31568295174215555,
    0.31222217808754393, 0.30844966784971173, 0.30439862495530556, 0.3001021228556692,
    0.29559245324803574, 0.290900602943233, 0.2860558580154705, 0.2810855269066994,
    0.2760147692551924, 0.27086651459915834, 0.2656614543248196, 0.2604180907861965,
    0.25515282895268276, 0.24988009784764612, 0.2446124911267322, 0.23936091819889493,
    0.23413475918483376, 0.2289420186659569, 0.22378947457575557, 0.21868281972731668,
    0.21362679437599028, 0.20862530891436523, 0.2036815563200491, 0.1987981143571936,
    0.19397703779932313, 0.18921994111905568, 0.1845280722011573, 0.1799023776962686,
    0.17534356065751888, 0.1708521311018496, 0.1664284501204581, 0.16207276813451235,
    0.15778525785772984, 0.1535660424897055, 0.14941521962537177, 0.14533288132804337,
    0.1413191307771411, 0.13737409586743965, 0.13349794010473615, 0.12969087111330596,
    0.12595314704330063, 0.12228508114120282, 0.11868704472341528, 0.11515946877184755,
    0.11170284435071333, 0.10831772202559885, 0.1050047104489336, 0.10176447426024549,
    0.09859773143484857, 0.09550525020083957, 0.09248784563136328, 0.08954637600704958,
    0.086681739032208, 0.08389486797785317, 0.0811867278148227, 0.07855831139116881,
    0.07601063569962897, 0.07354473827328538, 0.07116167374050324, 0.06886251056387989,
    0.06664832798219433, 0.06452021316923726, 0.06247925861887063, 0.06052655976167744,
    0.05866321281513182, 0.05689031286625171, 0.05520895218320654, 0.05362021875028691,
    0.05212519501895384, 0.05072495686636493, 0.04942057275177222, 0.048213103060450714,
    0.04710359962436392, 0.04609310540851686, 0.04518265435189954, 0.044373271352036454,
    0.04366597238241626, 0.04306176473245091, 0.042561647360093866, 0.04216661134780061,
    0.041877640453143315, 0.04169571174606706, 0.041621796325492164, 0.041656860108716345,
    0.04180186468784017, 0.042057768248229696, 0.04242552654482269, 0.042906093932899445,
    0.043500424450742585, 0.04420947295243156, 0.04503419628983777, 0.04597555454370675,
    0.04703451230455469, 0.04821204000493915, 0.049509115305529386, 0.05092672453826662,
    0.052465864210802786, 0.05412754257733187, 0.05591278128188097, 0.05782261708113089,
    0.059858103654879605, 0.062020313513372206, 0.06431034001188739, 0.06672929948422515,
    0.06927833350806992, 0.07195861131665096, 0.07477133237265769, 0.07771772912206804,
    0.08079906994734662, 0.08401666234147816, 0.08737185632646258, 0.09086604814226347,
    0.09450068423482973, 0.09827726557464662, 0.10219735234044418, 0.10626256900616134,
    0.11047460987311247, 0.11483524509355884, 0.11934632723661183, 0.12400979845264522,
    0.1288276982982523, 0.1338021722902927, 0.13893548126488905, 0.14423001162541402,
    0.14968828657269523, 0.15531297842101904, 0.16110692211520103, 0.16707313007719543,
    0.1732148085257318, 0.17953537542948697, 0.1860384802737466, 0.19272802584270254,
    0.1996081922449573, 0.20668346343896551, 0.21395865654870513, 0.22143895429850124,
    0.2291299409405251, 0.23703764209998449, 0.24516856902255751, 0.2535297677774716,
    0.26212887404915897, 0.27097417424224046, 0.2800746737302389, 0.2894401731995682,
    0.29908135417827003, 0.30900987499466587, 0.3192384785841429, 0.3297811137507679,
    0.3406530716885345, 0.35187113976356843, 0.3634537747324671, 0.3754212976861929,
    0.3877961130005453, 0.40060295333699497, 0.41386915209512026, 0.4276249433772328,
    0.44190378700166477, 0.45674271158865465, 0.47218266089992794, 0.48826881519480586,
    0.5050508365925687, 0.5225829489094665, 0.5409236973045919, 0.5601351228287568,
    0.5802809002400304, 0.6014226716070653, 0.6236132776019264, 0.6468847124972719,
    0.6712272389550832, 0.6965540782017655, 0.7226437462052472, 0.7490512943464492,
    0.7749864915248074, 0.7991852275721748, 0.8198671089281087, 0.8349552909432058,
    0.8426900029716428, 0.842415003061436, 0.8349278991927115, 0.8220567987794127,
    0.8058666877930069, 0.7880849941955684, 0.7699179617115676, 0.7521089421037397,
    0.7350689780978432, 0.7189955003361054, 0.7039573737188195, 0.6899496519780028,
    0.6769268972724655, 0.6648227712701397, 0.6535612493964387, 0.6430628550165781,
    0.6332479834630979, 0.624038555136989, 0.6153587356398454, 0.6071351633022697,
    0.599296948675328, 0.5917756062853352, 0.5845050161061038, 0.5774214728809288,
    0.5704638550209065, 0.56357392511151, 0.5566967576308528, 0.5497812747229188,
    0.5427808574850602, 0.5356539888539437, 0.5283648760073394, 0.5208839966026435,
    0.5131885152376107, 0.5052625246573966, 0.4970970799085957, 0.4886900113208905,
    0.4800455215679519, 0.47117359046830903, 0.4620892261840446, 0.45281161129908587,
    0.4433631961340606, 0.4337687898306781, 0.4240546932972418, 0.41424790862011024,
    0.4043754487022343, 0.39446376018412443, 0.38453826324275, 0.3746230043061325,
    0.3647404123043538, 0.3549111457185774, 0.3451540160995413, 0.3354859735185156,
    0.325922140184128, 0.31647587984362635, 0.30715889228881693, 0.2979813240821747,
    0.2889518883541053, 0.2800779881038953, 0.2713658388158777, 0.2628205873628192,
    0.25444642511560295, 0.24624669393042792, 0.2382239842661177, 0.23038022512160772,
    0.22271676580281088, 0.2152344497525746, 0.20793368082790212, 0.20081448250217457,
    0.1938765505212057, 0.18711929956190285, 0.18054190444012072, 0.1741433363969061,
    0.1679223949651347, 0.16187773588530865, 0.1560078955030753, 0.1503113120437968,
    0.14478634412271607, 0.1394312868138677, 0.13424438556755325, 0.12922384823514427,
    0.12436785543149198, 0.11967456943927063, 0.11514214183612838, 0.11076872000441786,
    0.10655245266443747, 0.102491494555315, 0.09858401037276658, 0.09482817805974217,
    0.09122219153435165, 0.08776426292916961, 0.08445262440699433, 0.08128552961021449,
    0.07826125479397597, 0.0753780996872482, 0.0726343881205747, 0.0700284684546427,
    0.06755871383973806, 0.06522352233263642, 0.06302131689439294, 0.060950545289834,
    0.059009679907237426, 0.05719721751467908, 0.05551167896779912, 0.053951608882241694,
    0.052515575282742526, 0.05120216923972905, 0.050010004503346354, 0.048937717144020856,
    0.047983965207964836, 0.0471474283954297, 0.04642680776899863, 0.04582082549876164,
    0.04532822465081322, 0.04494776902516453, 0.04467824304882549, 0.044518451729507455,
    0.04446722067508472, 0.04452339618364624, 0.04468584540862886, 0.04495345660317598,
    0.04532513944745422, 0.0457998254622289, 0.04637646851146902, 0.04705404539618793,
    0.047831556541046705, 0.048708026774490994, 0.04968250620231677, 0.05075407117357226,
    0.05192182533657952, 0.053184900781588544, 0.05454245926515017, 0.05599369350970171,
    0.05753782857005895, 0.05917412325653822, 0.06090187160222481, 0.06272040435946537,
    0.06462909050797973, 0.06662733875403767, 0.06871459899689006, 0.07089036373509006,
    0.07315416938142504, 0.07550559745091533, 0.07794427558162992, 0.08046987834293491,
    0.08308212778016151, 0.08578079363845831, 0.0885656932018003, 0.0914366906756071,
    0.09439369603313016, 0.09743666323659125, 0.10056558773391558, 0.10378050312060115,
    0.10708147684375345, 0.11046860481138719, 0.11394200475457848, 0.1175018081728541,
    0.12114815067405096, 0.12488116049871155, 0.12870094499566762, 0.13260757478981833,
    0.13660106535504962, 0.14068135567498294, 0.14484828364188446, 0.1491015578100927,
    0.15344072508537682, 0.157865133896862, 0.16237389236515096, 0.1669658209512353,
    0.1716393990490413, 0.1763927049741493, 0.18122334880811033, 0.18612839758934854,
    0.19110429240734916, 0.1961467570686752, 0.20125069817615993, 0.20641009671408098,
    0.21161789158289154, 0.21686585600055686, 0.2221444683083469, 0.22744277951163236,
    0.23274828087141688, 0.23804677605251034, 0.2433222637261703, 0.24855683809117532,
    0.2537306164543148, 0.2588217046875461, 0.2638062128826962, 0.2686583346152611,
    0.2733505035974835, 0.2778536407844155, 0.28213750281323885, 0.2861711386661689,
    0.2899234554408373, 0.2933638861146242, 0.29646314256594786, 0.29919402664303185,
    0.30153226193974075, 0.30345730064936743, 0.30495305500975944, 0.3060085027855134,
    0.30661812173419584, 0.3067821189912319, 0.30650643672537486, 0.30580253335188967,
    0.3046869576237655, 0.3031807486123312, 0.3013087059715447, 0.29909858082419727,
    0.29658023795649424, 0.29378483548054585, 0.29074406004811254, 0.28748944565694445,
    0.28405179360007715, 0.2804607013884854, 0.2767442003386566, 0.2729284953540558,
    0.2690377962912239, 0.26509422799192733, 0.2611178052491743, 0.25712645927606625,
    0.2531361032959459, 0.2491607263563178, 0.24521250614139975, 0.24130193324198862,
    0.23743794092058748, 0.23362803581763078, 0.2298784262511876, 0.22619414576294944,
    0.22257917036890001, 0.2190365286039741, 0.21556840393057647, 0.21217622943554834,
    0.20886077499264874, 0.20562222723857992, 0.20246026281802532, 0.19937411541211056,
    0.19636263708735707, 0.19342435449837184, 0.19055752045492463, 0.1877601613286845,
    0.18503012073144312, 0.18236509984845833, 0.17976269476046547, 0.17722043103783883,
    0.1747357958418493, 0.17230626772211344, 0.1699293442570453, 0.16760256764598455,
    0.16532354832817797, 0.16308998667534078, 0.1608996927811597, 0.15875060435309055,
    0.1566408026990263, 0.15456852679379884, 0.15253218540773503, 0.1505303672813893,
    0.1485618493365559, 0.14662560292332966, 0.14472079811561028, 0.14284680608248992,
    0.14100319957960428, 0.13918975162214806, 0.13740643241906147, 0.13565340466524647,
    0.13393101730489582, 0.1322397978936282, 0.13058044369955055, 0.12895381169332995,
    0.1273609075845847, 0.1258028740662095, 0.12428097842967417, 0.12279659971290188,
    0.12135121553821804, 0.11994638879131667, 0.11858375428353413, 0.11726500552923962,
    0.11599188175829636, 0.11476615527064876, 0.11358961922654305, 0.1124640759520528,
    0.11139132582577914, 0.11037315679913255, 0.10941133458973368, 0.10850759357536603,
    0.10766362840480748, 0.10688108633178799, 0.1061615602694285, 0.10550658255478715,
    0.10491761940660167, 0.1043960660539603, 0.10394324250938179, 0.10356038995658662,
    0.10324866772103254, 0.10300915078993889, 0.10284282784798504, 0.10275059979501992,
    0.10273327871285838, 0.10279158724949791, 0.10292615839074963, 0.10313753559128273,
    0.10342617323932679, 0.10379243743171035, 0.10423660703847634, 0.10475887503891244,
    0.10535935011346442, 0.10603805847858111, 0.10679494595403476, 0.1076298802546515,
    0.10854265350062159, 0.10953298494261358, 0.1106005238997775, 0.11174485291034883,
    0.1129654910959404, 0.11426189774173741, 0.11563347609563204, 0.11707957738988412,
    0.11859950508911299, 0.1201925193683431, 0.1218578418244177, 0.12359466042333832,
    0.1254021346850226, 0.12727940110558314, 0.1292255788154841, 0.13123977546993676,
    0.13332109336555892, 0.1354686357747498, 0.13768151348641577, 0.13995885153867346,
    0.14229979612596086, 0.14470352165974018, 0.14716923795859083, 0.1496961975401979,
    0.1522837029844274, 0.15493111433355894, 0.15763785649276812, 0.16040342659125872,
    0.16322740126206076, 0.16610944379647075, 0.169049311127519, 0.17204686059567872,
    0.17510205644935292, 0.17821497603243, 0.18138581561145944, 0.18461489579565463,
    0.18790266650393603, 0.19124971143453792, 0.19465675199418395, 0.19812465064528617,
    0.20165441363099215, 0.20524719303892539, 0.20890428816481618, 0.212627146136846,
    0.216417361759911, 0.22027667653586516, 0.2242069768108693, 0.2282102909935305,
    0.2322887857773365, 0.23644476128720668, 0.2406806450521671, 0.24499898468354672,
    0.24940243910955875, 0.2538937681818611, 0.25847582042637784, 0.26315151865767133,
    0.26792384311211553, 0.2727958116778096, 0.2777704567061454, 0.28285079777887523,
    0.2880398096718079, 0.2933403845985979, 0.29875528763158343, 0.3042871039766467,
    0.3099381765215888, 0.3157105317778897, 0.32160579199062594, 0.32762507079876174,
    0.3337688493887978, 0.3400368296051088, 0.34642775997431946, 0.3529392300950087,
    0.3595674283837034, 0.36630685782561534, 0.3731500042648491, 0.38008695204778353,
    0.387104942737723, 0.3941878744694408, 0.4013157427321638, 0.4084640284885246,
    0.4156030471737165, 0.42269728291480924, 0.42970474679202353, 0.436576416293334,
    0.4432558346977294, 0.4496789720794474, 0.4557744701812696, 0.4614644054233563,
    0.4666656993386079, 0.4712922740799807, 0.47525798410423103, 0.4784802512440256,
    0.48088419747354005, 0.4824069302995198, 0.4830015252041963, 0.48264020743130437,
    0.4813162905248839, 0.479044584502971, 0.47586021271849793, 0.4718160181296704,
    0.46697893585257944, 0.4614258153586549, 0.45523917943231185, 0.4485033257137507,
    0.4413010466183981, 0.4337111035892587, 0.42580647148369816, 0.417653283598597,
    0.40931035969210966, 0.40082918237845483, 0.3922541920533964, 0.38362328776591154,
    0.37496844384233763, 0.36631637468637285, 0.3576892002760757, 0.3491050812995185,
    0.34057880545956626, 0.3321223156204823, 0.3237451767723049, 0.3154549828948972,
    0.307257707289974, 0.2991580012924962, 0.29115944684249806, 0.28326476846822024,
    0.2754760099971695, 0.2677946809083842, 0.26022187675643554, 0.2527583775929598,
    0.2454047278200743, 0.23816130045119316, 0.23102834833838226, 0.2240060445549056,
    0.21709451379613154, 0.21029385637917716, 0.20360416617744906, 0.19702554361657756,
    0.19055810467876688, 0.18420198670957946, 0.17795735169073626, 0.1718243875316922,
    0.16580330783863675, 0.15989435053965928, 0.15409777567724836, 0.14841386262206172,
    0.14284290691356183, 0.137385216892467, 0.13204111025573567, 0.12681091063617178,
    0.121694944284849, 0.11669353691474722, 0.1118070107475885, 0.10703568179249075,
    0.10237985737415935, 0.09783983391954938, 0.09341589500499027, 0.0891083096603613,
    0.08491733092268403, 0.0808431946284572, 0.07688611843184417, 0.07304630103431115,
    0.06932392161046123, 0.06571913941440091, 0.062232093550910104, 0.05886290289602008,
    0.055611666152075336, 0.05247846202303327, 0.04946334949657856, 0.0465663682205154,
    0.04378753896182817, 0.0411268641377878, 0.038584328409459794, 0.03615989932889886,
    0.03385352803231122, 0.03166514997231506, 0.029594685683330662, 0.02764204157494033,
    0.0258071107488215, 0.02408977383558204, 0.02248989984850045, 0.021007347051762593,
    0.019641963841381966, 0.018393589637483082, 0.01726205578709114, 0.01624718647699682,
    0.015348799656627575, 0.014566707971180433, 0.013900719705556985, 0.013350639739874843,
    0.012916270517529955, 0.01259741302693628, 0.012393867798188107, 0.012305435915960294,
    0.012331920049993865, 0.012473125504508312, 0.012728861287821223, 0.013098941203360222,
    0.013583184963101161, 0.01418141932426496, 0.014893479249855048, 0.01571920909329028,
    0.016658463807021993, 0.017711110174558255, 0.018877028064791735, 0.020156111706911447,
    0.021548270983462733, 0.023053432738287142, 0.02467154209514386, 0.02640256378172921,
    0.028246483452564957, 0.03020330900284973, 0.03227307186374058, 0.034455828267721776,
    0.03675166047066442, 0.03916067791478227, 0.04168301831405607, 0.044318848640612814
  ]
}

export const loschmidtEchoDataXXZ = {
  LE: [
    0.9999999999999993, 0.9999625003385395, 0.9998500054165783, 0.9996625274208775,
    0.999400086661066, 0.9990627115671797, 0.9986504386862167, 0.9981633126777102,
    0.9976013863083167, 0.9969647204454253, 0.9962533840497816, 0.9954674541671348,
    0.9946070159189028, 0.9936721624918611, 0.9926629951268522, 0.9915796231065233,
    0.9904221637420852, 0.989190742359103, 0.9878854922823134, 0.9865065548194754,
    0.9850540792442518, 0.9835282227781289, 0.9819291505713713, 0.9802570356830184,
    0.9785120590599231, 0.976694409514834, 0.9748042837035285, 0.9728418861009932,
    0.9708074289766605, 0.9687011323687016, 0.9665232240573758, 0.9642739395374488,
    0.9619535219896734, 0.9595622222513422, 0.9571002987859131, 0.9545680176517124,
    0.9519656524697211, 0.9492934843904439, 0.9465518020598714, 0.9437409015845314,
    0.9408610864956425, 0.9379126677123697, 0.9348959635041834, 0.9318112994523359,
    0.9286590084104508, 0.9254394304642335, 0.9221529128903126, 0.9187998101142067,
    0.9153804836674331, 0.911895302143757, 0.9083446411545867, 0.9047288832835257,
    0.9010484180400793, 0.8973036418125283, 0.8934949578199743, 0.8896227760635567,
    0.8856875132768576, 0.8816895928754913, 0.8776294449058913, 0.8735075059932956,
    0.8693242192889433, 0.8650800344164808, 0.8607754074175945, 0.8564108006968663,
    0.8519866829658649, 0.8475035291864803, 0.8429618205135047, 0.8383620442364693,
    0.8337046937207447, 0.828990268347911, 0.8242192734554041, 0.8193922202754501,
    0.8145096258732886, 0.8095720130846988, 0.8045799104528328, 0.7995338521643642,
    0.794434377984961, 0.7892820331940914, 0.7840773685191659, 0.7788209400690329,
    0.7735133092668243, 0.7681550427821716, 0.7627467124627907, 0.7572888952654502,
    0.7517821731863304, 0.7462271331907786, 0.7406243671424747, 0.7349744717320111,
    0.7292780484048988, 0.7235357032890066, 0.7177480471214435, 0.7119156951748946,
    0.7060392671834168, 0.7001193872677051, 0.6941566838598391, 0.6881517896275188,
    0.682105341397798, 0.6760179800803261, 0.6698903505901084, 0.663723101769791,
    0.6575168863114855, 0.651272360678137, 0.6449901850244506, 0.6386710231173829,
    0.6323155422562093, 0.6259244131921773, 0.619498310047754, 0.613037910235484,
    0.6065438943764557, 0.6000169462184002, 0.5934577525534229, 0.5868670031353823,
    0.5802453905969246, 0.573593610366185, 0.5669123605831687, 0.5602023420158163,
    0.553464257975767, 0.546698814233833, 0.5399067189351909, 0.5330886825143019,
    0.5262454176095727, 0.519377638977768, 0.5124860634081813, 0.5055714096365795,
    0.49863439825893074, 0.49167575164492144, 0.48469619385128215, 0.47769645053492443,
    0.47067724886590373, 0.46363931744021986, 0.4565833861924624, 0.4495101863083152,
    0.44242045013692904, 0.4353149111031735, 0.42819430361977956, 0.421059362999383,
    0.4139108253664806, 0.4067494275693081, 0.3995759070916538, 0.3923910019646152,
    0.38519545067831257, 0.37798999209356976, 0.3707753653535707, 0.36355230979550607,
    0.35632156486221983, 0.3490838700138644, 0.34183996463958033, 0.33459058796920355,
    0.3273364789850215, 0.3200783763335796, 0.3128170182375541, 0.3055531424077012,
    0.2982874859548927, 0.2910207853022498, 0.2837537760973857, 0.2764871931247672,
    0.26922177021820753, 0.2619582401735, 0.2546973346612033, 0.24743978413959095,
    0.24018631776777305, 0.23293766331900292, 0.22569454709417944, 0.21845769383555386,
    0.21122782664065473, 0.20400566687643865, 0.1967919340936791, 0.18958734594160462,
    0.1823926180827934, 0.17520846410834076, 0.16803559545330385, 0.16087472131243716,
    0.1537265485562301, 0.14659178164725392, 0.1394711225568318, 0.13236527068203946,
    0.12527492276304858, 0.11820077280082227, 0.1111435119751726, 0.10410382856319139,
    0.09708240785806319, 0.09007993208827045, 0.08309708033720271, 0.07613452846317662,
    0.06919294901987981, 0.06227301117724633, 0.05537538064277422, 0.04850071958329452,
    0.041649686547202476, 0.03482293638715875, 0.0280211201832719, 0.02124488516677071,
    0.014494874644175513, 0.0077717279219783495, 0.0010760802318418874, 0.005591437343675082,
    0.012230197944855598, 0.018839579008017127, 0.025418962338148623, 0.03196773418098593,
    0.03848528529451311, 0.04497101101988359, 0.0514243113517501, 0.05784459100799932,
    0.06423125949887415, 0.07058373119548526, 0.07690142539769655, 0.08318376640137627,
    0.08943018356501219, 0.09564011137567197, 0.10181298951431195, 0.1079482629204171,
    0.11404538185596982, 0.12010380196873693, 0.1261229843548699, 0.13210239562080503,
    0.1380415079444647, 0.14393979913574237, 0.14979675269627207, 0.15561185787846996,
    0.16138460974384383, 0.16711450922055981, 0.17280106316026494, 0.17844378439415026,
    0.18404219178825834, 0.18959581029801637, 0.19510417102200012, 0.20056681125491135,
    0.2059832745397719, 0.21135311071931848, 0.2166758759866019, 0.22195113293477514,
    0.22717845060607125, 0.23235740453996184, 0.23748757682048852, 0.2425685561227686,
    0.24759993775865902, 0.25258132372158276, 0.25751232273050545, 0.2623925502730615,
    0.2672216286478199, 0.27199918700568965, 0.27672486139045466, 0.2813982947784386,
    0.28601913711728877, 0.29058704536388036, 0.29510168352133204, 0.2995627226751304,
    0.3039698410283588, 0.3083227239360258, 0.31262106393848976, 0.31686456079397446,
    0.32105292151017223, 0.3251858603749332, 0.3292630989860314, 0.33328436628001123,
    0.3372493985601038, 0.34115793952321566, 0.3450097402859843, 0.3488045594098934,
    0.3525421629254548, 0.35622232435544254, 0.35984482473718593, 0.363409452643912,
    0.3669160042051399, 0.37036428312612185, 0.37375410070632886, 0.37708527585697954,
    0.3803576351176112, 0.38357101267168786, 0.386725250361247, 0.3898201977005814,
    0.39285571188895474, 0.39583165782234947, 0.3987479081042478, 0.4016043430554393,
    0.4044008507228617, 0.4071373268874652, 0.40981367507110755, 0.4124298065424745,
    0.4149856403220247, 0.4174811031859622, 0.41991612966923286, 0.42229066206754534,
    0.424604650438418, 0.42685805260124904, 0.42905083413641315, 0.43118296838338255,
    0.4332544364378728, 0.4352652271480172, 0.43721533710956406, 0.43910477066010667,
    0.44093353987233536, 0.4427016645463245, 0.44440917220084725, 0.44605609806372165,
    0.4476424850611915, 0.4491683838063404, 0.4506338525865426, 0.4520389573499518,
    0.4533837716910288, 0.4546683768351129, 0.4558928616220337, 0.4570573224887732,
    0.45816186345117166, 0.4592065960846886, 0.4601916395042133, 0.4611171203429337,
    0.46198317273026346, 0.46278993826883047, 0.46353756601053026, 0.4642262124316475,
    0.46485604140704795, 0.4654272241834457, 0.4659399393517487, 0.4663943728184852,
    0.4667907177763151, 0.4671291746736322, 0.46740995118325773, 0.4676332621702334,
    0.46779932965871257, 0.46790838279796115, 0.46796065782746493, 0.46795639804115446,
    0.46789585375074655, 0.4677792822482128, 0.46760694776737377, 0.46737912144462973,
    0.4670960812788283, 0.46675811209027773, 0.4663655054789079, 0.4659185597815876,
    0.46541758002860034, 0.4648628778992881, 0.46425477167686474, 0.4635935862024072,
    0.46287965282803056, 0.46211330936925116, 0.46129490005654666, 0.46042477548611616,
    0.45950329256984906, 0.4585308144845069, 0.4575077106201286, 0.4564343565276596,
    0.45531113386581884, 0.45413843034720247, 0.45291663968363943, 0.4516461615307989,
    0.45032740143206074, 0.448960770761654, 0.44754668666707453, 0.4460855720107814,
    0.4445778553111888, 0.44302397068295446, 0.4414243577765744, 0.43977946171729165,
    0.43808973304332705, 0.43635562764343927, 0.4345776066938226, 0.4327561365943502,
    0.4308916889041704, 0.428984740276666, 0.42703577239378326, 0.4250452718997395,
    0.4230137303341168, 0.42094164406435325, 0.41882951421763565, 0.41667784661220736,
    0.4144871516880955, 0.41225794443727143, 0.40999074433324556, 0.4076860752601161,
    0.4053444654410703, 0.4029664473663542, 0.4005525577207174, 0.39810333731034236,
    0.3956193309892668, 0.3931010875853112, 0.3905491598255165, 0.3879641042611062,
    0.38534648119197873, 0.3826968545907414, 0.3800157920262953, 0.3773038645869811,
    0.37456164680329396, 0.3717897165701794, 0.3689886550689208, 0.3661590466886229,
    0.36330147894730924, 0.3604165424126361, 0.3575048306222398, 0.3545669400037202,
    0.3516034697942782, 0.3486150219600099, 0.34560220111487494, 0.3425656144393429,
    0.3395058715987326, 0.33642358466125155, 0.33331936801574946, 0.3301938382891915,
    0.32704761426386647, 0.3238813167943365, 0.32069556872414223, 0.31749099480227283,
    0.314268221599407, 0.31102787742394494, 0.3077705922378324, 0.3044969975721945,
    0.3012077264427849, 0.2979034132652638, 0.2945846937703167, 0.2912522049186198,
    0.28790658481566966, 0.2845484726264802, 0.28117850849016457, 0.2777973334344087,
    0.2744055892898506, 0.27100391860437034, 0.2675929645573097, 0.2641733708736243,
    0.2607457817379879, 0.25731084170884777, 0.25386919563245636, 0.25042148855687674,
    0.24696836564598296, 0.24351047209345972, 0.24004845303681452, 0.23658295347141425,
    0.233114618164556, 0.22964409156958118, 0.2261720177400474, 0.2226990402439664,
    0.21922580207812056, 0.2157529455824663, 0.21228111235463737, 0.20881094316455998,
    0.2053430778691865, 0.2018781553273623, 0.19841681331483432, 0.1949596884394135,
    0.1915074160563013, 0.18806063018359112, 0.18461996341795314, 0.18118604685051795,
    0.17775950998296516, 0.17434098064383077, 0.17093108490504033, 0.16753044699868222,
    0.16413968923402908, 0.1607594319148212, 0.15739029325681428, 0.15403288930561412,
    0.15068783385479714, 0.14735573836433544, 0.14403721187933058, 0.14073286094907048,
    0.1374432895464186, 0.13416909898754517, 0.1309108878520089, 0.1276692519032039,
    0.12444478400917694, 0.12123807406382929, 0.11804970890850779, 0.11488027225400176,
    0.11173034460295068, 0.1086005031726727, 0.1054913218184285, 0.10240337095712165,
    0.0993372174914548, 0.09629342473454411, 0.09327255233500507, 0.09027515620251739,
    0.08730178843387904, 0.08435299723955948, 0.08142932687076175, 0.078531317546998,
    0.0756595053841954, 0.07281442232333518, 0.06999659605963666, 0.067206549972294,
    0.06444480305477578, 0.06171186984569564, 0.05900826036026309, 0.05633448002232066,
    0.053691029596981465, 0.05107840512386888, 0.04849709785097402, 0.04594759416913123,
    0.04343037554712818, 0.04094591846745308, 0.03849469436268826, 0.03607716955256124,
    0.0336938051816561, 0.03134505715779755, 0.029031376091113073, 0.026753207233782737,
    0.02451099042048074, 0.022305160009520854, 0.020136144824709476, 0.018004368097917126,
    0.015910247412370418, 0.013854194646678312, 0.011836615919594095, 0.009857911535524245,
    0.007918475930786706, 0.006018697620630424, 0.00415895914701883, 0.002339637027186182,
    0.0005611017029716228, 0.0011762825090612442, 0.002872157466713268, 0.00452617125044106,
    0.006137978210845637, 0.007707239015350101, 0.009233620694062535, 0.010716796684816933,
    0.012156446877387019, 0.013552257656868, 0.014903921946219196, 0.01621113924796361,
    0.017473615685037434, 0.018691064040787367, 0.019863203798106834, 0.020989761177708646,
    0.022070469175529007, 0.023105067599256852, 0.02409330310398575, 0.025034929226981893,
    0.025929706421564645, 0.02677740209009632, 0.02757779061607489, 0.02833065339532761,
    0.029035778866299385, 0.0296929625394349, 0.030302007025648003, 0.030862722063875633,
    0.0313749245477142, 0.03183843855113271, 0.03225309535326086, 0.032618733462248535,
    0.03293519863819357, 0.0332023439151348, 0.03342002962210802, 0.03358812340326148,
    0.033706500237028814, 0.03377504245435694, 0.033793639755986184, 0.033762189228781034,
    0.03368059536110854, 0.033548770057263685, 0.03336663265093786, 0.03313410991773149,
    0.032851136086706326, 0.0325176528509775, 0.03213360937734411, 0.03169896231495601,
    0.03121367580301648, 0.030677721477519765, 0.030091078477021988, 0.029453733447445254,
    0.028765680545913604, 0.028026921443621972, 0.027237465327734803, 0.026397328902316757,
    0.02550653638829456, 0.02456511952244883, 0.02357311755543771, 0.022530577248850613,
    0.02143755287129466, 0.020294106193511928, 0.019100306482529947, 0.0178562304948446,
    0.016561962468637326, 0.015217594115027554, 0.013823224608360543, 0.012378960575532331,
    0.010884916084353286, 0.009341212630951036, 0.007747979126216151, 0.006105351881288073,
    0.004413474592088683, 0.002672498322900643, 0.0008825814889954507, 0.0009561101616885637,
    0.0028434035678148568, 0.00477911837486139, 0.006763066956275803, 0.008795054435570547,
    0.010874878709354358, 0.013002330471297022, 0.015177193237026288, 0.01739924336995137,
    0.01966825010801244, 0.02198397559135077, 0.024346174890896303, 0.026754596037870223,
    0.029208980054198693, 0.03170906098383238, 0.034254565924970636, 0.036845215063181634,
    0.03948072170542223, 0.04216079231494403, 0.04488512654708835, 0.047653417285961834,
    0.050465350681990616, 0.05332060619034663, 0.05621885661024113, 0.05915976812508153,
    0.06214300034348785, 0.0651682063411593, 0.06823503270359135, 0.071343119569632,
    0.0744921006758785, 0.07768160340190272, 0.08091124881630406, 0.0841806517235799,
    0.0874894207118138, 0.09083715820116953, 0.09422346049318693, 0.09764791782087527,
    0.10111011439959511, 0.10460962847872518, 0.10814603239410428, 0.11171889262124476,
    0.11532776982930884, 0.11897221893584398, 0.12265178916226616, 0.12636602409008735,
    0.13011446171787863, 0.1338966345189642, 0.13771206949983497, 0.14156028825927902,
    0.14544080704821535, 0.14935313683023366, 0.15329678334282043, 0.15727124715927215,
    0.16127602375128455, 0.16531060355221203, 0.16937447202098707, 0.1734671097066922,
    0.17758799231377712, 0.1817365907679143, 0.1859123712824812, 0.19011479542566292,
    0.19434332018816808, 0.19859739805154528, 0.20287647705709821, 0.20718000087538374,
    0.2115074088762894, 0.215858136199677, 0.22023161382659026, 0.22462726865100935,
    0.22904452355215088, 0.23348279746729547, 0.23794150546514656, 0.2424200588196983,
    0.24691786508461003, 0.25143432816807637, 0.25596884840818723, 0.2605208226487606,
    0.265089644315645, 0.2696747034934804, 0.2742753870029072, 0.27889107847821576,
    0.28352115844542064, 0.28816500440075776, 0.2928219908895926, 0.2974914895857225,
    0.30217286937107496, 0.30686549641577976, 0.31156873425861464, 0.3162819438878096,
    0.32100448382219976, 0.3257357101927197, 0.33047497682422117, 0.3352216353176189,
    0.3399750351323358, 0.3447345236690515, 0.3494994463527362, 0.35426914671596405,
    0.35904296648249223, 0.363820245651095, 0.36860032257964515, 0.3733825340694323,
    0.37816621544970275, 0.38295070066241627, 0.38773532234720154, 0.39251941192651396,
    0.39730229969096426, 0.40208331488482657, 0.4068617857917033, 0.411637039820343,
    0.41640840359059333, 0.4211752030194863, 0.4259367634074391, 0.4306924095245609,
    0.4354414656970586, 0.4401832558937251, 0.44491710381250216, 0.4496423329671061,
    0.4543582667737084, 0.45906422863765556, 0.46375954204022046, 0.4684435306253709,
    0.4731155182865542, 0.4777748292534707, 0.48242078817884104, 0.4870527202251449,
    0.4916699511513306, 0.4962718073994729, 0.5008576161813779, 0.5054267055651198,
    0.5099784045615001, 0.5145120432104153, 0.5190269526671284, 0.5235224652884236,
    0.5279979147186471, 0.5324526359756084, 0.5368859655363427, 0.5412972414227168,
    0.54568580328687, 0.5500509924964826, 0.5543921522198553, 0.5587086275107895,
    0.5629997653932654, 0.567264914945897, 0.571503427386161, 0.575714656154384, 0.579897956997479,
    0.5840526880524268, 0.5881782099294774, 0.592273885795077, 0.5963390814544979,
    0.6003731654341706, 0.6043755090636992, 0.6083454865575537, 0.6122824750964312,
    0.6161858549082717, 0.6200550093489191, 0.6238893249824216, 0.6276881916609514,
    0.6314510026043476, 0.6351771544792599, 0.6388660474778879, 0.6425170853963094,
    0.6461296757123801, 0.6497032296632048, 0.6532371623221596, 0.656730892675463,
    0.6601838436982846, 0.6635954424303805, 0.6669651200512451, 0.6702923119547735,
    0.6735764578234197, 0.6768170017018486, 0.680013392070064, 0.6831650819160098,
    0.6862715288076322, 0.689332194964395, 0.6923465473282391, 0.6953140576339752,
    0.6982342024791034, 0.7011064633930489, 0.7039303269058077, 0.7067052846159906,
    0.7094308332582562, 0.7121064747701314, 0.7147317163582014, 0.7173060705636659,
    0.7198290553272553, 0.7223001940534929, 0.724719015674299, 0.7270850547119302,
    0.7293978513412401, 0.7316569514512585, 0.7338619067060808, 0.7360122746050587,
    0.738107618542283, 0.7401475078653543, 0.7421315179334331, 0.7440592301745608,
    0.7459302321422441, 0.7477441175712977, 0.7495004864329377, 0.7511989449891154,
    0.7528391058460907, 0.7544205880072311, 0.7559430169250366, 0.7574060245523794,
    0.7588092493929517, 0.7601523365509187, 0.7614349377797672, 0.7626567115303452,
    0.7638173229980864, 0.7649164441694101, 0.7659537538672986, 0.7669289377960375,
    0.7678416885851174, 0.7686917058322933, 0.7694786961457897, 0.7702023731856565,
    0.7708624577042565, 0.7714586775858917, 0.7719907678855602, 0.7724584708668303,
    0.7728615360388406, 0.7731997201924106, 0.7734727874352643, 0.7736805092263535,
    0.7738226644092865, 0.773899039244855, 0.7739094274426461, 0.7738536301917495,
    0.7737314561905461, 0.7735427216755774, 0.7732872504494901, 0.7729648739080576,
    0.7725754310662671, 0.7721187685834745, 0.7715947407876235, 0.7710032096985227,
    0.7703440450501788, 0.7696171243121868, 0.7688223327101679, 0.7679595632452583,
    0.767028716712641, 0.7660297017191229, 0.7649624346997543, 0.7638268399334833,
    0.7626228495578499, 0.7613504035827131, 0.7600094499030146, 0.7585999443105663,
    0.7571218505048757, 0.7555751401029939, 0.7539597926483924, 0.7522757956188644,
    0.7505231444334524, 0.7487018424583959, 0.7468119010121052, 0.7448533393691535,
    0.7428261847632928, 0.7407304723894899, 0.7385662454049801, 0.7363335549293437,
    0.7340324600436021, 0.7316630277883313, 0.7292253331607982, 0.7267194591111135,
    0.7241454965374099, 0.7215035442800347, 0.7187937091147704, 0.7160161057450735,
    0.713170856793337, 0.7102580927911757, 0.7072779521687419, 0.7042305812430601,
    0.7011161342053928, 0.6979347731076346, 0.6946866678477406, 0.691371996154178,
    0.6879909435694216, 0.6845437034324793, 0.681030476860459, 0.6774514727291695,
    0.6738069076527714, 0.6700970059624707, 0.6663219996842549, 0.6624821285156844,
    0.6585776398017372, 0.6546087885097019, 0.6505758372031408, 0.646479056014901,
    0.6423187226192039, 0.6380951222027934, 0.633808547435159, 0.6294592984378368,
    0.6250476827527849, 0.6205740153098437, 0.6160386183932839, 0.6114418216074456,
    0.6067839618414687, 0.6020653832331241, 0.5972864371317522, 0.5924474820603051,
    0.5875488836765009, 0.5825910147330997, 0.5775742550372971, 0.5724989914092498,
    0.5673656176397253, 0.562174534446898, 0.5569261494322826, 0.5516208770358147,
    0.5462591384900894, 0.5408413617737526, 0.5353679815640614, 0.5298394391886156,
    0.5242561825762588, 0.5186186662071697, 0.5129273510621357, 0.507182704571026,
    0.5013852005604617, 0.49553531920069216, 0.489633546951691, 0.48368037650846496,
    0.47767630674559736, 0.4716218426610149, 0.46551749531900627, 0.459363781792481,
    0.4531612251044853, 0.44691035416897973, 0.44061170373088626, 0.43426581430540695,
    0.42787323211663936, 0.42143450903546287, 0.4149502025167455, 0.40842087553583933,
    0.4018470965244027, 0.3952294393055412, 0.38856848302827307, 0.3818648121013391,
    0.3751190161263631, 0.36833168983035214, 0.3615034329975752, 0.3546348504008021,
    0.34772655173193057, 0.34077915153199445, 0.3337932691205719, 0.32676952852460023,
    0.31970855840660034, 0.3126109919923332, 0.3054774669978733, 0.2983086255561384,
    0.2911051141428605, 0.28386758350201635, 0.27659668857073, 0.26929308840364863,
    0.2619574460968043, 0.2545904287109811, 0.24719270719457143, 0.23976495630596265,
    0.23230785453543806, 0.2248220840266189, 0.2173083304974453, 0.20976728316071083,
    0.2021996346441635, 0.19460608091018083, 0.18698732117501882, 0.1793440578276628,
    0.1716769963482803, 0.16398684522628282, 0.15627431587801205, 0.1485401225640622,
    0.14078498230624192, 0.1330096148041878, 0.12521474235165408, 0.11740108975245651,
    0.10956938423611798, 0.10172035537319968, 0.09385473499034237, 0.08597325708502003,
    0.0780766577400202, 0.0701656750376626, 0.062241048973768705, 0.05430352137137512,
    0.046353835794233966, 0.038392737460080394, 0.03042097315369274, 0.02243929113975582,
    0.014448441075533294, 0.006449173923359002, 0.0015577581370267869, 0.009571601796315813,
    0.0175916027035995, 0.02561700555448117, 0.03364705417930527, 0.04168099163150428,
    0.049718060276047485, 0.057757501877977034, 0.0657985576910285, 0.07384046854631589,
    0.08188247494108239, 0.08992381712749073, 0.09796373520145556, 0.10600146919149687,
    0.11403625914761162, 0.12206734523014831, 0.13009396779867288, 0.1381153675008132,
    0.14613078536108473, 0.15413946286966657, 0.1621406420711254, 0.17013356565308657,
    0.17811747703482306, 0.18609162045576616, 0.19405524106391644, 0.20200758500415092,
    0.2099478995064218, 0.21787543297380996, 0.22578943507045834, 0.23368915680934413
  ],
  RR: [
    3.3306690738754706e-16, 0.000018750182295188427, 0.00007500291686710955, 0.00016876476790396815,
    0.00030004667947118694, 0.00046886398114439823, 0.0006752363959045273, 0.0009191880503025144,
    0.0012007474869074322, 0.0015199476790488196, 0.0018768260478728657, 0.0022714244817288102,
    0.002703789357909252, 0.003173971566767205, 0.003682026538239101, 0.004228014270800501,
    0.004811999362891566, 0.0054340510468440925, 0.006094243225351076, 0.006792654510519804,
    0.007529368265554258, 0.00830447264911334, 0.00911806066239834, 0.009970230199023279,
    0.010861084097726808, 0.011790730197989393, 0.01275928139861833, 0.013766855719374672,
    0.0148135763657128, 0.015899571796711785, 0.017024975796283662, 0.018189927547740504,
    0.019394571711817483, 0.020639058508246003, 0.021923543800980036, 0.023248189187182963,
    0.024613162090087497, 0.026018635855849254, 0.027464789854516763, 0.028951809585253396,
    0.030479886785945202, 0.03204921954734295, 0.033660012431892615, 0.03531247659741021,
    0.037006829925775316, 0.03874329715681851, 0.04052211002758713, 0.04234350741719123,
    0.044207735497429436, 0.04611504788941523, 0.04806570582643177, 0.050059978323250626,
    0.05209814235217214, 0.05418048302604683, 0.05630729378855865, 0.05847887661206506,
    0.06069554220329707, 0.06295761021724955, 0.06526540947959875, 0.06761927821801045,
    0.07001956430271389, 0.07246662549674532, 0.07496082971627605, 0.0775025553014743,
    0.08009219129836545, 0.08273013775218348, 0.08541680601273646, 0.08815261905233432,
    0.09093801179685927, 0.09377343147059203, 0.09665933795544253, 0.09959620416526781,
    0.10258451643600446, 0.10562477493237905, 0.10871749407200967, 0.11186320296775644,
    0.11506244588923152, 0.1183157827444315, 0.12162378958251858, 0.1249870591188286,
    0.12840620128326552, 0.13188184379329632, 0.13541463275285218, 0.1390052332785115,
    0.14265433015443432, 0.14636262851761186, 0.15013085457508976, 0.15395975635494089,
    0.15785010449287204, 0.16180269305647935, 0.16581834040929988, 0.16989789011695097,
    0.1740422118978102, 0.178252202620852, 0.18252878735344294, 0.18687292046209367,
    0.19128558676937826, 0.1957678027704626, 0.200320617912931, 0.20494511594387504,
    0.209642416328495, 0.21441367574478984, 0.2192600896592501, 0.22418289398885022,
    0.22918336685504115, 0.23426283043589233, 0.23942265292301637, 0.2446642505904373,
    0.24998908998315214, 0.2553986902337514, 0.2608946255161738, 0.2664785276464196,
    0.2721520888408816, 0.2779170646438689, 0.2837752770368967, 0.28972861774343334,
    0.29577905174399965, 0.3019286210178671, 0.3081794485290836, 0.31453374247619736,
    0.32099380082686163, 0.32756201616051916, 0.33424088084460496, 0.34103299257217967,
    0.34794106029168403, 0.35496791056258353, 0.3621164943741118, 0.36938989446818105,
    0.37679133321183333, 0.38432418106944555, 0.3919919657303423, 0.3997983819535861,
    0.4077473021986185, 0.4158427881182244, 0.42408910299911395, 0.4324907252454313,
    0.4410523630118687, 0.44977897010602436, 0.4586757632944186, 0.46774824116349756,
    0.47700220470633153, 0.48644377982799025, 0.4960794419882405, 0.5059160432298345,
    0.5159608418749777, 0.526221535212408, 0.5367062955439033, 0.5474238100132354,
    0.5583833247040408, 0.5695946935677075, 0.5810684328303264, 0.5928157816318608,
    0.6048487697743046, 0.6171802936029724, 0.6298242012214383, 0.6427953884526136,
    0.656109907214297, 0.6697850882877622, 0.6838396808359024, 0.6982940114902285,
    0.7131701663956962, 0.7284922003074541, 0.7442863777115625, 0.7605814520416752,
    0.7774089904508031, 0.7948037533588364, 0.8128041402501252, 0.8314527161014023,
    0.8507968365990988, 0.8708893952630621, 0.8917897221617752, 0.9135646726954849,
    0.9362899568224632, 0.9600517754029714, 0.984948852955318, 1.0110949879571822,
    1.0386222873378819, 1.067685317976745, 1.098466506034475, 1.1311832631465524, 1.166097547804128,
    1.2035289342478381, 1.243872856033825, 1.2876266960695095, 1.335428157163119,
    1.3881135772390019, 1.4468100883651593, 1.513088322189902, 1.5892307181375482,
    1.678739508861078, 1.7873983804413283, 1.9258195553092259, 2.1169800824286655,
    2.4286313777725956, 3.417215127556776, 2.593259448598411, 2.2019235721127575,
    1.9858976778651185, 1.8361299173702688, 1.721514095854957, 1.6287396554440026,
    1.5508685981778254, 1.4838221174226849, 1.4249976650038758, 1.3726326393526727,
    1.3254777986810207, 1.2826154334557744, 1.2433515327802638, 1.2071485150075185,
    1.1735814859312266, 1.1423087923166393, 1.1130516068353902, 1.0855794116735784,
    1.0596994468525154, 1.035248890882573, 1.012088966357197, 0.9901004284222829,
    0.9691800641877886, 0.9492379428644799, 0.930195231348153, 0.9119824412198909,
    0.8945380087918858, 0.8778071350416395, 0.8617408303652376, 0.8462951222310275,
    0.8314303934948364, 0.8171108263443604, 0.8033039322627616, 0.789980152521637,
    0.7771125168731801, 0.7646763505551548, 0.7526490216278348, 0.7410097221590062,
    0.729739277957595, 0.7188199824999089, 0.7082354514487066, 0.697970494775163, 0.688011003988292,
    0.678343852379637, 0.6689568065214446, 0.6598384475286558, 0.6509781008201385,
    0.6423657733016608, 0.6339920970491617, 0.625848278701673, 0.6179260538831977,
    0.6102176460657069, 0.6027157293640496, 0.5954133948204131, 0.5883041197929763,
    0.5813817401121508, 0.5746404247096428, 0.5680746524615559, 0.5616791910178165,
    0.5554490774170784, 0.5493796003095541, 0.5434662836305051, 0.5377048715847703,
    0.5320913148181574, 0.5266217576650352, 0.5212925263733066, 0.5161001182184012,
    0.5110411914270826, 0.5061125558400076, 0.5013111642491288, 0.49663410435240984,
    0.4920785912739609, 0.4876419606027279, 0.48332166190734055, 0.47911525268872557,
    0.4750203927356504, 0.47103483885156505, 0.467156439923973, 0.4633831323101315,
    0.45971293551519576, 0.45614394814100934, 0.4526743440856071, 0.449302368975212,
    0.44602633681201764, 0.44284462682245407, 0.4397556804918823, 0.4367579987728025,
    0.43385013945470474, 0.43103071468462506, 0.42829838862833336, 0.4256518752628603,
    0.42308993629178204, 0.42061137917533675, 0.41821505526804215, 0.41589985805702406,
    0.4136647214947772, 0.4115086184205199, 0.4094305590647499, 0.40742958963196535,
    0.40550479095689806, 0.4036552772299169, 0.40188019478756354, 0.40017872096446583,
    0.39855006300312484, 0.39699345701831124, 0.39550816701302627, 0.39409348394318144,
    0.3927487248283464, 0.391473231906075, 0.3902663718274996, 0.38912753489201063,
    0.38805613431900005, 0.38705160555475815, 0.38611340561274343, 0.38524101244555486,
    0.38443392434703827, 0.3836916593830566, 0.3830137548495469, 0.38239976675656506,
    0.3818492693371056, 0.38136185457955396, 0.38093713178269767, 0.3805747271322875,
    0.3802742832982037, 0.38003545905133235, 0.3798579288993209, 0.37974138274041835,
    0.3796855255346674, 0.37969007699174767, 0.37975477127482116, 0.37987935671976025,
    0.38006359556918834, 0.3803072637207845, 0.38061015048934926, 0.3809720583821507,
    0.3813928028871068, 0.38187221227338275, 0.38241012740401353, 0.3830064015601835,
    0.38366090027682287, 0.38437350118920194, 0.38514409389022597, 0.38597257979815713,
    0.38685887203450625, 0.3878028953118627, 0.38880458583144295, 0.3898638911901629,
    0.3909807702970481, 0.3921551932988256, 0.393387141514542, 0.39467660737908516,
    0.3960235943954852, 0.3974281170959014, 0.39889020101120587, 0.40040988264909483,
    0.4019872094806677, 0.4036222399354387, 0.4053150434047453, 0.4070657002535433,
    0.4088743018405893, 0.4107409505470221, 0.41266575981337095, 0.4146488541850339,
    0.4166903693662807, 0.4187904522828506, 0.4209492611532309, 0.4231669655687128,
    0.42544374658234035, 0.42777979680688194, 0.4301753205219694, 0.4326305337905627,
    0.43514566458492354, 0.43772095292228524, 0.44035665101043714, 0.44305302340344715,
    0.44581034716778006, 0.44862891205906896, 0.4515090207098388, 0.45445098882848606,
    0.4574551454098487, 0.4605218329577218, 0.4636514077196996, 0.46684423993474616,
    0.4701007140939321, 0.47342122921479207, 0.476806199129797, 0.4802560527894612,
    0.4837712345806394, 0.4873522046606018, 0.4909994393075168, 0.49471343128800366,
    0.4984946902424623, 0.5023437430889374, 0.5062611344463055, 0.5102474270776423,
    0.5143032023546613, 0.5184290607441917, 0.5226256223177002, 0.5268935272849498,
    0.5312334365529331, 0.5356460323113121, 0.5401320186456563, 0.5446921221798677,
    0.5493270927492606, 0.5540377041058676, 0.5588247546576384, 0.5636890682433139,
    0.5686314949448701, 0.5736529119395553, 0.5787542243936895, 0.5839363664005173,
    0.5892003019645924, 0.5945470260353174, 0.5999775655924648, 0.6054929807866872,
    0.6110943661382464, 0.6167828517974248, 0.6225596048703201, 0.628425830814014,
    0.6343827749053743, 0.6404317237880911, 0.6465740071028732, 0.6528109992061267,
    0.659144120982821, 0.6655748417597139, 0.6721046813255706, 0.6787352120655663,
    0.6854680612175987, 0.6923049132589103, 0.6992475124320678, 0.7062976654201325,
    0.7134572441816609, 0.7207281889570876, 0.7281125114590414, 0.7356122982602427,
    0.7432297143938246, 0.7509670071822673, 0.7588265103125879, 0.7668106481770662,
    0.7749219405005681, 0.7831630072775223, 0.7915365740438265, 0.8000454775113919,
    0.8086926715957823, 0.8174812338704354, 0.8264143724843542, 0.8354954335839506,
    0.8447279092839757, 0.8541154462372216, 0.8636618548580499, 0.8733711192608059,
    0.8832474079809831, 0.893295085554644, 0.9035187250402952, 0.9139231215772247,
    0.9245133070855096, 0.9352945662255518, 0.9462724537495655, 0.9574528133939517,
    0.9688417984805355, 0.980445894416411, 0.9922719433072761, 1.0043271709281216,
    1.0166192163286902, 1.0291561643900398, 1.0419465816938835, 1.0549995561192462,
    1.068324740642932, 1.0819324018929954, 1.095833474090174, 1.1100396191135864,
    1.1245632935472945, 1.1394178237077117, 1.154617489823174, 1.1701776207429346,
    1.1861147008010897, 1.2024464907618015, 1.2191921651383917, 1.236372468626913,
    1.2540098949458285, 1.2721288920548686, 1.2907560985736741, 1.3099206172817197,
    1.32965433291731, 1.3499922831885522, 1.3709730940751508, 1.392639493289193, 1.4150389193851018,
    1.4382242487542658, 1.4622546690161515, 1.4871967357044173, 1.5131256604766592,
    1.540126894566311, 1.5682980926452363, 1.597751572387511, 1.6286174279731211,
    1.6610475180367028, 1.6952206405120172, 1.7313493453759023, 1.7696890498441546,
    1.8105504581185672, 1.8543168367130936, 1.9014686183606289, 1.9526194144236706,
    2.008570439209789, 2.0703959430036276, 2.139583614813175, 2.2182787539908815, 2.30974047230792,
    2.4192782623274405, 2.556442192577351, 2.74124522052951, 3.0288797391157254, 3.742804190076461,
    3.3726981147812216, 2.9263459005281955, 2.698939447723516, 2.5466299363347074,
    2.4327976299530136, 2.3424520164203004, 2.267971492354465, 2.204947821362726,
    2.1506010643962146, 2.1030654413997043, 2.0610283325694048, 2.0235316054396013,
    1.9898548640569569, 1.9594431571181634, 1.9318602615612201, 1.906757400209893,
    1.8838516544613546, 1.8629106783019282, 1.8437416300836005, 1.826183000728071,
    1.8100984763782741, 1.795372259008721, 1.7819054508403667, 1.7696132277172056,
    1.7584226063925559, 1.7482706650951265, 1.7391031145357074, 1.7308731431934057,
    1.7235404798439893, 1.7170706302000314, 1.7114342547870327, 1.7066066628443979,
    1.7025674028491444, 1.699299934728287, 1.6967913723196506, 1.6950322874260189,
    1.6940165690804427, 1.6937413335498612, 1.6942068822663812, 1.6954167063858567,
    1.6973775381142904, 1.7000994503910016, 1.7035960080507389, 1.7078844752896625,
    1.7129860862307527, 1.7189263877446987, 1.7257356665893318, 1.7334494765920834,
    1.742109286298489, 1.7517632736367712, 1.7624673022552386, 1.7742861250641324,
    1.787294875301766, 1.8015809258364202, 1.8172462259456235, 1.834410265355841, 1.853213873907344,
    1.8738241514184155, 1.8964409517617618, 1.9213055438797801, 1.9487123851924129,
    1.9790254489262677, 2.0127013921645536, 2.050323315243073, 2.0926515064788425,
    2.1407025793726255, 2.1958784876118393, 2.260188646808823, 2.336659601580613,
    2.4301616137638646, 2.5492947674339312, 2.7115465051913783, 2.9623707713023935,
    3.516329717307372, 3.476318709829877, 2.9313767524964085, 2.6717495949477814, 2.498139400028738,
    2.3667828557173944, 2.260649927616202, 2.1713133352820826, 2.0939807114285776,
    2.0256642790963424, 1.9643748065094342, 1.9087207366171777, 1.857690265231406,
    1.8105245029493604, 1.76663954044237, 1.7255764017449733, 1.68696770574057, 1.6505147589048064,
    1.6159713921523766, 1.58313229093833, 1.5518243982074515, 1.521900467531939, 1.4932341516507381,
    1.4657162074125138, 1.4392515257432135, 1.4137567803007771, 1.389158546286728,
    1.3653917808926879, 1.3423985850066695, 1.320127185904814, 1.2985310952072948, 1.27756840704797,
    1.257201209323336, 1.2373950868228434, 1.2181186995395028, 1.1993434228983815,
    1.1810430392930304, 1.1631934723828432, 1.1457725572212345, 1.1287598405609374,
    1.1121364066973394, 1.0958847250224135, 1.079988516115118, 1.0644326337229741,
    1.0492029604200401, 1.0342863150786705, 1.019670370582081, 1.0053435804439652,
    0.991295113199959, 0.9775147936011744, 0.9639930497784912, 0.9507208656625455,
    0.9376897380424883, 0.9248916377294916, 0.9123189743614841, 0.8999645644456016,
    0.8878216022861474, 0.8758836334898267, 0.8641445307778166, 0.8525984718668296,
    0.8412399192095181, 0.8300636014089714, 0.8190644961432829, 0.8082378144546651, 0.7975789862737,
    0.7870836470634741, 0.7767476254807026, 0.7665669319618591, 0.7565377481518888,
    0.7466564171015819, 0.7369194341671279, 0.7273234385520371, 0.717865205437451,
    0.7085416386521495, 0.6993497638381745, 0.690286722072167, 0.6813497639062118,
    0.672536243795332, 0.6638436148817225, 0.6552694241085054, 0.6468113076381807,
    0.6384669865531192, 0.6302342628173924, 0.622111015480981, 0.6140951971090074,
    0.6061848304200853, 0.5983780051191547, 0.5906728749113906, 0.5830676546848168,
    0.575560617850253, 0.5681500938281099, 0.5608344656723485, 0.5536121678226831,
    0.5464816839767398, 0.539441545074561, 0.5324903273883534, 0.5256266507109347,
    0.5188491766367871, 0.5121566069300746, 0.5055476819743797, 0.49902117929927325,
    0.4925759121791807, 0.4862107283003225, 0.47992450849178236, 0.4737161655170384,
    0.46758464292251134, 0.4615289139399555, 0.45554798043967437, 0.44964087193178054,
    0.4438066446128716, 0.43804438045568106, 0.4323531863393979, 0.4267321932185088,
    0.4211805553281426, 0.4156974494240178, 0.4102820740552183, 0.4049336488681233,
    0.3996514139399182, 0.3944346291402062, 0.3892825735193315, 0.38419454472210146,
    0.3791698584256763, 0.37420784780045024, 0.36930786299284524, 0.36446927062896406,
    0.3596914533381364, 0.3549738092954243, 0.3503157517822268, 0.34571670876414784,
    0.3411761224853536, 0.33669344907867826, 0.3322681581907839, 0.3278997326217073,
    0.323587667978176, 0.3193314723400881, 0.3151306659396063, 0.3109847808523231,
    0.30689336069999623, 0.3028559603643736, 0.29887214571164755, 0.29494149332711217,
    0.29106359025961176, 0.28723803377538276, 0.2834644311209273, 0.27974239929455996,
    0.27607156482629464, 0.2724515635657529, 0.26888204047778463, 0.26536264944552346,
    0.26189305308059047, 0.2584729225401925, 0.25510193735086023, 0.2517797852385944,
    0.24850616196519124, 0.24528077117053135, 0.2421033242206283, 0.23897354006124352,
    0.23589114507687595, 0.23285587295495855, 0.2298674645550814, 0.22692566778309062,
    0.22403023746990544, 0.221180935254906, 0.21837752947375833, 0.21561979505053674,
    0.21290751339402578, 0.21024047229807585, 0.20761846584590096, 0.20504129431820875,
    0.20250876410506238, 0.2000206876213728, 0.19757688322593145, 0.19517717514389107,
    0.1928213933926155, 0.19050937371081575, 0.18824095749089817, 0.18601599171445354,
    0.183834328890819, 0.18169582699865153, 0.17960034943045153, 0.17754776493998028,
    0.17553794759251926, 0.17357077671792115, 0.17164613686640967, 0.16976391776707836,
    0.1679240142890567, 0.16612632640530198, 0.16437075915898278, 0.1626572226324265,
    0.16098563191860063, 0.15935590709510086, 0.1577679732006285, 0.15622176021393328,
    0.15471720303520517, 0.1532542414699013, 0.1518328202149981, 0.15045288884765606,
    0.1491144018162919, 0.1478173184340552, 0.14656160287470754, 0.14534722417090476,
    0.14417415621488588, 0.1430423777615775, 0.14195187243411905, 0.14090262873182444,
    0.139894640040591, 0.13892790464577484, 0.1380024257475529, 0.13711821147879205,
    0.1362752749254528, 0.13547363414955438, 0.13471331221473323, 0.13399433721443088,
    0.1333167423027435, 0.13268056572797912, 0.13208585086896402, 0.13153264627414296,
    0.13102100570352995, 0.1305509881735542, 0.13012265800487033, 0.12973608487318433,
    0.12939134386316378, 0.1290885155255068, 0.12882768593723412, 0.1286089467652895,
    0.128432395333526, 0.1282981346931709, 0.12820627369685453, 0.12815692707630238,
    0.1281502155237978, 0.12818626577751432, 0.12826521071083805, 0.12838718942579666,
    0.12855234735072305, 0.1287608363422822, 0.12901281479200577, 0.12930844773747815,
    0.12964790697832712, 0.13003137119718483, 0.13045902608578802, 0.13093106447639494,
    0.13144768647871066, 0.13200909962251733, 0.13261551900621935, 0.1332671674515207,
    0.1339642756644655, 0.1347070824030873, 0.13549583465191786, 0.1363307878036273,
    0.13721220584807448, 0.1381403615690711, 0.13911553674916402, 0.14013802238277193,
    0.14120811889802037, 0.14232613638764158, 0.1434923948493217, 0.14470722443590567,
    0.14597096571588222, 0.14728396994460446, 0.14864659934671884, 0.1500592274103045,
    0.15152223919325675, 0.15303603164246835, 0.1546010139264027, 0.15621760778168403,
    0.1578862478743615, 0.15960738217654907, 0.16138147235917324, 0.16320899420161833,
    0.16509043801908668, 0.1670263091085569, 0.1690171282142657, 0.17106343201369795,
    0.17316577362512311, 0.17532472313779593, 0.17754086816598696, 0.1798148144280953,
    0.18214718635216717, 0.1845386277092378, 0.18698980227598516, 0.18950139452830175,
    0.19207411036747798, 0.19470867788081517, 0.19740584813858839, 0.2001663960294258,
    0.20299112113630385, 0.20588084865549794, 0.2088364303609995, 0.2118587456170868,
    0.2149487024419044, 0.21810723862514073, 0.221335322903074, 0.2246339561945309,
    0.22800417290153172, 0.23144704227868473, 0.2349636698756986, 0.23855519905769956,
    0.24222281260840076, 0.24596773442155923, 0.24979123128658023, 0.2536946147745783,
    0.25767924323171226, 0.261746523887161, 0.26589791508369887, 0.2701349286394807,
    0.2744591323503719, 0.27887215264293114, 0.2833756773890291, 0.28797145889400827,
    0.2926613170713555, 0.29744714281798335, 0.30233090160548287, 0.30731463730410824,
    0.31240047625777934, 0.317590631630097, 0.3228874080432556, 0.3282932065338048,
    0.33381052985156195, 0.33944198813053267, 0.3451903049635896, 0.35105832391585845,
    0.3570490155153355, 0.3631654847632898, 0.3694109792114674, 0.3757888976581911,
    0.38230279952107576, 0.3889564149505099, 0.3957536557552296, 0.4026986272194699,
    0.409795640900405, 0.41704922850506687, 0.4244641569578166, 0.43204544478307283,
    0.43979837994341997, 0.4477285392910549, 0.4558418098108109, 0.4641444118564682,
    0.47264292460904167, 0.481344314016928, 0.4902559635139869, 0.4993857078536961,
    0.5087418704464659, 0.5183333046445762, 0.5281694394863391, 0.5382603304902119,
    0.5486167161829099, 0.5592500811561897, 0.5701727265785216, 0.581397849244851,
    0.592939630435914, 0.6048133360850018, 0.6170354300240095, 0.6296237024131554,
    0.6425974158646988, 0.6559774722689724, 0.6697866039451791, 0.6840495935011599,
    0.6987935277369333, 0.7140480921203, 0.7298459138718232, 0.7462229636175582, 0.7632190280325208,
    0.7808782690851257, 0.7992498896503226, 0.8183889307323466, 0.8383572328146319,
    0.8592246036317709, 0.8810702479471118, 0.9039845332049784, 0.9280711904270685,
    0.9534500858081814, 0.980260750351778, 1.0086669308062481, 1.038862538417801,
    1.0710795446211625, 1.105598642201247, 1.1427639223964778, 1.1830035322374641,
    1.2268594976078366, 1.2750320716886214, 1.3284480235537715, 1.388370272781183,
    1.4565831019216848, 1.535725616618653, 1.6299434829596364, 1.746311501037244, 1.898470893982015,
    2.1185843771448685, 2.5219016151372236, 3.232253791488384, 2.324477355343853,
    2.0201668049546186, 1.8322494342803242, 1.6959148848431027, 1.5888550451386154,
    1.5006935130261154, 1.4257510176839499, 1.3605786802401678, 1.3029241716904296,
    1.2512351461018592, 1.2043962217382513, 1.161578958880659, 1.1221511623344913,
    1.0856194092302556, 1.051591188233609, 1.0197491302106074, 0.9898329733462847,
    0.9616266340549655, 0.9349487416648841, 0.9096455797253064, 0.885585735052404,
    0.8626559815207888, 0.8407580717263233, 0.8198062063498046, 0.7997250163801992,
    0.780447938317217, 0.7619158939430248, 0.7440762086215738, 0.7268817182099211
  ]
}

export const loschmidtEchoLabel = [
  0.0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15,
  0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31,
  0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47,
  0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63,
  0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79,
  0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95,
  0.96, 0.97, 0.98, 0.99, 1.0, 1.01, 1.02, 1.03, 1.04, 1.05, 1.06, 1.07, 1.08, 1.09, 1.1, 1.11,
  1.12, 1.13, 1.14, 1.15, 1.16, 1.17, 1.18, 1.19, 1.2, 1.21, 1.22, 1.23, 1.24, 1.25, 1.26, 1.27,
  1.28, 1.29, 1.3, 1.31, 1.32, 1.33, 1.34, 1.35, 1.36, 1.37, 1.38, 1.39, 1.4, 1.41, 1.42, 1.43,
  1.44, 1.45, 1.46, 1.47, 1.48, 1.49, 1.5, 1.51, 1.52, 1.53, 1.54, 1.55, 1.56, 1.57, 1.58, 1.59,
  1.6, 1.61, 1.62, 1.63, 1.64, 1.65, 1.66, 1.67, 1.68, 1.69, 1.7, 1.71, 1.72, 1.73, 1.74, 1.75,
  1.76, 1.77, 1.78, 1.79, 1.8, 1.81, 1.82, 1.83, 1.84, 1.85, 1.86, 1.87, 1.88, 1.89, 1.9, 1.91,
  1.92, 1.93, 1.94, 1.95, 1.96, 1.97, 1.98, 1.99, 2.0, 2.01, 2.02, 2.03, 2.04, 2.05, 2.06, 2.07,
  2.08, 2.09, 2.1, 2.11, 2.12, 2.13, 2.14, 2.15, 2.16, 2.17, 2.18, 2.19, 2.2, 2.21, 2.22, 2.23,
  2.24, 2.25, 2.26, 2.27, 2.28, 2.29, 2.3, 2.31, 2.32, 2.33, 2.34, 2.35, 2.36, 2.37, 2.38, 2.39,
  2.4, 2.41, 2.42, 2.43, 2.44, 2.45, 2.46, 2.47, 2.48, 2.49, 2.5, 2.51, 2.52, 2.53, 2.54, 2.55,
  2.56, 2.57, 2.58, 2.59, 2.6, 2.61, 2.62, 2.63, 2.64, 2.65, 2.66, 2.67, 2.68, 2.69, 2.7, 2.71,
  2.72, 2.73, 2.74, 2.75, 2.76, 2.77, 2.78, 2.79, 2.8, 2.81, 2.82, 2.83, 2.84, 2.85, 2.86, 2.87,
  2.88, 2.89, 2.9, 2.91, 2.92, 2.93, 2.94, 2.95, 2.96, 2.97, 2.98, 2.99, 3.0, 3.01, 3.02, 3.03,
  3.04, 3.05, 3.06, 3.07, 3.08, 3.09, 3.1, 3.11, 3.12, 3.13, 3.14, 3.15, 3.16, 3.17, 3.18, 3.19,
  3.2, 3.21, 3.22, 3.23, 3.24, 3.25, 3.26, 3.27, 3.28, 3.29, 3.3, 3.31, 3.32, 3.33, 3.34, 3.35,
  3.36, 3.37, 3.38, 3.39, 3.4, 3.41, 3.42, 3.43, 3.44, 3.45, 3.46, 3.47, 3.48, 3.49, 3.5, 3.51,
  3.52, 3.53, 3.54, 3.55, 3.56, 3.57, 3.58, 3.59, 3.6, 3.61, 3.62, 3.63, 3.64, 3.65, 3.66, 3.67,
  3.68, 3.69, 3.7, 3.71, 3.72, 3.73, 3.74, 3.75, 3.76, 3.77, 3.78, 3.79, 3.8, 3.81, 3.82, 3.83,
  3.84, 3.85, 3.86, 3.87, 3.88, 3.89, 3.9, 3.91, 3.92, 3.93, 3.94, 3.95, 3.96, 3.97, 3.98, 3.99,
  4.0, 4.01, 4.02, 4.03, 4.04, 4.05, 4.06, 4.07, 4.08, 4.09, 4.1, 4.11, 4.12, 4.13, 4.14, 4.15,
  4.16, 4.17, 4.18, 4.19, 4.2, 4.21, 4.22, 4.23, 4.24, 4.25, 4.26, 4.27, 4.28, 4.29, 4.3, 4.31,
  4.32, 4.33, 4.34, 4.35, 4.36, 4.37, 4.38, 4.39, 4.4, 4.41, 4.42, 4.43, 4.44, 4.45, 4.46, 4.47,
  4.48, 4.49, 4.5, 4.51, 4.52, 4.53, 4.54, 4.55, 4.56, 4.57, 4.58, 4.59, 4.6, 4.61, 4.62, 4.63,
  4.64, 4.65, 4.66, 4.67, 4.68, 4.69, 4.7, 4.71, 4.72, 4.73, 4.74, 4.75, 4.76, 4.77, 4.78, 4.79,
  4.8, 4.81, 4.82, 4.83, 4.84, 4.85, 4.86, 4.87, 4.88, 4.89, 4.9, 4.91, 4.92, 4.93, 4.94, 4.95,
  4.96, 4.97, 4.98, 4.99, 5.0, 5.01, 5.02, 5.03, 5.04, 5.05, 5.06, 5.07, 5.08, 5.09, 5.1, 5.11,
  5.12, 5.13, 5.14, 5.15, 5.16, 5.17, 5.18, 5.19, 5.2, 5.21, 5.22, 5.23, 5.24, 5.25, 5.26, 5.27,
  5.28, 5.29, 5.3, 5.31, 5.32, 5.33, 5.34, 5.35, 5.36, 5.37, 5.38, 5.39, 5.4, 5.41, 5.42, 5.43,
  5.44, 5.45, 5.46, 5.47, 5.48, 5.49, 5.5, 5.51, 5.52, 5.53, 5.54, 5.55, 5.56, 5.57, 5.58, 5.59,
  5.6, 5.61, 5.62, 5.63, 5.64, 5.65, 5.66, 5.67, 5.68, 5.69, 5.7, 5.71, 5.72, 5.73, 5.74, 5.75,
  5.76, 5.77, 5.78, 5.79, 5.8, 5.81, 5.82, 5.83, 5.84, 5.85, 5.86, 5.87, 5.88, 5.89, 5.9, 5.91,
  5.92, 5.93, 5.94, 5.95, 5.96, 5.97, 5.98, 5.99, 6.0, 6.01, 6.02, 6.03, 6.04, 6.05, 6.06, 6.07,
  6.08, 6.09, 6.1, 6.11, 6.12, 6.13, 6.14, 6.15, 6.16, 6.17, 6.18, 6.19, 6.2, 6.21, 6.22, 6.23,
  6.24, 6.25, 6.26, 6.27, 6.28, 6.29, 6.3, 6.31, 6.32, 6.33, 6.34, 6.35, 6.36, 6.37, 6.38, 6.39,
  6.4, 6.41, 6.42, 6.43, 6.44, 6.45, 6.46, 6.47, 6.48, 6.49, 6.5, 6.51, 6.52, 6.53, 6.54, 6.55,
  6.56, 6.57, 6.58, 6.59, 6.6, 6.61, 6.62, 6.63, 6.64, 6.65, 6.66, 6.67, 6.68, 6.69, 6.7, 6.71,
  6.72, 6.73, 6.74, 6.75, 6.76, 6.77, 6.78, 6.79, 6.8, 6.81, 6.82, 6.83, 6.84, 6.85, 6.86, 6.87,
  6.88, 6.89, 6.9, 6.91, 6.92, 6.93, 6.94, 6.95, 6.96, 6.97, 6.98, 6.99, 7.0, 7.01, 7.02, 7.03,
  7.04, 7.05, 7.06, 7.07, 7.08, 7.09, 7.1, 7.11, 7.12, 7.13, 7.14, 7.15, 7.16, 7.17, 7.18, 7.19,
  7.2, 7.21, 7.22, 7.23, 7.24, 7.25, 7.26, 7.27, 7.28, 7.29, 7.3, 7.31, 7.32, 7.33, 7.34, 7.35,
  7.36, 7.37, 7.38, 7.39, 7.4, 7.41, 7.42, 7.43, 7.44, 7.45, 7.46, 7.47, 7.48, 7.49, 7.5, 7.51,
  7.52, 7.53, 7.54, 7.55, 7.56, 7.57, 7.58, 7.59, 7.6, 7.61, 7.62, 7.63, 7.64, 7.65, 7.66, 7.67,
  7.68, 7.69, 7.7, 7.71, 7.72, 7.73, 7.74, 7.75, 7.76, 7.77, 7.78, 7.79, 7.8, 7.81, 7.82, 7.83,
  7.84, 7.85, 7.86, 7.87, 7.88, 7.89, 7.9, 7.91, 7.92, 7.93, 7.94, 7.95, 7.96, 7.97, 7.98, 7.99,
  8.0, 8.01, 8.02, 8.03, 8.04, 8.05, 8.06, 8.07, 8.08, 8.09, 8.1, 8.11, 8.12, 8.13, 8.14, 8.15,
  8.16, 8.17, 8.18, 8.19, 8.2, 8.21, 8.22, 8.23, 8.24, 8.25, 8.26, 8.27, 8.28, 8.29, 8.3, 8.31,
  8.32, 8.33, 8.34, 8.35, 8.36, 8.37, 8.38, 8.39, 8.4, 8.41, 8.42, 8.43, 8.44, 8.45, 8.46, 8.47,
  8.48, 8.49, 8.5, 8.51, 8.52, 8.53, 8.54, 8.55, 8.56, 8.57, 8.58, 8.59, 8.6, 8.61, 8.62, 8.63,
  8.64, 8.65, 8.66, 8.67, 8.68, 8.69, 8.7, 8.71, 8.72, 8.73, 8.74, 8.75, 8.76, 8.77, 8.78, 8.79,
  8.8, 8.81, 8.82, 8.83, 8.84, 8.85, 8.86, 8.87, 8.88, 8.89, 8.9, 8.91, 8.92, 8.93, 8.94, 8.95,
  8.96, 8.97, 8.98, 8.99, 9.0, 9.01, 9.02, 9.03, 9.04, 9.05, 9.06, 9.07, 9.08, 9.09, 9.1, 9.11,
  9.12, 9.13, 9.14, 9.15, 9.16, 9.17, 9.18, 9.19, 9.2, 9.21, 9.22, 9.23, 9.24, 9.25, 9.26, 9.27,
  9.28, 9.29, 9.3, 9.31, 9.32, 9.33, 9.34, 9.35, 9.36, 9.37, 9.38, 9.39, 9.4, 9.41, 9.42, 9.43,
  9.44, 9.45, 9.46, 9.47, 9.48, 9.49, 9.5, 9.51, 9.52, 9.53, 9.54, 9.55, 9.56, 9.57, 9.58, 9.59,
  9.6, 9.61, 9.62, 9.63, 9.64, 9.65, 9.66, 9.67, 9.68, 9.69, 9.7, 9.71, 9.72, 9.73, 9.74, 9.75,
  9.76, 9.77, 9.78, 9.79, 9.8, 9.81, 9.82, 9.83, 9.84, 9.85, 9.86, 9.87, 9.88, 9.89, 9.9, 9.91,
  9.92, 9.93, 9.94, 9.95, 9.96, 9.97, 9.98, 9.99, 10.0
]

export const initialDataSetLESSH: DataSetIF = {
  data: loschmidtEchoDataSSH.LE,
  label: 'data 1',
  borderColor: '#144bcd'
}

export const initialDataSetRRSSH: DataSetIF = {
  data: loschmidtEchoDataSSH.RR,
  label: 'data 1',
  borderColor: '#144bcd'
}

export const initialDataSetLEXXZ: DataSetIF = {
  data: loschmidtEchoDataXXZ.LE,
  label: 'data 1',
  borderColor: '#144bcd'
}

export const initialDataSetRRXXZ: DataSetIF = {
  data: loschmidtEchoDataXXZ.RR,
  label: 'data 1',
  borderColor: '#144bcd'
}

export const initialSSHParameters: FormParametersIF[] = [
  {
    model: SystemModel.SSH,
    type: SystemType.SINGLE_PARTICLE,
    size: 4,
    disorder: 0,
    interaction: 1.0,
    delta: -0.5,
    openbc: true,
    fix: true
  }
]

export const initialXXZParameters: FormParametersIF[] = [
  {
    model: SystemModel.XXZ,
    type: SystemType.SINGLE_PARTICLE,
    size: 4,
    disorder: 0,
    interaction: 1.0,
    delta: -0.5,
    openbc: true,
    fix: true
  }
]
