export const LinearSVG = (props: { children: JSX.Element[]; viewBox: string }) => {
  return (
    <svg
      width="80px"
      height="80px"
      viewBox={props.viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {props.children}
    </svg>
  )
}
