import React from 'react'
import '../assets/projects.sass'
import { useNavigate } from 'react-router-dom'
import { LinearSVG } from '../utils/LinearSVG'
import { ColorIF } from '../interfaces/svg'
import { projects } from '../utils/resources'
import MagnifierSVG from '../icons/MagnifierSVG'

const Projects: React.FC<{
  color1: string
  color2: string
  background1: string
  background2: string
}> = ({ color1, color2, background1, background2 }) => {
  const navigate = useNavigate()
  const [colors, setColors] = React.useState<ColorIF[]>(projects)
  const [index, setIndex] = React.useState<number>(-1)

  React.useEffect(() => {
    if (index !== -1) {
      setColors([
        ...colors.slice(0, index),
        {
          ...colors[index],
          color1: color1,
          color2: color2,
          background1: background1,
          background2: background2
        },
        ...colors.slice(index + 1)
      ])
    }
    // eslint-disable-next-line
  }, [color1, color2, background1, background2, index])

  const handleHoverItem = (index: number, onHover: boolean) => {
    let thisColor = {
      ...projects[index],
      color1: color1,
      color2: color2,
      background1: background1,
      background2: background2
    }
    if (!onHover) {
      setColors(projects)
      return
    }
    setColors([...projects.slice(0, index), thisColor, ...projects.slice(index + 1)])
    setIndex(index)
  }

  const redirectURL = (url: string) => {
    if (url.includes('/project/')) {
      navigate(url)
    } else {
      window.location.href = url
    }
  }

  return (
    <div id="projects">
      <div id="projects-container">
        <div id="projects-title-container">
          <h1>FEATURED PROJECTS</h1>
          <h4>I am in extremely love with software development and numerical solutions</h4>
        </div>
        <div id="projects-items-container">
          {projects.map(
            (item: { title: string; type: string; image: string; url: string }, index: number) => (
              <div
                className="projects-items"
                key={index}
                onMouseOver={() => handleHoverItem(index, true)}
                onMouseLeave={() => handleHoverItem(index, false)}
              >
                <div
                  className="projects-items-wrappers"
                  style={{
                    backgroundImage: `-webkit-linear-gradient(0deg, ${colors[index].background1} 0%, ${colors[index].background2} 100%`
                  }}
                >
                  <div className="projects-items-top">
                    <img
                      className="projects-items-image"
                      src={item.image}
                      alt={item.title.toLocaleLowerCase()}
                    />
                    <div
                      className="projects-items-background"
                      style={{
                        backgroundImage: `-webkit-linear-gradient(0deg, ${colors[index].background1} 0%, ${colors[index].background2} 100%`
                      }}
                      onClick={() => redirectURL(item.url)}
                    />
                    <div className="projects-items-zoom">
                      <LinearSVG viewBox={'0 0 310.42 310.42'}>
                        <defs></defs>
                        <MagnifierSVG />
                      </LinearSVG>
                    </div>
                  </div>
                </div>
                <div className="projects-items-title">{item.title}</div>
                <div className="projects-items-type">{item.type}</div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default Projects
