import React from 'react'
import { Line, Bubble, Scatter, Bar } from 'react-chartjs-2'
import { DataSetIF, MinMaxIF } from '../../interface/common'
import { AnimationIF } from '../../interface/functionAnimator'
import { Chart as ChartJS, registerables } from 'chart.js'
ChartJS.register(...registerables)

const FunctionAnimator: React.FC<{
  animation: AnimationIF
  datasets: DataSetIF[]
  labels: number[]
  yMinMax: MinMaxIF
}> = ({ animation, datasets, labels, yMinMax }) => {
  const [points, setPoints] = React.useState<number>(0)

  React.useEffect(() => {
    setInterval(() => {
      setPoints((points) => points + 1)
    }, 100)
  }, [])

  const data = {
    labels: labels.slice(0, (points / (-animation.speed + 21)) % labels.length),
    datasets: datasets.map((value: DataSetIF) => ({
      ...value,
      data: value.data.slice(0, (points / (-animation.speed + 21)) % labels.length),
      borderColor: value.borderColor,
      backgroundColor: value.borderColor.replace('1)', '0.4)'),
      borderWidth: 2
    }))
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      y: {
        display: false,
        suggestedMin: yMinMax.min,
        suggestedMax: yMinMax.max,
        grid: {
          display: false
        }
      },
      x: {
        display: false,
        suggestedMin: Math.min(...labels),
        suggestedMax: Math.max(...labels),
        grid: {
          display: false,
          drawTicks: false
        }
      }
    },
    elements: {
      point: {
        radius: animation.size,
        rotation: animation.rotate ? (points * animation.spin) % 360 : 0,
        pointStyle: animation.point
      }
    }
  }

  return (
    <div id="function-animator-animation-container">
      {animation.style === 'line' && <Line data={data} options={options} />}
      {animation.style === 'bar' && <Bar data={data} options={options} />}
      {animation.style === 'bubble' && <Bubble data={data} options={options} />}
      {animation.style === 'scatter' && <Scatter data={data} options={options} />}
    </div>
  )
}

export default FunctionAnimator
